import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import { translateOptions } from '../../i18n/config';
import { Container, Form, Button, TextArea } from 'semantic-ui-react';
import { scrollToTop } from '../../utils/misc-utils';
import DifferentCategoryModal from '../questionnaire-view/questionnaire-modal-component/different-category-modal'
import EditWeightageModal from '../questionnaire-view/questionnaire-modal-component/edit-weightage-modal';
import {UP_ARROW_ICON, DOWN_ARROW_ICON} from "../../constants/images";
import ReadMoreModal from '../questionnaire-view/questionnaire-modal-component/read-more-modal';
import ErrorMessageView from './error-message-view';

class SingleQuestionView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);
    this.state = {
      showCategoryModal: false,
      selectedGradeName: null,
      selectedGradeId: null,
      answerExplanation: '',
      questionReadyForSubmit: false,
      showEditWeightageModal: false,
      openReadMoreModal: false,
      perception: null,
      disableCloseModal: false
    }
  };

  /***************************
   * METHODS
   ***************************/
  handleChooseDifferentCategory() {
    this.setState({disableCloseModal:false, showCategoryModal: !this.state.showCategoryModal});
  }

  handleChooseDifferentCategoryForTopicSort() {
    this.setState({showCategoryModal: true, disableCloseModal: true});
  }

  handleEditWeightage(){
    const params = {
      org_topic_version_id: this.props.question.org_topic_version,
    }
    this.props.getEditWeightageValues(params, this.props.analysisType);
    this.setState({showEditWeightageModal: !this.state.showEditWeightageModal});
  }

  handleModalClose(modal){
    if(modal === 'weightage') {
      this.setState({showEditWeightageModal: false});
    } else if(modal==='category') {
      this.setState({showCategoryModal: false});
    }
  }

  onGradeSelect(gradeName, gradeId) {
    this.setState({selectedGradeName: gradeName, selectedGradeId: gradeId});
    this.setState({questionReadyForSubmit: true});
  }

  handleDescriptionOnChange(e) {
    this.setState({answerExplanation: e.target.value});
  }

  handleSubmit() {
    if(this.state.questionReadyForSubmit) {
      const params = {
        question: this.props.question.question,
        org_topic_version: this.props.question.org_topic_version,
        grade: this.state.selectedGradeId,
        explanation: this.state.answerExplanation ? this.state.answerExplanation : null,
        perception: this.state.perception ? this.state.perception : null,
        type: this.props.analysisType
      }
      this.resetForm()
      this.props.updateQuestionnaireAnswers(params);
      scrollToTop();
    }
  }

  resetForm() {
    this.setState({selectedGradeId: null, selectedGradeName: null, answerExplanation: '', questionReadyForSubmit: false, perception: null});
  }

  handleReadMoreModalOpen() {
    this.setState({openReadMoreModal: true})
  }

  handleReadMoreModalClose() {
    this.setState({openReadMoreModal: false})
  }
  capturePerception(value){
    if(this.state.perception === value) {
      this.setState({perception: null});
    } else {
      this.setState({perception: value});
    }
  }
  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount() {
    scrollToTop();
  }

  componentWillReceiveProps(newProps) {
    if(newProps.EndOfQuestionnaireForTopicCategory) {
      this.handleChooseDifferentCategoryForTopicSort();
    }
  }

  render(){
    const { t } = this.props;
    const { topicCategories } = this.props;
    const questionHtml = {
      __html: this.props.question.question,
    }
    let headingQuestionnaire;
    if(this.props.analysisType === 'stakeholder') {
      headingQuestionnaire = t('questionnaireQuestion.headingStakeholder');
    } else {
      headingQuestionnaire = t('questionnaireQuestion.headingBusinessCriteria');
    }

    return(
      <Form className="single-question-view" onSubmit={()=> this.handleSubmit()}>
        <div>
          <Container className="single-question-container">
            <div className="question-header-one">
              <div className="heading-left">
                <div className="heading-content">
                  {t('questionnaireQuestion.heading1')}        
                </div>
                {
                  topicCategories &&
                  <div className="answered-count">
                    {
                      topicCategories.questionnaireTopicCategories.total_answers}/{topicCategories.questionnaireTopicCategories.total_questions
                    }
                  </div>
                }
              </div>
              <div className="heading-right">
                <div className="weightage" onClick={()=>this.handleEditWeightage()}>
                  {t('questionnaireQuestion.weightageLinkText')}
                </div>
              </div>
            </div>
            <div className="question-header-2">
              <div className="header-content">
                {headingQuestionnaire}
              </div>
              <div className="different-category-btn">
                <Button type="button" className="ui black basic button diff-category-btn" onClick={()=>this.handleChooseDifferentCategory()}>
                  {t('questionnaireQuestion.differentCategory')}
                </Button>
              </div>
            </div>
            {
              this.props.isError &&
              <ErrorMessageView error={this.props.errorMessage || 'Unable to save the answer. Please try again later'}/>
            }
            <div className="question-content ui grid">
              <div className="question-number one wide column">
                {this.props.questionNumber}
              </div>
              <div className="question fifteen wide column" dangerouslySetInnerHTML={questionHtml}>
              </div>
            </div>
            <div className="read-more" onClick={()=>this.handleReadMoreModalOpen()}>
              {t('questionnaireQuestion.readMore')}
            </div>
            <div className="scoring-the-question">
              {
                this.props.questionnaireGradePoints && this.props.questionnaireGradePoints.questionnaireGradePoints.length > 0 &&
                this.props.questionnaireGradePoints.questionnaireGradePoints.map((grade) => {
                  let classname;
                  let gradename = grade.name.toUpperCase();
                  if(gradename === 'LOW') {
                    classname = "ui green button opacity-btn";           
                  } else if(gradename === 'MEDIUM') {
                    classname = "ui yellow button opacity-btn";
                  } else if (gradename === 'HIGH') {
                    classname = "ui red button opacity-btn";
                  }
                  if (gradename !== this.state.selectedGradeName) {
                    classname += " basic";
                  }
                  return (
                    <Button type="button" key={grade.name} className={classname} onClick={()=>this.onGradeSelect(grade.name.toUpperCase(), grade.id)}>
                      {grade.name.toUpperCase()}
                    </Button>
                  )
                })
              }
            </div>
            <div className="question-description">
              <TextArea autoHeight value={this.state.answerExplanation} placeholder={t('questionnaireQuestion.descriptionPlaceholder')} onChange={(e) => {this.handleDescriptionOnChange(e)}} rows='3' />
            </div>
            <div className="importance margin-60">
              <div className={this.state.perception === 'rising_in_importance' ? "rise selected-perception" : "rise"} onClick={()=>this.capturePerception('rising_in_importance')}>
                {t('questionnaireQuestion.raisingImportance')}
                <img className="up-arrow" src={UP_ARROW_ICON}/>
              </div>
              <div className={this.state.perception === 'becoming_less_relevant' ? "less-relevent selected-perception" : "less-relevent"} onClick={()=>this.capturePerception('becoming_less_relevant')}>
                {t('questionnaireQuestion.lessRelevant')}
                <img className="up-arrow" src={DOWN_ARROW_ICON}/>
              </div>
            </div>
            <div className="questionnaie-footer">
              <Button className={this.state.questionReadyForSubmit ? "orange-solid-btn next-btn" : "orange-solid-btn next-btn disabled"} type="submit">
                {
                  this.props.lastQuestion
                  ? t('questionnaireQuestion.lastQuestion')
                  : t('questionnaireQuestion.nextQuestionText')
                }
              </Button>
              <Link to={`/${this.props.analysisType}/questionnaire-preview`}>
                <div className="view-results">
                  {t('questionnaireQuestion.viewResults')}
                </div>
              </Link>
            </div>
          </Container>
          {
            this.state.showCategoryModal &&
            <DifferentCategoryModal
              size={'small'}
              open={true}
              disableCloseModal={this.state.disableCloseModal}
              closeOnEscape={this.state.disableCloseModal ? false : true}
              closeOnDimmerClick={this.state.disableCloseModal ? false : true}
              analysisType = {this.props.analysisType}
              getQuestionnaireQuestionForTopic={(categoryId) => this.props.getQuestionnaireQuestionForTopic(categoryId)}
              handleModalCloseCallback={(value, modal)=>this.handleModalClose(value, modal)}
              getQuestionnaireTopicCategories={()=> this.props.getQuestionnaireTopicCategories()}
            />
          }
          {
            this.state.showEditWeightageModal &&
            <EditWeightageModal 
              size={'small'}
              open={this.state.showEditWeightageModal}
              question={this.props.question}
              analysisType={this.props.analysisType}
              updateEditWeightageValues={(orgParams, postData) => this.props.updateEditWeightageValues(orgParams, postData)}
              handleModalCloseCallback={(modal)=>this.handleModalClose(modal)}
            />
          }
          {
            this.state.openReadMoreModal &&
            <ReadMoreModal
              size={'tiny'}
              open={this.state.openReadMoreModal}
              topicDefinition={this.props.question.topic_definition}
              topicName={this.props.question.topic_name}
              handleModalCloseCallback={()=>this.handleReadMoreModalClose()}
            />
          }
        </div>
      </Form>
    );
  };

}

export default translate(['translations'], translateOptions)(connect(null)(SingleQuestionView));