import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import rootReducer from '../reducer/root';
import {
  getStateFromLocalStorage,
  setStateToLocalStorage,
} from '../helpers/auth-helpers';
import { isEqual } from 'lodash';
export const history = createBrowserHistory();

const enhancers = [];
const middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.devToolsExtension;
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const persistedState = getStateFromLocalStorage();

const store = createStore(rootReducer, persistedState, composedEnhancers);

store.subscribe(() => {
  if (store.getState().auth.userSession != null) {
    if (!persistedState) {
      setStateToLocalStorage({
        auth: store.getState().auth,
      });
    } else if (
      !isEqual(
        persistedState.auth.userSession,
        store.getState().auth.userSession
      )
    ) {
      setStateToLocalStorage({
        auth: store.getState().auth,
      });
    }
  }
});

export default store;
