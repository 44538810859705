import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { CLOSE_ICON } from '../../../constants/images';

class ReadMoreModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      modalOpened: this.props.open
    }
  }
  handleModalClose() {
    if(this.state.modalOpened) {
      this.setState({modalOpened: false});
      this.props.handleModalCloseCallback();
    }
  }

  render(){
    return(
      <div>
        <Modal className="read-more-model" size={this.props.size} open={this.state.modalOpened} onClose={()=>this.handleModalClose()}>
          <Modal.Header>
            <div className="header-content">
              {this.props.topicName}
            </div>
            <div className="close-icon" onClick={()=>this.handleModalClose()}>
              <img src={CLOSE_ICON} alt="close-icon"/>
            </div>
          </Modal.Header>
          <Modal.Content>
            {this.props.topicDefinition}
          </Modal.Content>
        </Modal>
      </div>
    )
  };

};

export default ReadMoreModal;