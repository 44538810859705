import ActionTypes from '../constants/action-type';

import {
  getResetPasswordLink,
  login,
  logout,
  register, resetPassword, updateUserName, changePassword
} from '../utils/app-api-utils';
import {
  getErrorMessage
} from '../utils/string-utils';
import {
  clearLocalStorage
} from '../helpers/auth-helpers';
import store from '../store/store';

/* START :: LOGIN */
export function requestLogin(params){
  return(dispatch) => {
    login(dispatch, params);
  }
}

export function receiveLoginResponse(response){
  let action;
  if(response.user && response.user.is_superuser) {
    response.isSuccess = false;
    response.isError = true;
    response.message = 'Unable to login with the provided credentials';
    action = ActionTypes.RECEIVE_LOGIN_ERROR_RESPONSE;
  } else if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.message = getErrorMessage(response.error);
    action = ActionTypes.RECEIVE_LOGIN_ERROR_RESPONSE;
  } else {
    action = ActionTypes.RECEIVE_LOGIN_SUCCESS_RESPONSE;
  }
  return{
    type: action,
    response
  }
}
/* END :: LOGIN */

/* START :: REGISTER */
export function requestRegister(params){
  return(dispatch) => {
    register(dispatch, params);
  }
}

export function receiveRegisterResponse(response){
  return (dispatch) => {
    if (response.hasOwnProperty('success') && !response.success){
      response.isSuccess = false;
      response.isError = true;
      response.registerResponse = getErrorMessage(response.error);
    } else {
      response.isSuccess = true;
      response.isError = false;
      response.registerResponse = response.data
    }
    dispatch({
      type: ActionTypes.RECEIVE_REGISTER_RESPONSE,
      response
    })
  }
}
/* END :: REGISTER */

/* START :: REQUEST RESET PASSWORD */
export function requestResetPassword(params) {
  return(dispatch)=> {
    getResetPasswordLink(dispatch, params);
  }
}

export function receiveResetPasswordLinkResponse(response){
  if (response.hasOwnProperty('success') && !response.success) {
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response;
  }
  return{
    type: ActionTypes.RECEIVE_RESET_PASSWORD_LINK_RESPONSE,
    response
  }
}
/* END :: REQUEST RESET PASSWORD */

/* START :: UPDATE SET PASSWORD */
export function requestSetPassword(params){
  return(dispatch) => {
    resetPassword(dispatch, params);
  }
};

export function receiveSetPasswordResponse(response){
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  }
  else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response;
  }
  return{
    type: ActionTypes.RECEIVE_RESET_PASSWORD_RESPONSE,
    response
  }
};
/* END :: UPDATE SET PASSWORD */

export function receiveRefreshTokenResponse(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  }
  else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response;
  }
  store.dispatch({
    type: ActionTypes.RECEIVE_REFRESH_TOKEN_RESPONSE,
    response
  });
}

export function requestUpdateUserName(params) {
  return(dispatch) => {
    updateUserName(dispatch, params)
  }
}

export function receiveUpdateUserNameResponse(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  }
  else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response;
  }
  return{
    type: ActionTypes.RECEIVE_UPDATE_USERNAME_RESPONSE,
    response
  }
}

export function requestChangePassword(params) {
  return(dispatch) => {
    changePassword(dispatch, params);
  }
}

export function receiveChangePasswordResponse(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  }
  else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response;
  }
  return{
    type: ActionTypes.RECEIVE_UPDATE_PASSWORD_RESPONSE,
    response
  }
}

/* START :: LOGOUT */
export function requestLogout() {
  return(dispatch)=> {
    logout(dispatch);
  }
}

export function receiveLogoutResponse(response) {
  clearLocalStorage();
  window.location.replace('/');

  return{
    type: ActionTypes.RECEIVE_LOGOUT_RESPONSE,
    response
  }
}
/* END :: LOGOUT */

/* START :: CLEAR STATE VARIABLES */
export function requestClearAuthStates() {
  return{
    type: ActionTypes.CLEAR_AUTH_STATE_VARIABLES
  }
}
/* END :: CLEAR STATE VARIABLES */