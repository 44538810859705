import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Form, Grid } from 'semantic-ui-react';
import { translateOptions } from '../../../../../../i18n/config';

class EditGradesView extends Component {
  constructor(props){
    super(props);
    this.state = {
      fields:{
        id: this.props.grade.id,
        name: this.props.grade.name || "",
        points: this.props.grade.points,
      }
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(field, e){
    let fields = this.state.fields;
    if (field === "points" && e.target.value)
      fields["points"] = parseInt(e.target.value);
    else
      fields[field] = e.target.value;
    this.setState({
      fields,
    });
    this.props.onChangeCallback(fields);
  }

  render(){
    const { t } = this.props;
    const { name, points } = this.state.fields;

    return(
      <Form.Field className='form-field' style={styles.marginBottom5}>
        <Grid columns='equal' verticalAlign='middle'>
          <Grid.Row className='no-padding'>
            <Grid.Column width={7}>
              <input
                type='text'
                placeholder={t('stakeholdersInsightsPage.addValue')}
                onChange={this.handleChange.bind(this, 'name')}
                value={name}
                className='no-margin clear-opacity'
                disabled
              />
            </Grid.Column>
            <Grid.Column width={7}>
              <input
                type='number'
                placeholder={t('stakeholdersInsightsPage.degree')}
                onChange={this.handleChange.bind(this, 'points')}
                value={points}
                min="0"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Field>
    );
  }

}

export default translate(['translations'], translateOptions)(connect(null)(EditGradesView));

const styles = {
  marginBottom5: {
    marginBottom: '5px'
  }
}