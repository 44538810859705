import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../../i18n/config';

class StakeHolderListView extends Component {
  constructor(props){
    super(props);
    this.state = {
      fields:{
        id: this.props.stakeHolder.id,
        name: this.props.stakeHolder.name || "",
        notes: this.props.stakeHolder.notes || "",
        responseRecieved: this.props.stakeHolder.has_respondent
      }
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e, id){
    e.target.value = "on";
    this.props.updateSelectedStakeholderChange(id);
  }

  render(){
    const { name, id, responseRecieved } = this.state.fields;

    return(
      <div className={responseRecieved ? "field opacity-preview" : "field"}>
        <label>
          <span>{name}</span>
          <input id={id} type="radio" checked={this.props.defaultSelectedStakeholder} name="stakeholder" value={"off"} onClick={(e)=> this.handleChange(e, id)}/>
        </label>
      </div>
    );
  }

}

export default translate(['translations'], translateOptions)(connect(null)(StakeHolderListView));
