import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import auth from './auth';
import app from './app';

const history = createBrowserHistory();

const rootReducer = combineReducers({
  auth: auth,
  app: app,
  router: connectRouter(history),
});

export default rootReducer;
