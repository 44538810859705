import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import { translateOptions } from '../../i18n/config';
import { scrollToTop } from '../../utils/misc-utils';
import {
  requestGetStrategyReportFiles
} from '../../actions/app-actions';
import {getActiveOrg} from "../../helpers/auth-helpers";
import StrategyAndReportsFilesComponent from './strategy-reports-file-component';
import EmptyStateView from '../common/empty-state-view';

class StrategyAndReportsFilesView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);
    const { userSession } = this.props;
    this.state={
      org_id: getActiveOrg(userSession)
    };
  };

  /***************************
   * METHODS
   ***************************/
  /***************************
   * VIEWS
   ***************************/
  getStrategyReportFilesView(strategyReportsFile, index) {
    const elementId = `key_${strategyReportsFile.file_name}_${index}`;
    return(
      <StrategyAndReportsFilesComponent
        key={ elementId }
        index={ index }
        strategyReportsFile={ strategyReportsFile }
      />
    )
  }

  getEmptyStateView() {
    const { t } = this.props;
    return(
      <EmptyStateView message={t('strategyAndReportsFile.nofilesFound')} />
    )
  }


  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount() {
    scrollToTop();

    const { org_id } = this.state;
    const params = {
      org_id: org_id
    }

    this.props.requestGetStrategyReportFiles(params);
  }

  render(){
    const { t } = this.props;
    const { listStrategyReportsFiles } = this.props;
    return(
    <Container>
      <div className={ !listStrategyReportsFiles ? "ui active inverted dimmer" : "ui inverted dimmer"}>
        <div className="ui large text loader">Loading</div>
      </div>
      <div className="strategy-reports-file-heading">
        {t('strategyAndReportsFile.strategyAndReportsFileHeading')}
      </div>
      <div className="strategy-reports-file-wrapper">
        { 
          listStrategyReportsFiles && (listStrategyReportsFiles.listStrategyReportsFiles.length > 0) ?
          listStrategyReportsFiles.listStrategyReportsFiles.map((strategyReportsFile, index)=> (this.getStrategyReportFilesView(strategyReportsFile, index)) )
            : this.getEmptyStateView() 
        }
      </div>
    </Container>
    );
  };

}

function mapStateToProps(state) {
  return {
    userSession: state.auth.userSession,
    listStrategyReportsFiles: state.app.listStrategyReportsFiles,
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    requestGetStrategyReportFiles: requestGetStrategyReportFiles
  },dispatch)
}

export default translate(['translations'], translateOptions)(connect(mapStateToProps, mapDispatchToProps)(StrategyAndReportsFilesView));