import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../../i18n/config';
import _ from 'lodash';

class ReviewStakeholderConfigurationsView extends Component {
  constructor(props){
    super(props);
    this.state = {
      modalOpened: this.props.open
    }
  }
  handleModalClose() {
    if(this.state.modalOpened) {
      this.setState({modalOpened: false});
      this.props.onClose();
    }
  }

  callConfigureStakeholder() {
    this.props.UpdateStakeHolderConfiguredCallback({'is_stakeholder_configured': true});
    this.props.showOrHideModel();
    this.handleModalClose();
  }

  handlePrint() {
    window.print();
  }

  getStakeholderView(stakeHolderResponse) {
    const { t } = this.props;
    stakeHolderResponse = _.orderBy(stakeHolderResponse, 'weight', 'desc');
    return(
      <table className="ui basic table">
        <thead>
          <tr>
            <th>{t('reviewStakeholderConfigsModal.stakeholderTableHeading1')}</th>
            <th>{t('reviewStakeholderConfigsModal.stakeholderTableHeading2')}</th>
          </tr>
        </thead>
        <tbody>
          {
            stakeHolderResponse.map((stakeholder, index) => {
              return (
                <tr key={index}>
                  <td>{stakeholder.name}</td>
                  <td>{stakeholder.weight}</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    )
  }

  getGradeView(gradesResponse) {
    const { t } = this.props;
    return(
      <table className="ui basic table">
        <thead>
          <tr>
            <th>{t('reviewStakeholderConfigsModal.gradePointsHeading1')}</th>
            <th>{t('reviewStakeholderConfigsModal.gradePointsHeading2')}</th>
          </tr>
        </thead>
        <tbody>
          {
            gradesResponse.map((grade, index) => {
              return (
                <tr key={index}>
                  <td>{grade.name}</td>
                  <td>{grade.points}</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    )
  }

  render(){
    const {stakeHolderResponse, gradesResponse, t} = this.props;
    return(
      <div>
        <Modal className="review-stakeholder-configs-view" size={this.props.size} closeOnEscape={this.props.closeOnEscape} closeOnDimmerClick={this.props.closeOnDimmerClick} open={this.state.modalOpened} onClose={()=>this.handleModalClose()}>
          <Modal.Header>
            <div className="icon-alert-icon"></div>
            <div className="heading-content">
              <p>
                {t('reviewStakeholderConfigsModal.heading1')}
              </p>
              <p>
                {t('reviewStakeholderConfigsModal.heading2')}
              </p>
            </div>
          </Modal.Header>
          <Modal.Content>
            <div className="stakeholder-configurations">
              <div className="heading-wrapper-modal">
                <div className="heading">
                  {t('reviewStakeholderConfigsModal.stakeholderWeightsHeading')}
                </div>
                <div className="print-link" onClick={()=>this.handlePrint()}>Print</div>
              </div>
              {
                stakeHolderResponse && stakeHolderResponse.stakeHolderResponse.length>0 &&
                this.getStakeholderView(stakeHolderResponse.stakeHolderResponse)
              }
            </div>
            <div className="grade-values-wrapper">
              <div className="heading">
              {t('reviewStakeholderConfigsModal.gradePointsHeading')}
              </div>
              {
                gradesResponse && gradesResponse.gradesResponse &&
                this.getGradeView(gradesResponse.gradesResponse)
              }
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button color="orange" icon='checkmark' labelPosition='left' content={'cancel'} onClick={()=>this.handleModalClose()}/>
            <Button color="orange" icon='checkmark' labelPosition='left' content={'proceed'} onClick={()=> this.callConfigureStakeholder()}/>
          </Modal.Actions>
        </Modal>
      </div>
    )
  };

};

export default translate(['translations'], translateOptions)(connect(null)(ReviewStakeholderConfigurationsView));