import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { translateOptions } from '../../i18n/config';
import { scrollToTop } from '../../utils/misc-utils';
import {FAQ_ICON} from "../../constants/images";
import {
  requestValidateStakeholderAndCriteriaConfig
} from '../../actions/app-actions';
import { getActiveOrg } from "../../helpers/auth-helpers";

class AnalysisTypesView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.state={
      org_id: getActiveOrg(userSession)
    };
  };

  /***************************
   * METHODS
   ***************************/
  getrequestValidateStakeholderAndCriteriaConfig = () => {
    let params = {};
    const { org_id } = this.state;
    params['org_id'] = org_id;
    this.props.requestValidateStakeholderAndCriteriaConfig(params);
  }

  /***************************
   * VIEWS
   ***************************/
  getHeroBannerContentView(){
    const { t } = this.props;
    return(
      <Container className="relative-position">
        <div className="banner-content-wrapper">
          <div className="banner-heading">
            <p className="small-heading">{t('analysisTypesPage.bannerSmallHeading')}</p>
            <p className="big-heading">{t('analysisTypesPage.bannerBigHeading')}</p>
          </div>
          <div className="banner-content-para">{t('analysisTypesPage.bannerContent')}</div>
        </div>
      </Container>
    )
  };

  getAnalysisTypeListView(){
    const { t } = this.props;
    const { analysisTypesConfigured } = this.props;
    let disableAnalysisTypeView = false;
    let analysisTypesConfigurationFetched= false;
    if (analysisTypesConfigured) {
      analysisTypesConfigurationFetched = true;
    }
    if ( analysisTypesConfigured && 
      analysisTypesConfigured.analysisTypesConfigured.is_stakeholder_configured &&
      analysisTypesConfigured.analysisTypesConfigured.is_business_criteria_configured
    ) {
      disableAnalysisTypeView = true;
    }
    else {
      disableAnalysisTypeView = false;
    }
    return(
      <div className="analysis-types-list">
        <div className={ !analysisTypesConfigurationFetched ? "ui active inverted dimmer" : "ui inverted dimmer"}>
          <div className="ui large text loader">Loading</div>
        </div>
        {
          disableAnalysisTypeView &&
          <Link className="analysis-type" to="/stakeholder/questionnaire">
          <div className="icon-wrapper">
            <img src={FAQ_ICON} alt="Faq Icon" />
          </div>
          <div className="analysis-info">
            <div className="analysis-heading">{t('analysisTypesPage.questionnaireListHeading')}</div>
            <div className="analysis-details">{t('analysisTypesPage.questionnaireListInfo')}</div>
          </div>
        </Link>
        }
        {
          disableAnalysisTypeView &&
          <Link className="analysis-type" to={{pathname: '/strategy-report', state: { prevPath: 'analysisTypesMainPage' }}}>
          <div className="icon-wrapper">
            <img src={FAQ_ICON} alt="Faq Icon" />
          </div>
          <div className="analysis-info">
            <div className="analysis-heading">{t('analysisTypesPage.strategyReportsHeading')}</div>
            <div className="analysis-details">{t('analysisTypesPage.strategyReportsInfo')}</div>
          </div>
        </Link>
        }
        {
          analysisTypesConfigurationFetched &&
          <Link className={disableAnalysisTypeView ? "analysis-type cursor-not-allowed" : "analysis-type"} to="/stakeholder-insights">
            <div className={disableAnalysisTypeView ? "icon-wrapper reduced-opacity-disabled" : "icon-wrapper"}>
              <img src={FAQ_ICON} alt="Faq Icon" />
            </div>
            <div className={disableAnalysisTypeView ? "analysis-info reduced-opacity-disabled" : "analysis-info"}>
              <div className="analysis-heading">{t('analysisTypesPage.analysisType1Heading')}</div>
              <div className="analysis-details">{t('analysisTypesPage.analysisType1Info')}</div>
            </div>
          </Link>
        }
        {
          analysisTypesConfigurationFetched &&
          <Link className={disableAnalysisTypeView ? "analysis-type cursor-not-allowed" : "analysis-type"} to="/business-ability">
            <div className={disableAnalysisTypeView ? "icon-wrapper reduced-opacity-disabled" : "icon-wrapper"}>
              <img src={FAQ_ICON} alt="Faq Icon" />
            </div>
            <div className={disableAnalysisTypeView ? "analysis-info reduced-opacity-disabled" : "analysis-info"}>
              <div className="analysis-heading">{t('analysisTypesPage.analysisType2Heading')}</div>
              <div className="analysis-details">{t('analysisTypesPage.analysisType2Info')}</div>
            </div>
          </Link>
        }
      </div>
    )
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount() {
    scrollToTop();
    this.getrequestValidateStakeholderAndCriteriaConfig();
  }

  render(){
    return(
      <div className="analysis-types-view">
        <div className="hero-banner-wrapper">
          <div className="hero-banner">
            { this.getHeroBannerContentView() }
          </div>
        </div>
        <div className="analysis-types-list-wrapper">
          <Container>
            {this.getAnalysisTypeListView()}
          </Container>
        </div>
      </div>
    );
  };

}

function mapStateToProps(state) {
  return {
    userSession: state.auth.userSession,
    analysisTypesConfigured: state.app.analysisTypesConfigured
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    requestValidateStakeholderAndCriteriaConfig: requestValidateStakeholderAndCriteriaConfig
  },dispatch)
}

export default translate(['translations'], translateOptions)(connect(mapStateToProps, mapDispatchToProps)(AnalysisTypesView));