import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { Container, Breadcrumb } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { isEqual } from 'lodash';

import StakeholderInsightsContainer from './stakeholder-insights-container/stakeholder-insights-container';
import ReviewStakeholderConfigurationsView from './stakeholder-insights-container/review-stakeholder-configurations/review-stakeholder-configurations';

import { translateOptions } from '../../i18n/config';
import { scrollToTop } from '../../utils/misc-utils';
import {
  requestAddStakeHolders, requestClearStakeHolderViewStates,
  requestDeleteStakeHolders,
  requestGradess,
  requestStakeHolders,
  requestUpdateGrades,
  requestUpdateStakeHolders,
  requestUpdateStakeHolderConfigured,
  requestValidateStakeholderAndCriteriaConfig,
  clearStakeholderAndCriteriaConfig
} from '../../actions/app-actions';
import {getActiveOrg} from "../../helpers/auth-helpers";

import AlertModalView from "../common/alert-modal";

class StakeholderInsightsView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);
    const { userSession } = this.props;
    this.state={
      org_id: getActiveOrg(userSession),
      showLoader: false
    };
  };

  /***************************
   * METHODS
   ***************************/
  init(){
    const { org_id } = this.state;
    const params = {
      org_id: org_id
    }
    this.getrequestValidateStakeholderAndCriteriaConfig();
    this.props.clearStakeholderAndCriteriaConfig();
    this.props.requestStakeHolders(params);
  }

  getGrades = ()=> {
    const { org_id } = this.state;
    const params = {
      org_id: org_id
    }
    this.props.requestGrades(params)
  }

  addStakeHolder = (params)=> {
    const { org_id } = this.state;
    params['org_id'] = org_id;
    this.props.requestAddStakeHolder(params);
  }

  deleteStakeHolder = (params)=> {
    const { org_id } = this.state;
    params['org_id'] = org_id;
    this.props.requestDeleteStakeHolder(params);
  }

  updateStakeHolder = (params)=> {
    const { org_id } = this.state;
    params['org_id'] = org_id;
    this.props.requestUpdateStakeHolder(params);
  }

  updateGrades = (params)=> {
    const { org_id } = this.state;
    params['org_id'] = org_id;
    this.props.requestUpdateGrades(params);
  }

  UpdateStakeHolderConfigured = (params)=> {
    const { org_id } = this.state;
    this.props.requestUpdateStakeHolderConfigured(params, org_id);
  }

  clearStates = () => {
    this.props.requestClearStakeHolderViewStates();
  }

  getrequestValidateStakeholderAndCriteriaConfig = () => {
    let params = {};
    const { org_id } = this.state;
    params['org_id'] = org_id;
    this.props.requestValidateStakeholderAndCriteriaConfig(params);
  }

  showOrHideModel() {
    this.setState({showLoader: true});
  }

  showOrHideReviewModal() {
    this.setState({reviewModalOpened: true});
  }

  setAnalysisTypeValuesConfig(config) {
    this.setState({analysisTypesConfigured: config})
  }

  handleReviewModalClose() {
    this.setState({reviewModalOpened: false});
  }

  showLoaderToggle() {
    const {showLoader, analysisTypesConfigured} = this.state;
    if (analysisTypesConfigured) {
      if(!analysisTypesConfigured.analysisTypesConfigured.is_business_criteria_configured &&
      analysisTypesConfigured.analysisTypesConfigured.is_stakeholder_configured) {
        return false;
      } else if (analysisTypesConfigured.analysisTypesConfigured.is_business_criteria_configured &&
        analysisTypesConfigured.analysisTypesConfigured.is_stakeholder_configured) {
        return false;
      } else if(showLoader) {
        return true;
      }
    }
    return false; 
  }

  handlePrint() {
    window.print();
  }

  /***************************
   * VIEWS
   ***************************/
  getHeroBannerContentView(){
    const { t } = this.props;
    return(
      <Container className="relative-position">
        <div className="banner-content-wrapper">
          <div className="banner-heading">
            <p className="small-heading">{t('stakeholdersInsightsPage.bannerSmallHeading')}</p>
            <p className="big-heading">{t('stakeholdersInsightsPage.bannerBigHeading')}</p>
          </div>
          <div className="banner-content-para">{t('stakeholdersInsightsPage.bannerContent')}</div>
        </div>
      </Container>
    )
  };

  getBreadcrumbView(){
    const { t } = this.props;
    return(
      <Breadcrumb size='large'>
        <Breadcrumb.Section>
          <Link to="/analysis-types">{t('stakeholdersInsightsPage.methods')}</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon='right chevron' />
        <Breadcrumb.Section active>{t('stakeholdersInsightsPage.stakeholderInsights')}</Breadcrumb.Section>
      </Breadcrumb>
    )
  }

  alertModalView(openvalue) {
    return (<AlertModalView 
      size={'small'}
      open={openvalue}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      onClose={()=> this.handleModalClose()}
      message={'Please configure business criteria before  proceeding'}
      redirectTo={'/business-ability'}
      continueMessage={'Proceed'}/>)
  }

  continuePageView() {
    return (<AlertModalView 
      size={'small'}
      open={true}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      onClose={()=> this.handleModalClose()}
      message={'Configurations complete.'}
      additionalMessage={'Begin your questionnaire.'}
      redirectTo={'/stakeholder/questionnaire'}
      continueMessage={'Continue to questionnaire'}/>)
  }

  getReviewStakeholderView() {
    return (
      <ReviewStakeholderConfigurationsView
        size={'small'}
        open={true}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={()=> this.handleReviewModalClose()}
        stakeHolderResponse={this.props.stakeHolderResponse}
        gradesResponse={this.props.gradesResponse}
        UpdateStakeHolderConfiguredCallback={(params) => this.UpdateStakeHolderConfigured(params)}
        showOrHideModel= {()=> this.showOrHideModel()}
      />
    )
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount() {
    scrollToTop();

    this.init()
  }

  componentWillReceiveProps(newProps){
    if (!isEqual(newProps.updateStakeHolderResponse, this.props.updateStakeHolderResponse))
      this.init();

    if (newProps.analysisTypesConfigured)
    this.setAnalysisTypeValuesConfig(newProps.analysisTypesConfigured);
  }

  render(){
    const { stakeHolderResponse, addStakeHolderResponse, gradesResponse } = this.props;
    const { analysisTypesConfigured } = this.state;
    let showLoader = this.showLoaderToggle();
    return(
      <div className="stakeholder-insights-view">
        <div className={ (!showLoader) ? "ui inverted dimmer loading-wrapper" : "ui active inverted dimmer loading-wrapper"}>
          <div className="ui large text loader">Loading</div>
        </div>
        <div className="hero-banner-wrapper">
          <div className="hero-banner">
            { this.getHeroBannerContentView() }
          </div>
        </div>
        <div className="breadcrumb-wrapper">
          <Container>
            {this.getBreadcrumbView()}
            <div className="print-link" onClick={()=>this.handlePrint()}>Print</div>
          </Container>
        </div>
        <Container className="main-content-wrapper">
          <StakeholderInsightsContainer
            stakeHolderResponse={stakeHolderResponse}
            addStakeHolderResponse={addStakeHolderResponse}
            gradesResponse={gradesResponse}
            addStakeHolderCallback={(params)=> this.addStakeHolder(params)}
            deleteStakeHolderCallback={(params)=> this.deleteStakeHolder(params)}
            updateStakeHolderCallback={(params)=> this.updateStakeHolder(params)}
            getGradesCallback={()=> this.getGrades()}
            updateGradesCallback={(params)=> this.updateGrades(params)}
            UpdateStakeHolderConfiguredCallback={(params) => this.UpdateStakeHolderConfigured(params)}
            clearStatesCallback={()=> this.clearStates()}
            showOrHideModel= {()=> this.showOrHideModel()}
            showOrHideReviewModal= {()=> this.showOrHideReviewModal()}
          />
        </Container>
          {
            analysisTypesConfigured &&
            !analysisTypesConfigured.analysisTypesConfigured.is_business_criteria_configured &&
            analysisTypesConfigured.analysisTypesConfigured.is_stakeholder_configured &&
            this.alertModalView(true)
          }
          {
            analysisTypesConfigured && 
            analysisTypesConfigured.analysisTypesConfigured.is_stakeholder_configured &&
            analysisTypesConfigured.analysisTypesConfigured.is_business_criteria_configured &&
            this.continuePageView()
          }
          {
            this.state.reviewModalOpened &&
            this.getReviewStakeholderView()
          }
      </div>
    );
  };

}

function mapStateToProps(state) {
  return {
    userSession: state.auth.userSession,
    stakeHolderResponse: state.app.stakeHolderResponse,
    addStakeHolderResponse: state.app.addStakeHolderResponse,
    deleteStakeHolderResponse: state.app.deleteStakeHolderResponse,
    updateStakeHolderResponse: state.app.updateStakeHolderResponse,
    gradesResponse: state.app.gradesResponse,
    updateGradesResponse: state.app.updateGradesResponse,
    analysisTypesConfigured: state.app.analysisTypesConfigured
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    requestStakeHolders: requestStakeHolders,
    requestAddStakeHolder: requestAddStakeHolders,
    requestDeleteStakeHolder: requestDeleteStakeHolders,
    requestUpdateStakeHolder: requestUpdateStakeHolders,
    requestGrades: requestGradess,
    requestUpdateGrades: requestUpdateGrades,
    requestClearStakeHolderViewStates: requestClearStakeHolderViewStates,
    requestUpdateStakeHolderConfigured: requestUpdateStakeHolderConfigured,
    requestValidateStakeholderAndCriteriaConfig: requestValidateStakeholderAndCriteriaConfig,
    clearStakeholderAndCriteriaConfig: clearStakeholderAndCriteriaConfig
  },dispatch)
}

export default translate(['translations'], translateOptions)(connect(mapStateToProps, mapDispatchToProps)(StakeholderInsightsView));