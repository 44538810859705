export const SCATTER_CHART = {
  "INFLUENCE_ON_BUSINESS_KEY": "influence_on_the_business",
  "STAKEHOLDER_PERCEPTIONS_KEY": "stakeholder_perceptions",
  "IMPACT_ON_PEOPLE_KEY": "impact_on_people_and_planet",
  "INFLUENCE_ON_BUSINESS_LABEL": "Influence on the business",
  "IMPACT_ON_PEOPLE_LABEL": "Impact on people, society and the planet",
  "STAKEHOLDER_PERCEPTIONS_LABEL": "Stakeholder insights"
};

export const GRADE_CONSTANTS = {
  "LOW_GRADE": 50,
  "MEDIUM_GRADE": 75,
  "HIGH_GRADE": 100
};

export const AXIS_EXPLANATIONS = {
  "INFLUENCE_ON_BUSINESS": "Influence on the business: <b>your company's impact on people and the planet combined with</b> the topic's influence on <b>your company’s</b> ability to create value.",
  "STAKEHOLDER_PERCEPTIONS": "Stakeholder insights: The degree topics influence stakeholders' perceptions of <b>Your Company</b> and its ability to create value for them.",
}

export const TICK_COUNT = 6