import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../i18n/config';

class EmptyModalView extends Component {

  /***************************
   * VIEWS
   ***************************/
  render(){
    const { t } = this.props;
    return(
      <div className="empty-modal-view">{t('common.errorMsg')}</div>
    );
  };

}

export default translate(['translations'], translateOptions)(connect(null)(EmptyModalView));