import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Form, Grid } from 'semantic-ui-react';
import { translateOptions } from '../../../../../../i18n/config';

class EditBusinessCriteriaDegreeView extends Component {
  constructor(props){
    super(props);
    this.state = {
      fields:{
        id: this.props.businessCriteria.id,
        name: this.props.businessCriteria.name || "",
        weight: this.props.businessCriteria.weight || "",
        defaultCriteria: this.props.businessCriteria.default || null
      }
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(field, e){
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({
      fields,
    });
    this.props.onChangeCallback(fields);
  }

  deleteBusinessCriteria(id){
    const params = {
      business_criteria_id: id
    }
    this.props.deleteBusinessCriterionCallback(params);
  }

  render(){
    const { t } = this.props;
    const { id, name, weight, defaultCriteria } = this.state.fields;

    return(
      <Form.Field className='form-field' style={styles.marginBottom5}>
        <Grid columns='equal' verticalAlign='middle'>
          <Grid.Row className='no-padding'>
            <Grid.Column width={7} className="business-criteria-name">
              <input
                type='text'
                placeholder={t('businessAbilityPage.businessCriteria')}
                onChange={this.handleChange.bind(this, 'name')}
                value={name}
                className={defaultCriteria ? 'no-margin' : 'no-margin clear-opacity'}
                disabled
              />
            </Grid.Column>
            <Grid.Column width={7} className="business-criteria-note">
              <input
                type='number'
                placeholder={t('businessAbilityPage.degree')}
                onChange={this.handleChange.bind(this, 'weight')}
                value={weight}
                min="0"
              />
            </Grid.Column>
            <Grid.Column width={2} className="icon-wrapper" style={{textAlign: 'right'}}>
              <div className="icon icon-trash" onClick={()=> this.deleteBusinessCriteria(id)} ></div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Field>
    );
  }

}

export default translate(['translations'], translateOptions)(connect(null)(EditBusinessCriteriaDegreeView));

const styles = {
  marginBottom5: {
    marginBottom: '5px'
  }
}