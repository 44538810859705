import React, { useState, useEffect } from 'react';
import axios from 'axios';

import './topics-data.css';
import { getAuthToken, getActiveOrg } from '../../helpers/auth-helpers';
import { connect } from 'react-redux';

const ListOfTopics = (props) => {
  const [topicData, setTopicData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userSession } = props;
  const [orgId, setOrgId] = useState(null);
  const [collapsedSubtopics, setCollapsedSubtopics] = useState({});
  const [checkedSubtopics, setCheckedSubtopics] = useState({});
  const [assessmentValues, setAssessmentValues] = useState([]);
  const [rationaleValues, setRationaleValues] = useState([]);
  const [dropdownValues, setDropdownValues] = useState({});

  const BASE_URL = `${window.location.protocol}//${window.location.hostname}/api`;

  const api = axios.create({
    baseURL: BASE_URL,
  });

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const userInfo = await getActiveOrg(userSession);
        setOrgId(userInfo);
      } catch (error) {
        console.error('Error fetching user information:', error);
        // Handle error if needed
      }
    };

    fetchUserInfo();
  }, [userSession]); // Run effect when userSession changes
  useEffect(() => {
    const fetchTopicData = async () => {
      try {
        const responseTopics = await api.get('/standard-topics/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
          },
        });
        setTopicData(responseTopics.data);
        setLoading(false);

        // Initialize collapsed state for each subtopic
        setCollapsedSubtopics(
          responseTopics.data.data?.Categories?.reduce((acc, category) => {
            if (Array.isArray(category.subtopics)) {
              category.subtopics.forEach((subtopic) => {
                acc[subtopic.id] = true;
              });
            }
            return acc;
          }, {}) || {}
        );
      } catch (error) {
        console.error('Error during fetch of topics data:', error);
        setLoading(false);
      }
    };

    fetchTopicData();
  }, []);

  useEffect(() => {
    const fetchAssessments = async () => {
      try {
        const responseAssessments = await api.get('/assessments/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
          },
        });
        setAssessmentValues(responseAssessments.data);
      } catch (error) {
        console.log('Error during fetch of assessments:', error);
      }
    };

    const fetchRationale = async () => {
      try {
        const responseRationales = await api.get('/rationales/', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
          },
        });
        setRationaleValues(responseRationales.data);
      } catch (error) {
        console.log('Error during fetch of rationale:', error);
      }
    };

    fetchAssessments();
    fetchRationale();
  }, []);

  const handleCategoryCollapseToggle = (index) => {
    const categoryRows = document.querySelectorAll('.category-row');
    const categoryRow = categoryRows[index];

    // Check if the category row exists
    if (categoryRow) {
      const topicsTable = categoryRow.querySelector('.topics-table');

      // Check if the topics table exists
      if (topicsTable) {
        const controlCategoriesButton = categoryRow.querySelector(
          '#control-categories'
        );
        topicsTable.classList.toggle('hidden');
        // Toggle the text of the button
        controlCategoriesButton.innerText = topicsTable.classList.contains(
          'hidden'
        )
          ? 'Show topics'
          : 'Hide topics';
      }
    }
  };

  const handleSubtopicCollapseToggle = (subtopicId) => {
    setCollapsedSubtopics((prevState) => ({
      ...prevState,
      [subtopicId]: !prevState[subtopicId],
    }));
  };

  const handleDropdownValuesChange = (topicId, name, selectedOption) => {
    setDropdownValues((prevValues) => {
      return {
        ...prevValues,
        [topicId]: {
          ...prevValues[topicId],
          [name]: selectedOption,
        },
      };
    });
  };

  const resetDropdownSelection = (id) => {
    setDropdownValues((prevValues) => {
      const updatedValues = { ...prevValues };
      delete updatedValues[id];
      return updatedValues;
    });
  };

  const transformToDesiredFormat = (values) => {
    const topics = Object.keys(values).map((id) => {
      const topics = { id: Number(id) };
      if (values[id].rationale) {
        topics.rationale = values[id].rationale;
      }
      if (values[id].assessment) {
        topics.assessment = values[id].assessment;
      }
      return topics;
    });

    return { topics: topics };
  };

  const postData = async () => {
    try {
      const transformedData = transformToDesiredFormat(dropdownValues);
      debugger;
      const response = await api.post(
        `/orgs/${orgId}/topics/assessment_rationale/`,
        transformedData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthToken(),
          },
        }
      );
      console.log('POST request successful:', response.data);
    } catch (error) {
      console.error('Error making POST request:', error);
    }
  };

  const handleCheckboxChange = (subtopicId) => {
    // Toggle the checkbox state
    const isChecked = checkedSubtopics[subtopicId];
    setCheckedSubtopics((prevState) => ({
      ...prevState,
      [subtopicId]: !isChecked,
    }));

    // Get the checkbox element
    const checkbox = document.querySelector(
      `.subtopic-row[data-subtopic-id="${subtopicId}"] input[type="checkbox"]`
    );

    if (checkbox) {
      // Get the parent element of the checkbox, which is the list item (li)
      const listItem = checkbox.closest('li');

      if (listItem) {
        // Add or remove a class to the assessment dropdown parent based on checkbox state
        const assessmentDropdownContainer = listItem.querySelector(
          '.subtopic-actions-content.assessment_type_dropdown'
        );
        if (assessmentDropdownContainer) {
          if (!isChecked) {
            assessmentDropdownContainer.classList.add('unchecked');
          } else {
            assessmentDropdownContainer.classList.remove('unchecked');
          }
        }
      }
    }
  };

  const handleNextButtonClick = () => {
    // Query all checkboxes
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');

    // Filter out rows with unselected checkboxes
    const uncheckedRows = Array.from(checkboxes).filter(
      (checkbox) => !checkbox.checked
    );

    // Check if any checkbox is unchecked
    const isAnyCheckboxUnchecked = uncheckedRows.length > 0;

    // Check if any rationale dropdown has been changed
    const rationaleDropdowns = uncheckedRows.map((checkbox) => {
      const topicRow = checkbox.closest('li');
      return topicRow.querySelector('.rational_dropdown select');
    });

    // Filter out only selected checkboxes for rationaleDropdowns
    const selectedRationaleCheckboxes = Array.from(rationaleDropdowns).filter(
      (dropdown) => {
        const topicRow = dropdown.closest('li');
        const checkbox = topicRow.querySelector('input[type="checkbox"]');
        return checkbox && !checkbox.checked; // Notice the ! (not) operator here
      }
    );

    const isAnyRationaleNotSet = rationaleDropdowns.some(
      (dropdown) => dropdown.value === '0'
    );

    // If there is any unchecked checkbox, display an alert
    if (isAnyCheckboxUnchecked && isAnyRationaleNotSet) {
      // If any rationale dropdown is changed, display an additional alert
      if (isAnyRationaleNotSet) {
        const countOfChangedRationales = rationaleDropdowns.filter(
          (dropdown) => dropdown.value === '0'
        ).length;
        document.getElementById(
          'next-button-error-message'
        ).innerText = `There are ${countOfChangedRationales} unselected subtopics without a rationale.`;

        // Apply styling to affected dropdowns
        selectedRationaleCheckboxes.forEach((dropdown) => {
          // Add red border only if the condition is met
          if (dropdown.value === '0') {
            const dropdownParent = dropdown.closest('.rational_dropdown');
            const noRationaleMessage =
              dropdownParent.querySelector('.no-rationale-set');

            dropdown.style.border = '1px solid red';
            dropdown.style.borderRadius = '2px';
            noRationaleMessage.style.display = 'block';
          }
        });

        // Listen for changes in the rationale dropdown values
        selectedRationaleCheckboxes.forEach((dropdown) => {
          dropdown.addEventListener('change', () => {
            // Remove styling and "Not assessed!" message when the dropdown value changes
            const dropdownParent = dropdown.closest('.rational_dropdown');
            const noRationaleMessage =
              dropdownParent.querySelector('.no-rationale-set');

            dropdown.style.border = '';
            noRationaleMessage.style.display = 'none';
          });
        });
      }
    }
    // If any selected subtopic that is Not assessed
    else {
      // Check if any assessment dropdown has been changed
      const assessmentDropdowns = document.querySelectorAll(
        '.assessment_type_dropdown select'
      );

      // Filter out only selected checkboxes
      const selectedCheckboxes = Array.from(assessmentDropdowns).filter(
        (dropdown) => {
          const topicRow = dropdown.closest('li');
          const checkbox = topicRow.querySelector('input[type="checkbox"]');
          return checkbox && checkbox.checked;
        }
      );

      const isAnyAssessmentNotAssessed = selectedCheckboxes.some(
        (dropdown) => dropdown.value === '0'
      );

      // If any assessment dropdown is changed for selected checkboxes, display an additional alert
      if (isAnyAssessmentNotAssessed) {
        const countOfChangedAssessments = selectedCheckboxes.filter(
          (dropdown) => dropdown.value === '0'
        ).length;
        document.getElementById(
          'next-button-error-message'
        ).innerText = `There are ${countOfChangedAssessments} selected subtopics that are not assessed.`;

        // Apply styling to affected dropdowns
        selectedCheckboxes.forEach((dropdown) => {
          // Add red border and "Not assessed!" message only if the condition is met
          if (dropdown.value === '0') {
            const dropdownParent = dropdown.closest(
              '.assessment_type_dropdown'
            );
            const notAssessedMessage =
              dropdownParent.querySelector('.not-assessed');

            dropdown.style.border = '1px solid red';
            dropdown.style.borderRadius = '2px';
            notAssessedMessage.style.display = 'block';
          }
        });

        // Listen for changes in the assessment dropdown values
        selectedCheckboxes.forEach((dropdown) => {
          dropdown.addEventListener('change', () => {
            // Remove styling and "Not assessed!" message when the dropdown value changes
            const dropdownParent = dropdown.closest(
              '.assessment_type_dropdown'
            );
            const notAssessedMessage =
              dropdownParent.querySelector('.not-assessed');
            dropdown.style.border = '';
            notAssessedMessage.style.display = 'none';
          });
        });
      } else {
        // Clear any previous error messages and remove styling
        document.getElementById('next-button-error-message').innerText =
          'All the selected subtopics are assessed';

        const allDropdowns = document.querySelectorAll(
          '.assessment_type_dropdown select'
        );
        allDropdowns.forEach((dropdown) => {
          const dropdownParent = dropdown.closest('.assessment_type_dropdown');
          const notAssessedMessage =
            dropdownParent.querySelector('.not-assessed');
          dropdown.style.border = ''; // Remove red border from all dropdowns
          notAssessedMessage.style.display = 'none';
        });
        postData();
      }
    }
  };

  const renderTopics = (topics, categoryIndex, nestingLevel = 0) => {
    if (!Array.isArray(topics)) {
      return null;
    }

    const topicsSection = (
      <ul className="topics-list">
        {topics.map((topic) => (
          <li
            key={topic.id}
            className={`subtopic-row ${
              collapsedSubtopics[topic.id] ? 'collapsed' : ''
            }`}
          >
            <div className="topics-list-row">
              {nestingLevel === 1 && (
                <input
                  type="checkbox"
                  defaultChecked={true} // Set all checkboxes to true
                  onChange={() => {
                    handleCheckboxChange(topic.id);
                    resetDropdownSelection(topic.id);
                  }}
                />
              )}
              <button
                className="arrow-button"
                onClick={() => handleSubtopicCollapseToggle(topic.id)}
              >
                <span
                  className={`arrow-icon ${
                    collapsedSubtopics[topic.id] ? 'arrow-right' : 'arrow-down'
                  }`}
                ></span>
              </button>
              {/* Sub-topics level */}
              <h4>
                {nestingLevel === 0 ? (
                  <span>
                    {topic.header}
                    {topic.description === 'None' ? null : (
                      <p>{topic.description}</p>
                    )}
                  </span>
                ) : (
                  topic.header
                )}
              </h4>
              {nestingLevel === 1 && (
                <div className="subtopic-actions-container">
                  {/* Add Assessment Type Dropdown */}
                  <div className="subtopic-actions-content assessment_type_dropdown">
                    <h5 className="dropdown-title">Assessment Type</h5>
                    <p
                      className={`not-assessed ${
                        !checkedSubtopics[topic.id] ? '' : 'hidden'
                      }`}
                    >
                      Not assessed!
                    </p>
                    <select
                      className={`dropdown-select ${
                        !checkedSubtopics[topic.id]
                          ? ''
                          : 'not-checked-assessment-dropdown'
                      }`}
                      name="assessment"
                      value={
                        checkedSubtopics[topic.id]
                          ? 0
                          : dropdownValues[topic.id]?.assessment
                      }
                      disabled={checkedSubtopics[topic.id]}
                      onChange={(e) =>
                        handleDropdownValuesChange(
                          topic.id,
                          'assessment',
                          parseInt(e.target.value)
                        )
                      }
                    >
                      {assessmentValues.data?.Assessments.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="subtopic-actions-content rational_dropdown">
                    {/* Add Rationale Dropdown */}
                    <h5 className="dropdown-title">Rationale</h5>
                    <p
                      className={`no-rationale-set ${
                        checkedSubtopics[topic.id] ? '' : 'hidden'
                      }`}
                    >
                      No rationale provided!
                    </p>
                    <select
                      className={`dropdown-select  ${
                        checkedSubtopics[topic.id]
                          ? ''
                          : 'not-checked-rationale-dropdown'
                      }`}
                      name="rationale"
                      value={
                        !checkedSubtopics[topic.id]
                          ? 0
                          : dropdownValues[topic.id]?.rationale
                      }
                      disabled={!checkedSubtopics[topic.id]}
                      onChange={(e) =>
                        handleDropdownValuesChange(
                          topic.id,
                          'rationale',
                          parseInt(e.target.value)
                        )
                      }
                    >
                      {rationaleValues.data?.Rationales.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>

            <div
              className={`subtopic-content ${
                collapsedSubtopics[topic.id] ? 'hidden' : ''
              }`}
            >
              {topic.subtopics &&
                topic.subtopics.length > 0 &&
                renderTopics(topic.subtopics, categoryIndex, nestingLevel + 1)}
            </div>
          </li>
        ))}
      </ul>
    );

    return <div>{topicsSection}</div>;
  };

  return (
    <div className="main-container">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="top-categories-container">
          <ul className="top-categories-list">
            {topicData.data?.Categories?.map((category, index) => (
              <div key={category.id} className="category-row">
                <div className="category-row-header">
                  <h3>{category.header}</h3>
                  <button
                    id="control-categories"
                    onClick={() => handleCategoryCollapseToggle(index)}
                  >
                    Hide topics
                  </button>
                </div>
                <div className="topics-table">
                  {renderTopics(category.subtopics, index)}
                </div>
              </div>
            ))}
          </ul>

          <div className="bottom-actions-container">
            <p id="next-button-error-message"></p>
            <button onClick={handleNextButtonClick}>Next</button>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userSession: state.auth.userSession,
});

export default connect(mapStateToProps)(ListOfTopics);
