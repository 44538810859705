import { zipObject } from 'lodash';

const ACTION_TYPES = [

  // Auth
  'RECEIVE_LOGIN_SUCCESS_RESPONSE',
  'RECEIVE_LOGIN_ERROR_RESPONSE',

  'RECEIVE_REGISTER_RESPONSE',
  'RECEIVE_LOGOUT_RESPONSE',
  'RECEIVE_RESET_PASSWORD_LINK_RESPONSE',
  'RECEIVE_RESET_PASSWORD_RESPONSE',
  'CLEAR_AUTH_STATE_VARIABLES',
  'RECEIVE_ORGANISATION_DETAILS_RESPONSE',
  'RECEIVE_REFRESH_TOKEN_RESPONSE',
  'RECEIVE_UPDATE_USERNAME_RESPONSE',
  'RECEIVE_UPDATE_PASSWORD_RESPONSE',
  'RECEIVE_ORGANISATION_VERSIONS_RESPONSE',
  'RECEIVE_VERIFY_EMAIL',

  'RECEIVE_STAKE_HOLDER_RESPONSE',
  'RECEIVE_ADD_STAKE_HOLDER_RESPONSE',
  'RECEIVE_DELETE_STAKE_HOLDER_RESPONSE',
  'RECEIVE_UPDATE_STAKE_HOLDER_RESPONSE',
  'RECEIVE_GRADES_RESPONSE',
  'RECEIVE_UPDATE_GRADES_RESPONSE',
  'CLEAR_STAKEHOLDER_VIEW_STATES',
  'RECEIVE_STAKEHOLDER_CONFIGURED_RESPONSE',

  'RECEIVE_BUSINESS_CRITERIA_RESPONSE',
  'RECEIVE_ADD_BUSINESS_CRITERIA_RESPONSE',
  'RECEIVE_DELETE_BUSINESS_CRITERIA_RESPONSE',
  'RECEIVE_UPDATE_BUSINESS_CRITERIA_RESPONSE',
  'RECEIVE_BUSINESS_CRITERIA_STRATEGY_PERIOD_RESPONSE',
  'RECEIVE_UPDATE_BUSINESS_CRITERIA_STRATEGY_PERIOD_RESPONSE',
  'CLEAR_BUSINESS_CRITERIA_VIEW_STATES',
  'RECEIVE_UPDATE_STRATEGY_PERIOD_RESPONSE',
  'RECEIVE_BUSINESS_CRITERIA_CONFIGURED_RESPONSE',
  'RECEIVE_SELECTED_STAKEHOLDER_RESPONSE',
  'RECEIVE_LIST_OF_TOPICS_RESPONSE',

  'RECEIVE_ANALYSE_DRIVERS_RESPONSE',
  'RECEIVE_STAKEHOLDER_INTERVIEWS_RESPONSE',
  'RECEIVE_VALUE_CHAIN_BOUNDARIES_RESPONSE',
  'RECEIVE_STRATEGY_AND_REPORTS_FILES_RESPONSE',

  'RECEIVE_VALIDATE_CONFIGS_FOR_QUESTIONNAIRE',
  'RECEIVE_QUESTIONNAIRE_QUESTION',
  'RECEIVE_QUESTIONNAIRE_QUESTION_RESPONSE',
  'RECEIVE_LIST_OF_TOPIC_CATEGORIES_RESPONSE',
  'RECEIVE_QUESTIONNAIRE_GRADE_POINTS_RESPONSE',
  'RECEIVE_EDIT_WEIGHTAGE_VALUES_RESPONSE',
  'RECEIVE_UPDATE_EDIT_WEIGHTAGE_VALUES_RESPONSE',
  'RECEIVE_LIST_OF_RESPONDENTS_RESPONSE',
  'REQUEST_CLEAR_RESPONDENT',
  'REQUEST_CLEAR_QUESTIONNAIRE_QUESTIONNAIRE_TOPIC_CATEGORIES',

  'RECEIVE_PREVIEW_QUESTIONNAIRE_RESPONSE',
  'RECEIVE_EDIT_QUESTINNAIRE_ANSWER_RESPONSE',

  'RECEIVE_STRATEGY_REPORT_VALUES_RESPONSE',
  'RECEIVE_STRATEGY_REPORT_LAST_VISITED_RESPONSE',

  'REQUEST_CLEAR_STAKEHOLDER_CRITERIA_CONFIG',
  'REQUEST_CLEAR_QUESTIONNAIRE_PREVIEW',

  'RECEIVE_EDIT_RESPONDENT',
  'RECEIVE_INDIVIDUAL_RESPONDENT',

  'RESET_ANSWER_QUESTIONNAIRE_QUESTION_RESPONSE'
];

export default zipObject(ACTION_TYPES, ACTION_TYPES);