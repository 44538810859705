import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Button } from 'semantic-ui-react';
import { translate } from 'react-i18next';
import { translateOptions } from '../../i18n/config';
import {
  requestVerifyEmail
} from '../../actions/app-actions';
import { DONE_ICON } from '../../constants/images';

class EmailVerifiedView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    this.handleVerifyEmail = this.handleVerifyEmail.bind(this);
  };
  /***************************
   * METHODS
   ***************************/
  handleVerifyEmail() {
    let href = window.location.href;
    let indexOfVerify = href.indexOf('verify');
    let uidAndCode = href.substr(indexOfVerify+7).split('/');

    let params = {
      uid: uidAndCode[0],
      code: uidAndCode[1]
    }

    this.props.requestVerifyEmail(params);
  }
  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount() {
    this.handleVerifyEmail()
  }

  render(){
    const { t } = this.props;
    return(
      <Container className="email-verify-container">
        {
          !this.props.emailVerifyResponse &&
          <div class="ui active inverted dimmer">
            <div class="ui small text loader">Loading</div>
          </div>
        }
        <div className="ui center aligned very padded segment email-verify-wrapper">
          {
            this.props.emailVerifyResponse && this.props.emailVerifyResponse.isSuccess &&
            <div className="message-content-wrapper">
              <img src={DONE_ICON} alt="success"/>
              <div className="message-content">
                <div>
                  {t('emailVerify.successHeader')}
                </div>
                <div>
                  {t('emailVerify.successContent')}
                </div>
              </div>
            </div>
          }
          {
            this.props.emailVerifyResponse && this.props.emailVerifyResponse.isError &&
            <div className="message-content-wrapper">
              <div className="icon-alert-icon"> </div>
              <div className="message-content">
                <div>
                  {t('emailVerify.failureHeader')}
                </div>
                <div>
                  {t('emailVerify.failureContent')}
                </div>
              </div>
            </div>
          }
          <a href='/'>
            <Button positive fluid>Go to homepage</Button>
          </a>
        </div>
      </Container>
    );
  };
}

function mapStateToProps(state) {
  return {
    userSession: state.auth.userSession,
    emailVerifyResponse: state.auth.emailVerifyResponse
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    requestVerifyEmail: requestVerifyEmail,
  },dispatch)
}

export default translate(['translations'], translateOptions)(connect(mapStateToProps, mapDispatchToProps)(EmailVerifiedView));