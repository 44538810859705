import ActionTypes from '../constants/action-type';

export const initialState =  {

};

const auth = (state=initialState, action)=> {

  if (!action.type) {
    console.log('Payload missing a type!', action);
  }

  switch (action.type) {

    case ActionTypes.RECEIVE_LOGIN_SUCCESS_RESPONSE: {
      const userSession = {
        user: action.response.user,
        token: action.response.token,
        refresh_token: action.response.refresh_token,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, userSession};
    }

    case ActionTypes.RECEIVE_LOGIN_ERROR_RESPONSE:{
      const loginResponse = {
        message: action.response.message,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      }
      return {...state, loginResponse};
    }

    case ActionTypes.RECEIVE_REGISTER_RESPONSE: {
      const registerResponse = {
        registerResponse: action.response.registerResponse,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, registerResponse};
    }

    case ActionTypes.RECEIVE_RESET_PASSWORD_LINK_RESPONSE: {
      const resetPasswordResponse = {
        resetPasswordResponse: action.response.detail,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, resetPasswordResponse};
    }

    case ActionTypes.RECEIVE_RESET_PASSWORD_RESPONSE: {
      let setPasswordResponse;
      if (action.response.isSuccess){
        setPasswordResponse = {
          setPasswordResponse: action.response.data,
          isSuccess: action.response.isSuccess,
          isError: action.response.isError
        };
      } else {
        setPasswordResponse = {
          setPasswordResponse: action.response.response,
          isSuccess: action.response.isSuccess,
          isError: action.response.isError
        };
      }
      return {...state, setPasswordResponse};
    }

    case ActionTypes.RECEIVE_LOGOUT_RESPONSE: {
      return {...state, userSession: null};
    }

    case ActionTypes.CLEAR_AUTH_STATE_VARIABLES: {
      return {
        ...state,
        registerResponse: undefined,
        loginResponse: undefined,
        resetPasswordResponse: undefined
      }
    }

    case ActionTypes.RECEIVE_REFRESH_TOKEN_RESPONSE: {
      state.userSession.token = action.response.token;
      return state;
    }

    case ActionTypes.RECEIVE_UPDATE_USERNAME_RESPONSE: {
      state.userSession.user = action.response.data;
      return state;
    }

    case ActionTypes.RECEIVE_UPDATE_PASSWORD_RESPONSE: {
      if(action.response.isSuccess) {
        const updatePasswordResponse = {
          updatePasswordResponse: action.response.detail,
          isSuccess: action.response.isSuccess,
          isError: action.response.isError
        }
        return {...state, updatePasswordResponse};
      }
      else {
        return state;
      }
    }

    case ActionTypes.RECEIVE_VERIFY_EMAIL: {
      const emailVerifyResponse = {
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      }
      return {...state, emailVerifyResponse};
    }

    default:{
      return state;
    }

  }

};

export default auth;