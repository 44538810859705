import React, { Component } from 'react';

import Header from '../common/header';
import Footer from '../common/footer';
import AuthModule from '../auth-module/auth-module';

class BaseLayout extends Component {

  /***************************
   * METHODS
   ***************************/
  handleShowModalView = (modalView) => {
    this.authModule.show(modalView);
  };

  handleLogoutUser = () => {
    this.authModule.logout();
  };

  /***************************
   * LIFECYCLE
   ***************************/
  render() {
    return (
      <div className="app-wrapper">
        <Header
          showModalViewCallback={(view)=> this.handleShowModalView(view)}
          logoutUserCallback={()=> this.handleLogoutUser()}
        />
        <div className="app-views-wrapper">
          {this.props.children}
        </div>
        <Footer />
        <AuthModule onRef={ref => (this.authModule = ref)} />
      </div>
    );
  }
}

export default BaseLayout;