import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../i18n/config';
import Moment from 'react-moment';

class StrategyAndReportsFilesComponent extends Component {
  constructor(props){
    super(props);
    this.state = {
      fields: {
        fileName: this.props.strategyReportsFile.file_name,
        filesrc: this.props.strategyReportsFile.file,
        uploadedBy: this.props.strategyReportsFile.uploaded_by,
        created: this.props.strategyReportsFile.updated_at
      }
    }
  }

  render(){
    const { t } = this.props;
    const { fileName, filesrc, uploadedBy, created } = this.state.fields;

    return(
      <a className="ui grid strategy-reports-file-item" href={filesrc} download={fileName} target="_blank">
        <div className="one wide column icon-wrapper">
          <div className="icon-file-text">
          </div>
        </div>
        <div className="twelve wide column strategy-reports-file-file-info">
          <div className="filename-info">{fileName}</div>
          <div className="uploadedby-info"> {t('strategyAndReportsFile.uploadedByText')} {uploadedBy.first_name} {uploadedBy.last_name}</div>
        </div>
        <div className="three wide column upload-date">
          <Moment format="MMMM DD, YYYY" date={created} />
        </div>
      </a>
    );
  }

}

export default translate(['translations'], translateOptions)(connect(null)(StrategyAndReportsFilesComponent));
