import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../i18n/config';

import LeftSidePanel from './left-panel-view/left-panel-view';
import RightSidePanel from './right-panel-view/right-panel-view';
import { STEP_ONE } from '../../../constants/misc';

class BusinessAbilityContainer extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    this.state={
      activeStep: STEP_ONE
    };
  };

  /***************************
   * METHODS
   ***************************/
  handleUpdateView = (view)=> {
    this.setState({activeStep: view});
  }

  /***************************
   * LIFECYCLE
   ***************************/
  render(){
    const { activeStep } = this.state;
    const { businessCriteriaResponse, addBusinessCriteriaResponse, businessCriterionStrategyPeriodResponse } = this.props;
    return(
      <div className="business-ability-container">
        <LeftSidePanel
          activeStep={activeStep}
        />
        {businessCriteriaResponse && <RightSidePanel
          activeStep={activeStep}
          businessCriteriaResponse={businessCriteriaResponse}
          businessCriterionStrategyPeriodResponse={businessCriterionStrategyPeriodResponse}
          addBusinessCriteriaResponse={addBusinessCriteriaResponse}
          updateViewCallback={(step)=> this.handleUpdateView(step)}
          getStrategyPeriodCallback={()=> this.props.getStrategyPeriodCallback()}
          addBusinessCriterionCallback={(params)=> this.props.addBusinessCriterionCallback(params)}
          deleteBusinessCriterionCallback={(params)=> this.props.deleteBusinessCriterionCallback(params)}
          updateBusinessCriterionCallback={(params)=> this.props.updateBusinessCriterionCallback(params)}
          updateStrategyPeriodCallback={(params)=> this.props.updateStrategyPeriodCallback(params)}
          updateSelectedStrategyPeriodCallback={(params)=> this.props.updateSelectedStrategyPeriodCallback(params)}
          updateBusinessCriteriaConfigured={(params)=> this.props.updateBusinessCriteriaConfigured(params)}
          clearStatesCallback={()=> this.props.clearStatesCallback()}
          showOrHideModel= {()=> this.props.showOrHideModel()}
          showOrHideReviewModal= {()=> this.props.showOrHideReviewModal()}
          selectedStrategyPeriodForBuisnessCriteria = {this.props.selectedStrategyPeriodForBuisnessCriteria}
        />}
      </div>
    );
  };

}

export default translate(['translations'], translateOptions)(connect(null)(BusinessAbilityContainer));