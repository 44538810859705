import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../../../i18n/config';
import { Form, Button } from 'semantic-ui-react';
import { isEqual } from 'lodash';

import ErrorMessageView from '../../../../common/error-message-view';
import EditBusinessCriteriaDegreeView from './edit-business-criteria-degree-view/edit-business-criteria-degree-view';
import { STEP_ONE, STEP_THREE } from '../../../../../constants/misc';
import { isDegreeSumInvalid } from '../../../../../utils/string-utils';

class StepTwoView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    this.state={
      errorMessage: ""
    };
  };

  /***************************
   * METHODS
   ***************************/
  handleChange(index, params){
    const { businessCriterias } = this.state;
    businessCriterias[index] = params;
    this.setState({businessCriterias, errors:undefined});
  }

  handleSubmit(){
    this.updateView(STEP_THREE);
  }

  goBack = (view) => {
    this.props.updateViewCallback(view);
  }

  updateView(view){
    const { t } = this.props;
    const { businessCriterias } = this.state;
    if (this.isFormInValid()){
      this.setState({errorMessage: t('businessAbilityPage.noDegreeErrorMessage')}, ()=>{
        this.resetErrorMessage();
      });
    } else if (isDegreeSumInvalid(businessCriterias)){
       this.setState({errorMessage: t('businessAbilityPage.degreeErrorMessage')}, ()=>{
         this.resetErrorMessage();
       });
    } else {
      this.props.updateViewCallback(view);
      this.props.updateBusinessCriterionCallback(businessCriterias);
    }
  }

  updateBusinessCriterions(businessCriterias){
    this.setState({businessCriterias: businessCriterias}, ()=>{
      this.forceUpdate();
    });
  }

  resetErrorMessage(){
    setTimeout(()=> {
      this.setState({errorMessage: ""});
    }, 3000);
  }

  isFormInValid = () => {
    let isFormInValid;
    const { businessCriterias } = this.state;
    for (let index=0; index<businessCriterias.length; index++){
      const weight = businessCriterias[index].weight;
      if (weight === null){
        isFormInValid = true;
        break;
      } else {
        isFormInValid = false;
      }
    }
    return isFormInValid;
  }

  getBusinessCriteriaView = (businessCriteria, index) => {
    const elementId = `key_${businessCriteria.name}_${index}`;
    return(
      <EditBusinessCriteriaDegreeView
        key={ elementId }
        index={ index }
        businessCriteria={ businessCriteria }
        errors={this.state.errors}
        onChangeCallback={(params)=> this.handleChange(index, params)}
        deleteBusinessCriterionCallback={(params)=> this.props.deleteBusinessCriterionCallback(params)}
      />
    )
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount() {
    if (this.props.businessCriteriaResponse){
      const { businessCriteriaResponse } = this.props;
      this.updateBusinessCriterions(businessCriteriaResponse.businessCriteriaResponse);
    }
  }

  componentWillMount(){
    const { businessCriteriaResponse } = this.props;
    const businessCriteria = businessCriteriaResponse.businessCriteriaResponse;
    this.updateBusinessCriterions(businessCriteria);
  }

  componentWillReceiveProps(newProps){
    if (!isEqual(newProps.businessCriteriaResponse, this.props.businessCriteriaResponse)){
      this.updateBusinessCriterions(newProps.businessCriteriaResponse.businessCriteriaResponse);
    }
  }

  componentWillUnmount(){
    this.setState({errorMessage: ""});
    this.props.clearStatesCallback();
  }

  render(){
    const { t } = this.props;
    const { errorMessage, businessCriterias } = this.state;

    return(
      <div className="step-two-view">
        <div className="back-btn-wrapper">
          <div className="back-btn" onClick={()=> this.goBack(STEP_ONE)}>
            <div className="icon-wrapper">
              <span className="icon-icn_back"></span>
            </div>
            <div className="btn-text">
              {t('businessAbilityPage.backToBusinessCriteriasStep1')}
            </div>
          </div>
        </div>
        <div className="view-heading-wrapper">
          <div className="heading">
            {t('businessAbilityPage.step2ViewHeading')}
          </div>
        </div>
        <div className="step-two-form-wrapper">
          <Form onSubmit={(e) => this.handleSubmit(e)}>
            { businessCriterias.map((businessCriteria, index)=> (this.getBusinessCriteriaView(businessCriteria, index)) ) }
            <div className="error-wrapper" style={{marginTop: '20px'}}>
              { errorMessage && <ErrorMessageView error={errorMessage} /> }
            </div>
            <div className='form-footer'>
              <Button className='app-btn orange-solid-btn' type="submit">
                {t('common.next')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  };

}

export default translate(['translations'], translateOptions)(connect(null)(StepTwoView));