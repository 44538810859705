import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../../../i18n/config';
import { Form, Button, Grid } from 'semantic-ui-react';
import { isEqual } from 'lodash';

import EditBusinessCriteriaView from './edit-business-criteria-view/edit-business-criteria-view';
import ErrorMessageView from '../../../../common/error-message-view';
import EmptyStateView from '../../../../common/empty-state-view';
import { STEP_TWO } from '../../../../../constants/misc';

class StepOneView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    this.state={
      newBusinessCriteria: "",
      isError: false
    };

    this.handleAddBusinessCriteriaChange = this.handleAddBusinessCriteriaChange.bind(this);
  };

  /***************************
   * METHODS
   ***************************/
  handleChange(index, params){
    const { businessCriterias } = this.state;
    businessCriterias[index] = params;
    this.setState({businessCriterias, errors:undefined});
  }

  handleSubmit(){
    const { newBusinessCriteria } = this.state;
    if(newBusinessCriteria) {
      if(this.state.listBusinessCriteriasName.indexOf(newBusinessCriteria) === -1) {
        const params = {
          name: newBusinessCriteria
        }
        this.props.addBusinessCriterionCallback(params);
      } else {
        this.setState({isError: true});
      }
    } else {
      this.setState({isBusinessCriteriaMissingError: true});
    }
  }

  handleAddBusinessCriteriaChange(e){
    let businessCriteriaName = e.target.value;
    this.setState({
      newBusinessCriteria: businessCriteriaName.toLowerCase(),
      isBusinessCriteriaMissingError: false
    });
    if(this.state.isError) {
      this.setState({isError: false});
    }
  }

  updateView(){
    const { businessCriterias } = this.state;
    this.props.updateViewCallback(STEP_TWO);
    this.props.updateBusinessCriterionCallback(businessCriterias);
  }

  updateBusinessCriterions(businessCriterias){
    let existingCriterias = this.state.businessCriterias;
    let newCriterias = businessCriterias.map((critera) => {
      if(existingCriterias && existingCriterias.length > 0) {
        existingCriterias.map((existingCriteria) => {
          if((existingCriteria.id === critera.id) && (existingCriteria.notes !== critera.notes)) {
            critera.notes = existingCriteria.notes;
          }
          if((existingCriteria.id === critera.id) && (existingCriteria.name !== critera.name)) {
            critera.name = existingCriteria.name;
          }
        })
      }
      return critera
    })
    this.setState({businessCriterias: newCriterias}, ()=>{
      this.forceUpdate();
    });
    let businessCriteriasNameList = businessCriterias.map((criteria) => {
      let businessCriteriaName = criteria.name;
      return businessCriteriaName.toLowerCase()
    })
    this.setState({listBusinessCriteriasName: businessCriteriasNameList});
  }

  clearInput = ()=> {
    this.setState({newBusinessCriteria: ""});
  }

  clearError = () => {
    setTimeout(() => {
      this.setState({isError: false, isBusinessCriteriaMissingError: false});
    }, 5000);
  }

  /***************************
   * VIEWS
   ***************************/
  getAddBusinessCriteriaView(){
    const { t, addBusinessCriteriaResponse } = this.props;
    const { newBusinessCriteria, isError, isBusinessCriteriaMissingError } = this.state;
    return(
      <Form onSubmit={(e) => this.handleSubmit(e)}>
        <Form.Field className='form-field'>
          <Grid columns='equal' verticalAlign='middle'>
            <Grid.Row className='no-padding'>
              <Grid.Column width={14} className="business-criteria-name">
                <input
                  type='text'
                  placeholder={t('businessAbilityPage.addBusinessCriteriaPlaceholder')}
                  onChange={this.handleAddBusinessCriteriaChange}
                  value={newBusinessCriteria}
                  className='no-margin'
                  title={t('businessAbilityPage.addBusinessCriteriaPlaceholder')}
                />
              </Grid.Column>
              <Grid.Column width={2}>
                <Button className='orange-solid-btn' type="submit">
                  {t('common.add')}
                </Button>
              </Grid.Column>
            </Grid.Row>
            <div className="error-msg-wrapper">
              { isError && !isBusinessCriteriaMissingError && <ErrorMessageView error={t('businessAbilityPage.businessCriteriaExistsError')} />}
              { !isError && isBusinessCriteriaMissingError && <ErrorMessageView error={t('businessAbilityPage.businessCriteriaMissingError')} />}
            </div>
          </Grid>
        </Form.Field>
      </Form>
    )
  }

  getBusinessCriteriaView = (businessCriteria, index) => {
    const elementId = `key_${businessCriteria.id}_${index}`;
    return(
      <EditBusinessCriteriaView
        key={ elementId }
        index={ index }
        businessCriteria={ businessCriteria }
        errors={this.state.errors}
        onChangeCallback={(params)=> this.handleChange(index, params)}
        deleteBusinessCriterionCallback={(params)=> this.props.deleteBusinessCriterionCallback(params)}
      />
    )
  }

  getEmptyStateView = () => {
    const { t } = this.props;
    return(
      <EmptyStateView message={t('businessAbilityPage.businessCriteriaEmptyStateMsg')} />
    )
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentWillMount(){
    const { businessCriteriaResponse } = this.props;
    const businessCriteria = businessCriteriaResponse.businessCriteriaResponse;
    this.updateBusinessCriterions(businessCriteria);
  }

  componentWillReceiveProps(newProps){
    if (!isEqual(newProps.businessCriteriaResponse, this.props.businessCriteriaResponse))
      this.updateBusinessCriterions(newProps.businessCriteriaResponse.businessCriteriaResponse);

    if (newProps.addBusinessCriteriaResponse){
      if (newProps.addBusinessCriteriaResponse.isSuccess){
        this.clearInput();
      } else {
        this.setState({isError: true, isBusinessCriteriaMissingError: false}, () => {
          this.clearError()
        });
      }
    }
  }

  componentWillUnmount(){
    this.props.clearStatesCallback();
  }

  render(){
    const { t } = this.props;
    const { businessCriterias } = this.state;

    return(
      <div className="step-one-view">
        <div className="add-business-criteria-form-view">
          { this.getAddBusinessCriteriaView() }
        </div>
        <div className="step-one-form-wrapper">
          <Form onSubmit={(e) => this.handleSubmit(e)}>
            { (businessCriterias.length > 0) ?
              businessCriterias.map((businessCriteria, index)=> (this.getBusinessCriteriaView(businessCriteria, index)) )
              : this.getEmptyStateView() }
            <div className='form-footer'>
              <Button className='app-btn orange-solid-btn' type="submit" onClick={()=> this.updateView()} disabled={(businessCriterias.length == 0)}>
                {t('common.next')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  };

}

export default translate(['translations'], translateOptions)(connect(null)(StepOneView));