import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { Button, Form } from 'semantic-ui-react';
import { translateOptions } from '../../../i18n/config';

import ErrorMessageView from '../../common/error-message-view';

import { requestSetPassword } from '../../../actions/auth-actions';

class SetPasswordPageView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    this.state={
      fields: {
        password: '',
        confirmPassword: ''
      },
      serverError: "",
        isFormViewVisible: true,
      errors: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  };

  /***************************
   * METHODS
   ***************************/
  handleChange = (e)=> {
    let fields = this.state.fields;
    const field = e.target.name;
    fields[field] = e.target.value;
    this.setState({
      fields,
      errors: {}
    })
  }

  handleSubmit = (e)=> {
    e.preventDefault();
    const formValidationFeedback = this.handleFormValidation(this.state.fields);
    if(formValidationFeedback.isFormValid){
      const { match } = this.props;
      const fields = this.state.fields;
      const params = {
        new_password1: fields.password,
        new_password2: fields.confirmPassword,
        uid: match.params.uid,
        token: match.params.token
      };
      this.props.requestSetPassword(params);
    }else{
      this.setState({errors:formValidationFeedback.errors});
    }
  }

  handleFormValidation = (fields)=> {
    let errors = {};
    let formIsValid = true;
    let result = {};

    if(fields["password"] === ''){
      formIsValid = false;
      errors["password"] = "*This field is required.";
    } else if(fields["confirmPassword"] === ''){
      formIsValid = false;
      errors["confirmPassword"] = "*Please retype new password";
    } else if(fields["confirmPassword"] !== fields["password"]){
      formIsValid = false;
      errors["confirmPassword"] = "Password mismatch.";
    }

    result['errors'] = errors;
    result['isFormValid'] = formIsValid;
    return result;
  }

  showLoginView = ()=> {
    this.props.history.push("/?ref=login");
  }

  /***************************
   * VIEWS
   ***************************/
  getFieldErrorView = (error)=> {
    return(
      <ErrorMessageView error={error} />
    )
  }

  getSetPasswordFormView = ()=> {
    const { t } = this.props;
    const { errors, fields, serverError } = this.state;

    return(
      <div>
        <div className="heading">{t('auth.resetPassword')}</div>
        <Form className="set-password-form" onSubmit= {this.handleSubmit}>
          <Form.Field>
            <input
              name='password'
              type='password'
              placeholder={t('auth.password')}
              onChange={this.handleChange}
              value={fields["email"]}
              className={`${errors['email'] && 'highlight-input'}`}
            />
            {errors['password'] && this.getFieldErrorView(errors["password"])}
          </Form.Field>
          <Form.Field>
            <input
              name='confirmPassword'
              type='password'
              placeholder={t('auth.confirmPassword')}
              onChange={this.handleChange}
              value={fields["confirmPassword"]}
              className={`${errors['confirmPassword'] && 'highlight-input'}`}
            />
            {errors['confirmPassword'] && this.getFieldErrorView(errors["confirmPassword"])}
          </Form.Field>
          <div className="error-msg-wrapper">
            { serverError && this.getFieldErrorView(serverError)}
          </div>
          <div className="btn-wrapper">
            <Button className="app-btn" type='submit'>{t('common.submit')}</Button>
          </div>
        </Form>
      </div>
    )
  }

  getPasswordSetSuccessView = ()=> {
    const { t } = this.props;
    return(
      <div className="password-set-success-view">
        <div className="heading">{t('auth.passwordReset')}</div>
        <div className="form-text">{t('auth.passwordSetSuccess')}</div>
        <div className="btn-wrapper">
          <Button className="app-btn" type='submit' onClick={()=> this.showLoginView()}>
            {t('auth.backToLogin')}
          </Button>
        </div>
      </div>
    )
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentWillReceiveProps(newProps){
    if (newProps.setPasswordResponse.isSuccess)
      this.setState({isFormViewVisible: false});
    else
      this.setState({serverError: newProps.setPasswordResponse.setPasswordResponse});
  };

  render(){
    const { isFormViewVisible } = this.state;
    return(
      <div className="set-password-view-container">
        <div className="set-password-view">
          { isFormViewVisible ? this.getSetPasswordFormView() : this.getPasswordSetSuccessView() }
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    setPasswordResponse: state.auth.setPasswordResponse
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    requestSetPassword: requestSetPassword
  },dispatch)
}

export default translate(['translations'], translateOptions)(connect(mapStateToProps, mapDispatchToProps)(SetPasswordPageView));