import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../../i18n/config';

import StepOneView from './step-one-view/step-one-view';
import StepTwoView from './step-two-view/step-two-view';
import StepThreeView from './step-three-view/step-three-view';

import {
  STEP_ONE,
  STEP_THREE,
  STEP_TWO
} from '../../../../constants/misc';

class RightSidePanel extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    this.state={};
  };

  /***************************
   * VIEWS
   ***************************/
  getStepOneView = ()=> {
    const { businessCriteriaResponse, addBusinessCriteriaResponse } = this.props;
    return(
      <StepOneView
        businessCriteriaResponse={businessCriteriaResponse}
        updateViewCallback={(step)=> this.props.updateViewCallback(step)}
        addBusinessCriteriaResponse={addBusinessCriteriaResponse}
        addBusinessCriterionCallback={(params)=> this.props.addBusinessCriterionCallback(params)}
        deleteBusinessCriterionCallback={(params)=> this.props.deleteBusinessCriterionCallback(params)}
        updateBusinessCriterionCallback={(params)=> this.props.updateBusinessCriterionCallback(params)}
        clearStatesCallback={()=> this.props.clearStatesCallback()}
      />
    )
  }

  getStepTwoView = ()=> {
    const { businessCriteriaResponse } = this.props;
    return(
      <StepTwoView
        businessCriteriaResponse={businessCriteriaResponse}
        updateViewCallback={(step)=> this.props.updateViewCallback(step)}
        deleteBusinessCriterionCallback={(params)=> this.props.deleteBusinessCriterionCallback(params)}
        updateBusinessCriterionCallback={(params)=> this.props.updateBusinessCriterionCallback(params)}
        clearStatesCallback={()=> this.props.clearStatesCallback()}
      />
    )
  }

  getStepThreeView = ()=> {
    const { businessCriterionStrategyPeriodResponse } = this.props;
    return(
      <StepThreeView
        businessCriterionStrategyPeriodResponse={businessCriterionStrategyPeriodResponse}
        updateViewCallback={(step)=> this.props.updateViewCallback(step)}
        getStrategyPeriodCallback={()=> this.props.getStrategyPeriodCallback()}
        updateStrategyPeriodCallback={(params)=> this.props.updateStrategyPeriodCallback(params)}
        updateSelectedStrategyPeriodCallback={(params)=> this.props.updateSelectedStrategyPeriodCallback(params)}
        updateBusinessCriteriaConfigured={(params)=> this.props.updateBusinessCriteriaConfigured(params)}
        showOrHideModel= {()=> this.props.showOrHideModel()}
        showOrHideReviewModal= {()=> this.props.showOrHideReviewModal()}
        selectedStrategyPeriodForBuisnessCriteria = {this.props.selectedStrategyPeriodForBuisnessCriteria}
      />
    )
  }

  /***************************
   * LIFECYCLE
   ***************************/
  render(){
    const { activeStep } = this.props;

    return(
      <div className="right-side-panel">
        { (activeStep === STEP_ONE) && this.getStepOneView() }

        { (activeStep === STEP_TWO) && this.getStepTwoView() }

        { (activeStep === STEP_THREE) && this.getStepThreeView() }
      </div>
    );
  };

}

export default translate(['translations'], translateOptions)(connect(null)(RightSidePanel));