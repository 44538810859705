import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../../../i18n/config';
import { Form, Button, Grid } from 'semantic-ui-react';
import { isEqual } from 'lodash';

import ErrorMessageView from '../../../../common/error-message-view';
import EditStrategyPeriodView from './edit-strategy-period-view/edit-strategy-period-view';
import EmptyStateView from '../../../../common/empty-state-view';

import { STEP_TWO } from '../../../../../constants/misc';
import { isDataAvailable } from '../../../../../utils/array-utils';

class StepThreeView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    this.state={
      errorMessage: "",
      customStrategyPeriod: false,
      selectedStrategyPeriod: null
    };

    this.strategyPeriodSelected = this.strategyPeriodSelected.bind(this);
  };

  /***************************
   * VIEWS
   ***************************/
  getDetermineValuesView(){
    const { t } = this.props;
    const { strategyPeriod, errorMessage } = this.state;
    const { selectedStrategyPeriod } = this.state;
    let strategyPeriodList = [];
    let isCustomStrategyPeriod = true;
    if (isDataAvailable(strategyPeriod)) {
      strategyPeriodList = strategyPeriod.map((year, index) => {
        if(year.year === selectedStrategyPeriod) {
          isCustomStrategyPeriod = false;
        }
        return (
          <EditStrategyPeriodView
            classNames={selectedStrategyPeriod === year.year ? "strategy-period-select selected" : "strategy-period-select"}
            key={`key_${strategyPeriod.year}_${index}`}
            index={ index }
            strategyPeriod={ year }
            errors={this.state.errors}
            strategyPeriodSelected={this.strategyPeriodSelected}
            selectedStrategyPeriod = {this.state.selectedStrategyPeriod}
          />
        )
      });

      strategyPeriodList.push(
        <div className="other-startegy-period" key='addother' onClick={() => this.addNewStrategyPeriod()}>
          {t('businessAbilityPage.otherStrategyPeriod')}
        </div>
      )
    } else {
      strategyPeriodList.push(
        this.getEmptyStateView()
      )
      strategyPeriodList.push(
        <div className="other-startegy-period" key='addother' onClick={() => this.addNewStrategyPeriod()}>
          {t('businessAbilityPage.otherStrategyPeriod')}
        </div>
      )
    }

    return(
      <Form className="ui menus" onSubmit={(e) => this.handleSubmit(e)}>
        { strategyPeriodList }
        {
          this.state.customStrategyPeriod || isCustomStrategyPeriod &&
          <Form.Field className='form-field'>
            <Grid columns='equal' verticalAlign='middle'>
              <Grid.Row className='no-padding'>
                <Grid.Column width={7}>
                  <input
                    type='number'
                    placeholder='Enter a strategy period'
                    onChange={(e)=>this.handleCustomStrategyPeriodChange(e)}
                    value={isCustomStrategyPeriod ? this.state.selectedStrategyPeriod : ''}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form.Field>
        }
        <div className="error-wrapper" style={{marginTop: '20px'}}>
          { errorMessage && <ErrorMessageView error={errorMessage} /> }
        </div>
        <div className='form-footer'>
          <Button className='app-btn orange-solid-btn' type="submit">
            {t('common.finish')}
          </Button>
        </div>
      </Form>
    )
  }

  getEmptyStateView = ()=> {
    const { t } = this.props;
    return(
      <EmptyStateView key={'emptyStateView'} message={t('businessAbilityPage.noStrategyPeriod')} />
    )
  }

  /***************************
   * METHODS
   ***************************/
  handleChange(index, params){
    const { strategyPeriod } = this.state;
    strategyPeriod[index] = params;
    this.setState({strategyPeriod, errors:undefined});
  }

  handleCustomStrategyPeriodChange(e){
    const value = e.target.value;
    this.setState({selectedStrategyPeriod: value});
  }

  handleSubmit(){
    const { t } = this.props;
    const { selectedStrategyPeriod } = this.state;
    if (this.isFormInValid()){
      this.setState({errorMessage: t('businessAbilityPage.noStrategyPeriodSelected')}, ()=>{
        this.resetErrorMessage();
      });
    } else if(this.isStrategyPeriodValid()) {
      this.setState({errorMessage: t('businessAbilityPage.invalidStrategyPeriodSelected')}, ()=>{
        this.resetErrorMessage();
      });
    } else {
      this.props.updateSelectedStrategyPeriodCallback({year: selectedStrategyPeriod});
      this.props.showOrHideReviewModal();
    }
  }

  updateView(view){
    this.props.updateViewCallback(view);
  }

  updateBusinessCriterionStrategyPeriod(strategyPeriod){
    this.setState({strategyPeriod: strategyPeriod});
  }

  updateSelectedBusinessCriteria(selectedStrategyPeriod) {
    this.setState({selectedStrategyPeriod: selectedStrategyPeriod.year});
  }

  isFormInValid = () => {
    let isFormInValid;
    const { strategyPeriod } = this.state;
    if(this.state.selectedStrategyPeriod === null || this.state.selectedStrategyPeriod === '') {
      return true;
    }
    for (let index=0; index<strategyPeriod.length; index++){
      const year = strategyPeriod[index].year;
      if ((year === null) || year === ""){
        isFormInValid = true;
        break;
      } else {
        isFormInValid = false;
      }
    }
    return isFormInValid;
  }

  isStrategyPeriodValid() {
    let isPeriodInValid;
    const { selectedStrategyPeriod } = this.state;
    const currentYear = new Date().getFullYear();
    if (selectedStrategyPeriod < currentYear){
      isPeriodInValid = true;
    } else {
      isPeriodInValid = false;
    }
    return isPeriodInValid;
  }

  resetErrorMessage(){
    setTimeout(()=> {
      this.setState({errorMessage: ""});
    }, 3000);
  }

  addNewStrategyPeriod(){
    this.setState({'customStrategyPeriod': !this.state.customStrategyPeriod});
    this.setState({selectedStrategyPeriod: null});
  }

  strategyPeriodSelected(year){
    this.setState({selectedStrategyPeriod: year});
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentWillMount(){
    this.props.getStrategyPeriodCallback();
  }

  componentDidMount() {
    if (this.props.businessCriterionStrategyPeriodResponse){
      const { businessCriterionStrategyPeriodResponse } = this.props;
      this.updateBusinessCriterionStrategyPeriod(businessCriterionStrategyPeriodResponse.businessCriterionStrategyPeriodResponse);
    }

    if (this.props.selectedStrategyPeriodForBuisnessCriteria && this.props.selectedStrategyPeriodForBuisnessCriteria.selectedStrategyPeriod) {
      const { selectedStrategyPeriodForBuisnessCriteria } = this.props;
      this.updateSelectedBusinessCriteria(selectedStrategyPeriodForBuisnessCriteria.selectedStrategyPeriod);
    }
  }

  componentWillReceiveProps(newProps){
    if (!isEqual(newProps.businessCriterionStrategyPeriodResponse, this.props.businessCriterionStrategyPeriodResponse))
      this.updateBusinessCriterionStrategyPeriod(newProps.businessCriterionStrategyPeriodResponse.businessCriterionStrategyPeriodResponse);

    if (!isEqual(newProps.selectedStrategyPeriodForBuisnessCriteria, this.props.selectedStrategyPeriodForBuisnessCriteria)
        && newProps.selectedStrategyPeriodForBuisnessCriteria.selectedStrategyPeriod) {
      const { selectedStrategyPeriodForBuisnessCriteria } = newProps;
      this.updateSelectedBusinessCriteria(selectedStrategyPeriodForBuisnessCriteria.selectedStrategyPeriod);
    }
  }

  componentWillUnmount(){
    this.setState({errorMessage: ""});
  }

  render(){
    const { t } = this.props;
    return(
      <div className="step-three-view">
        <div className="back-btn-wrapper">
          <div className="back-btn" onClick={()=> this.updateView(STEP_TWO)}>
            <div className="icon-wrapper">
              <span className="icon-icn_back"></span>
            </div>
            <div className="btn-text">
              {t('businessAbilityPage.backToBusinessCriteriasStep2')}
            </div>
          </div>
        </div>
        <div className="view-heading-wrapper">
          <div className="heading">
            {t('businessAbilityPage.step3ViewHeading')}
          </div>
        </div>
        <div className="step-three-form-wrapper">
          {this.getDetermineValuesView()}
        </div>
      </div>
    );
  };

}

export default translate(['translations'], translateOptions)(connect(null)(StepThreeView));