import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { CLOSE_ICON } from '../../../constants/images';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../i18n/config';
import { connect } from 'react-redux';

class ChooseRespondentModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      modalOpened: this.props.open
    }
  }

  /***************************
   * METHODS
   ***************************/
  handleModalClose() {
    if(this.state.modalOpened) {
      this.setState({modalOpened: false});
      this.props.handleModalCloseCallback(false);
    }
  }

  respondentSelected(respondent) {
    this.props.respondentSelected(respondent);
    this.handleModalClose();
  }

  /***************************
   * VIEWS
   ***************************/
  getChooseRespondentComponentView(respondent, index) {
    return(
      <div className="respondent-item ui grid" key={respondent.id} onClick={()=>this.respondentSelected(respondent)}>
        <div className="respondent-name ten wide column">
          { respondent.name }
        </div>
        <div className="stakeholder-name six wide column">
          { respondent.stakeholder_name ?  respondent.stakeholder_name : respondent.business_criteria_name}
        </div>
      </div>
    )
  }

  /***************************
   * LIFECYCLE
   ***************************/
  render(){
    const { t } = this.props;
    return(
      <div>
        <Modal className="choose-respondent-model" size={this.props.size} open={this.state.modalOpened} onClose={()=>this.handleModalClose()}>
          <Modal.Header>
            <div className="header-content">
              {t('chooseRespondantModal.heading')}
            </div>
            <div className="close-icon" onClick={()=>this.handleModalClose()}>
              <img src={CLOSE_ICON} alt="close-icon"/>
            </div>
          </Modal.Header>
          <Modal.Content>
            {
              this.props.respondentList.map((respondent, index) => this.getChooseRespondentComponentView(respondent, index))
            }
          </Modal.Content>
        </Modal>
      </div>
    )
  };

};

export default translate(['translations'], translateOptions)(connect(null)(ChooseRespondentModal));