import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import { Container, Button, Form, Dimmer, Loader } from 'semantic-ui-react';
import { translateOptions } from '../../../i18n/config';
import { scrollToTop } from '../../../utils/misc-utils';
import StakeHolderListView from './stakeholder-list/stakeholder-list';
import EmptyStateView from '../../common/empty-state-view';
import ChooseRespondentModal from '../questionnaire-modal-component/input-screen-modal';
import ErrorMessageView from '../../common/error-message-view';

class SelectStakeHolderForQuestionnaire extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);
    this.state = {
      selectedStakeHolder: null,
      stakeholderName: '',
      stakeholderDescription: '',
      openChooseRespondent: false,
      error: false,
      chooseRespondantError: null,
      existingStakeholderSelected: false,
      isLoading: false
    }
    this.handleOnChange = this.handleOnChange.bind(this);
  };

  /***************************
   * METHODS
   ***************************/
  handleFormSubmit(){
    if (this.vaidateSubmit()) {
      const params = {
        stakeholder: this.state.selectedStakeHolder,
        name: this.state.stakeholderName,
        description: this.state.stakeholderDescription,
      }
      this.setState({isLoading: true});
      if (!this.state.existingStakeholderSelected) {
        this.props.updateAnalysisTypeSelectedCallBack(params, {type: 'stakeholder'});
      }
      else {
        params.respondent_id = this.state.selectedRespondentId;
        this.props.requestEditRespondent(params)
      }
    } else {
      this.setState({error: true})
    }
  }

  handleOnChange(id){
    if(this.state.error) {
      this.setState({error: false});
    }
    this.setState({selectedStakeHolder: id, existingStakeholderSelected: false});
  }

  handleNameOnChange(e) {
    const value = e.target.value;
    if(this.state.error) {
      this.setState({error: false});
    }
    this.setState({'stakeholderName': value, chooseRespondantError: null});
  }

  handleDescriptionOnChange(e) {
    const value = e.target.value;
    if(this.state.chooseRespondantError) {
      this.setState({chooseRespondantError: null});
    }
    if(this.state.error) {
      this.setState({error: false});
    }
    this.setState({'stakeholderDescription': value});
  }

  vaidateSubmit() {
    if (this.state.selectedStakeHolder && this.state.stakeholderName && this.state.stakeholderDescription) {
      return true;
    }
    else {
      return false;
    }
  }

  handleModalOpen() {
    this.setState({openChooseRespondent: true});
  }

  handleModalClose(value) {
    this.setState({openChooseRespondent: value});
  }

  respondentSelected(respondent) {
    this.setState({
      stakeholderName: respondent.name,
      stakeholderDescription: respondent.description,
      selectedStakeHolder: respondent.stakeholder,
      existingStakeholderSelected: true,
      selectedRespondentId: respondent.id,
      error: false,
      chooseRespondantError: null
    })
  }

  /***************************
   * VIEWS
   ***************************/
  getStakeHolderView = (stakeHolder, index) => {
    const elementId = `key_${stakeHolder.name}_${index}`;
    return(
      <StakeHolderListView
        key={ elementId }
        index={ index }
        stakeHolder={ stakeHolder }
        updateSelectedStakeholderChange={this.handleOnChange}
        defaultSelectedStakeholder={this.state.selectedStakeHolder === stakeHolder.id ? true:false}
      />
    )
  }

  getEmptyStateView = () => {
    const { t } = this.props;
    return(
      <EmptyStateView message={t('stakeholdersInsightsPage.stakeHolderEmptyStateMsg')} />
    )
  }

  getChooseRespondantModal() {
    return (
      <ChooseRespondentModal
        open={true}
        size={'tiny'}
        respondentList={this.props.respondentList}
        handleModalCloseCallback={(value)=>this.handleModalClose(value)}
        respondentSelected={(respondent)=>this.respondentSelected(respondent)}
      />
    )
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount() {
    scrollToTop();
    this.props.getStakeHolderCallBack();
  }

  componentDidUpdate() {
    if(this.state.chooseRespondantError || this.state.error) {
      scrollToTop();
    }
  }

  componentWillReceiveProps(newProps) {
    if(newProps.chooseRespondantError) {
      scrollToTop();
      this.setState({chooseRespondantError: newProps.chooseRespondantError, isLoading: false});
    } else if (this.state.chooseRespondantError && !newProps.chooseRespondantError) {
      this.setState({chooseRespondantError: null});
    }
  }

  render(){
    const { t } = this.props;
    const { stakeHolderResponse } = this.props;
    return(
      <Form className="ui form" onSubmit={(e) => this.handleFormSubmit(e)}>
        {
          this.state.isLoading &&
          <Dimmer active inverted>
            <Loader>Loading</Loader>
          </Dimmer>
        }
        <Container className='select-stake-holder-wrapper'>
          <div className="small-header-container">
            <div className='small-header'>
              {t('questionnaireSelectStakeHolder.stakeholderHeadingSmall')}
            </div>
            <Link to={`/business-criteria/questionnaire`}>
              <div className="toggle-questionnaire">
                {t('questionnaireSelectStakeHolder.businessCriteriaQuestionnaire')}
              </div>
            </Link>
          </div>
          <div className="large-header-container">
            <div className="large-header">
              {t('questionnaireSelectStakeHolder.stakeholderHeadingLarge')}
            </div>
            {
              this.props.respondentList && this.props.respondentList.length !==0 &&
              <div className="choose-respondent toggle-questionnaire" onClick={()=>this.handleModalOpen()}>
              {t('questionnaireSelectStakeHolder.chooseRespondent')}
            </div>
            }
          </div>
          {
            this.state.error &&
            <ErrorMessageView error={t('questionnaireSelectStakeHolder.incompleteFormError')}/>
          }
          {
            this.state.chooseRespondantError &&
            <ErrorMessageView error={this.state.chooseRespondantError}/>
          }
          <div className="ui input fluid">
            <input type="text" value={this.state.stakeholderName} placeholder={t('questionnaireSelectStakeHolder.stakeholderInputNameField')} onChange={(e)=> this.handleNameOnChange(e)}/>
          </div>
          <div className="grouped fields">
            { 
              stakeHolderResponse && (stakeHolderResponse.stakeHolderResponse.length > 0) ?
              stakeHolderResponse.stakeHolderResponse.map((stakeHolder, index)=> (this.getStakeHolderView(stakeHolder, index, this.state.selectedStakeHolder)) )
              : this.getEmptyStateView() 
            }
          </div>
          <div className="ui input fluid">
            <textarea rows='3' value={this.state.stakeholderDescription} type="text" placeholder={t('questionnaireSelectStakeHolder.stakeholderDescribePlaceholder')} onChange={(e)=> this.handleDescriptionOnChange(e)}/>
          </div>
          <div className='form-footer'>
            <Button className='orange-solid-btn questionnaire-btn' type="submit">
              {t('questionnaireSelectStakeHolder.footerButton')}
            </Button>
          </div>
          {
            this.state.openChooseRespondent &&
            this.getChooseRespondantModal()
          }
        </Container>
      </Form>
    );
  };
}

function mapStateToProps(state) {
  return {
    userSession: state.auth.userSession,
  };
}

export default translate(['translations'], translateOptions)(connect(mapStateToProps)(SelectStakeHolderForQuestionnaire));