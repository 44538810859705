import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../i18n/config';
import {DONE_ICON} from "../../../constants/images";
import { CLOSE_ICON } from '../../../constants/images';

class DifferentCategoryModal extends Component {
  constructor(props){
    super(props);
    this.state = {
      modalOpened: this.props.open,
      disableCloseModal: this.props.disableCloseModal,
      closeLoader: false
    }
  }

  /***************************
   * METHODS
   ***************************/
  handleModalClose() {
    if(this.state.modalOpened && !this.state.disableCloseModal) {
      this.setState({modalOpened: false});
      this.props.handleModalCloseCallback('category');
    }
  }

  getNewCategoryQuestion(category) {
    if (category.answered !== (category.answered+category.unanswered)) {
      this.props.getQuestionnaireQuestionForTopic(category.id);
      this.setState({disableCloseModal: false},()=> {
        this.handleModalClose();
      }) 
    }
  }

  /***************************
   * VIEWS
   ***************************/
  categoryItemView(category, index) {
    const { t,questionnaireTopicCategories } = this.props;
    if(questionnaireTopicCategories.questionnaireTopicCategories.categories.length === index+1 && !this.state.closeLoader) {
      this.setState({closeLoader: true})
    }
    return (
      <div className="category-item ui grid" key={category.name} onClick={()=> this.getNewCategoryQuestion(category)}>
        <div className="category-name ten wide column">
          { category.name }
        </div>
        <div className="topics-answered six wide column">
          {t('topicCategoryModal.topicAnswered')}
          <div className="answered-count">
            {category.answered}/{category.answered+category.unanswered}
          </div>
          <div className={category.answered === (category.answered+category.unanswered) ? "icon-done-icon" : "icon-alert-icon"}>
          {
            category.answered === (category.answered+category.unanswered) &&
            <img className="done-icon" src={DONE_ICON}/>
          }
          </div>
        </div>
      </div>
    )
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentWillMount() {
    this.props.getQuestionnaireQuestionForTopic()
  }

  render(){
    const { t, questionnaireTopicCategories } = this.props;
    const { closeLoader } = this.state;
    return(
      <div>
        <Modal className="different-category-model" size={this.props.size} closeOnEscape={this.props.closeOnEscape} closeOnDimmerClick={this.props.closeOnDimmerClick} open={this.state.modalOpened} onClose={()=>this.handleModalClose()}>
          <Modal.Header>
            <div className="header-content">
              {t('topicCategoryModal.heading')}
            </div>
            <div className="close-icon" onClick={()=>this.handleModalClose()}>
              <img src={CLOSE_ICON} alt="close-icon"/>
            </div>
          </Modal.Header>
          <Modal.Content>
            <div className={ !closeLoader ? "ui active inverted dimmer" : "ui inverted dimmer"}>
              <div className="ui large text loader">Loading</div>
            </div>
            {
              questionnaireTopicCategories &&
              questionnaireTopicCategories.questionnaireTopicCategories.categories.map((category, index) => this.categoryItemView(category, index))
            }
            {
              questionnaireTopicCategories &&
              questionnaireTopicCategories.questionnaireTopicCategories.total_answers === questionnaireTopicCategories.questionnaireTopicCategories.total_questions &&
              <Link to={`/${this.props.analysisType}/questionnaire-preview`}>
                <div className="view-results">
                  {t('questionnaireQuestion.viewResults')}
                </div>
              </Link>
            }
          </Modal.Content>
        </Modal>
      </div>
    )
  };

};

function mapStateToProps(state) {
  return {
    userSession: state.auth.userSession,
    questionnaireTopicCategories: state.app.questionnaireTopicCategories,
  };
}

export default translate(['translations'], translateOptions)(connect(mapStateToProps)(DifferentCategoryModal));