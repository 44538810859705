import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../i18n/config';
import { Button, Checkbox, Form } from 'semantic-ui-react'

import ErrorMessageView from '../../common/error-message-view';
import { isValidEmail } from '../../../utils/validation-utils';

class RegisterView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    this.state={
      fields: {
        name: '',
        email: '',
        company: '',
        phone: '',
        isTcAgreed: false
      },
      serverError: '',
      errors: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleTcChange = this.handleTcChange.bind(this);
  }

  /***************************
   * METHODS
   ***************************/
  showLoginView = ()=> {
    this.props.onLoginLinkClickCallback();
  }

  handleChange(e){
    let fields = this.state.fields;
    const field = e.target.name;
    fields[field] = e.target.value;
    this.setState({
      fields,
      errors: {},
      serverError: ""
    });
  }

  handleSubmit = (e)=> {
    e.preventDefault();
    const formValidationFeedback = this.handleFormValidation(this.state.fields);
    if(formValidationFeedback.isFormValid){
      const fields = this.state.fields;
      const params ={
        name: fields.name,
        email: fields.email,
        company_name: fields.company,
        phone: fields.phone
      };
      this.props.onFormSubmitCallback(params);
    }else{
      this.setState({errors:formValidationFeedback.errors});
    }
  }

  handleFormValidation(fields){
    let errors = {};
    let formIsValid = true;
    let result = {};

    if(fields["name"] === ''){
      formIsValid = false;
      errors["name"] = "*This field is required.";
    } else if(fields['email'] === ''){
      formIsValid = false;
      errors["email"] = "*This field is required.";
    } else if(fields["company"] === ''){
      formIsValid = false;
      errors["company"] = "*This field is required.";
    } else if(fields["phone"] === ''){
      formIsValid = false;
      errors["phone"] = "*This field is required.";
    } else if(fields["email"] !== "undefined"){
      if (!isValidEmail(fields["email"])){
        formIsValid = false;
        errors["email"] = "Please provide valid email id.";
      }
    }
    if (!fields["isTcAgreed"]){
      formIsValid = false;
      errors["isTcAgreed"] = "*Please accept the terms & conditions.";
    }

    result['errors'] = errors;
    result['isFormValid'] = formIsValid;
    return result;
  }

  populateLoginError(error){
    this.setState({
      serverError: error
    });
  }

  handleTcChange = () =>{
    let fields = this.state.fields;
    fields['isTcAgreed'] = !fields.isTcAgreed;
    this.setState({
      fields,
      errors: {}
    });
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount(){
    this.props.onRef(this);
  }

  componentWillUnmount(){
    this.props.onRef(undefined);

    this.props.requestClearAuthStatesCallback();
  }

  /***************************
   * VIEWS
   ***************************/
  getFieldErrorView(error){
    return(
      <ErrorMessageView error={error} />
    )
  }

  render(){
    const { t } = this.props;
    const { errors, fields, serverError } = this.state;
    return(
      <div className="register-view">
        <div className="heading">{t('header.signUp')}</div>
        <Form className="register-form" onSubmit={this.handleSubmit}>
          <Form.Field>
            <input
              name='name'
              type='text'
              placeholder={t('auth.name')}
              onChange={this.handleChange}
              value={fields["name"]}
              className={`${errors['name'] && 'highlight-input'}`}
            />
            {errors['name'] && this.getFieldErrorView(errors["name"])}
          </Form.Field>
          <Form.Field>
            <input
              name='email'
              type='email'
              placeholder={t('auth.emailAddress')}
              onChange={this.handleChange}
              value={fields["email"]}
              className={`${errors['email'] && 'highlight-input'}`}
            />
            {errors['email'] && this.getFieldErrorView(errors["email"])}
          </Form.Field>
          <Form.Field>
            <input
              name='company'
              type='text'
              placeholder={t('auth.company')}
              onChange={this.handleChange}
              value={fields["company"]}
              className={`${errors['company'] && 'highlight-input'}`}
            />
            {errors['company'] && this.getFieldErrorView(errors["company"])}
          </Form.Field>
          <Form.Field>
            <input
              name='phone'
              type='text'
              placeholder={t('auth.phone')}
              onChange={this.handleChange}
              value={fields["phone"]}
              className={`${errors['phone'] && 'highlight-input'}`}
            />
            {errors['phone'] && this.getFieldErrorView(errors["phone"])}
          </Form.Field>
          <Form.Field className="checkbox-wrapper">
            <Checkbox label={t('auth.acceptT&C')} onChange={this.handleTcChange} checked={fields.isTcAgreed} />
            {errors['isTcAgreed'] && this.getFieldErrorView(errors["isTcAgreed"])}
          </Form.Field>
          <div className="error-msg-wrapper">
            { serverError && this.getFieldErrorView(serverError)}
          </div>
          <div className="btn-wrapper">
            <Button className="app-btn" type='submit'>{t('header.signUp')}</Button>
          </div>
          <div className="login-here-link" onClick={()=> this.showLoginView()}>{t('auth.loginHereText')}</div>
        </Form>
      </div>
    );
  };
}

export default translate(['translations'], translateOptions)(RegisterView);