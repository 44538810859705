export const MATERIALITY_STEP_ONE = {
  id: 1,
  icon: "icon-icn-analyze-8",
  heading: "1. Analyze drivers",
  url: "/analyse-drivers",
  content: "Navigating 2030 through the lens of four drivers—Changing natural environments, Reaching limits to growth, Shifting demographics, and Interconnecting our world. Based on the findings of respected institutions and research, we’ll conduct an analysis of your changing business landscape and role in society. We’ll include context of how society is evolving (including the Sustainable Development Goals), and how it may affect your market, legislation and changing expectations to 2030."
}

export const MATERIALITY_STEP_TWO = {
  id: 2,
  icon: "icon-icn-topics",
  heading: "2. List of topics",
  url: "/list-of-topics",
  content: "Informed by the Navigating 2030 drivers, we’ll create a list of between 30-40 issues that are relevant to equipping you for a changing business and societal landscape. Topics cover all issues important to your business that can contribute either to creating shared economic, social and environmental value or to reducing negative impacts and risks."
}

export const MATERIALITY_STEP_THREE = {
  id: 3,
  icon: "icon-icn-importance",
  heading: "3. Weight their importance",
  url: "/analysis-types",
  content: "A weighting (or ranking) of the importance of these topics, conducted together with internal experts with insights into prioritized stakeholder groups, the business strategy and your degree of impact on each topic."
}

export const MATERIALITY_STEP_FOUR = {
  id: 4,
  icon: "icon-icn-stakeholders",
  heading: "4. Stakeholder interviews",
  url: "/stakeholder-interviews",
  content: "Through deep-dive interviews with representatives of targeted stakeholder groups, we validate our weighting and gain additional knowledge about stakeholders' future priorities and their assessments of your ability to deliver value over the long term."
}

export const MATERIALITY_STEP_FIVE = {
  id: 5,
  icon: "icon-icn-value",
  heading: "5. Value chain & boundaries",
  url: "/value-chain-and-boundaries",
  content: "Where your responsibility begins and ends. We chart the topics with the greatest impacts along each phase of your value chain and evaluate your ability to influence change at every phase."
}

export const MATERIALITY_STEP_SIX = {
  id: 6,
  icon: "icon-icn-report",
  heading: "6. Report & strategy work",
  url: "/strategy-reports-file",
  content: "Collate findings and how they can be integrated into your business strategy, how you manage your operations and communicate your engagement."
}