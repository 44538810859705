import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../../../i18n/config';
import { Form, Button } from 'semantic-ui-react';
import { isEqual } from 'lodash';

import ErrorMessageView from '../../../../common/error-message-view';
import EditStakeHolderDegreeView from './edit-stake-holder-degree-view/edit-stake-holder-degree-view';
import { STEP_ONE, STEP_THREE } from '../../../../../constants/misc';
import { isDegreeSumInvalid } from '../../../../../utils/string-utils';

class StepTwoView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    this.state={
      errorMessage: ""
    };
  };

  /***************************
   * METHODS
   ***************************/
  handleChange(index, params){
    const { stakeHolders } = this.state;
    stakeHolders[index] = params;
    this.setState({stakeHolders, errors:undefined});
  }

  handleSubmit(){
    this.updateView(STEP_THREE);
  }

  goBack = (view) => {
    this.props.updateViewCallback(view);
  }

  updateView(view){
    const { t } = this.props;
    const { stakeHolders } = this.state;
    if (this.isFormInValid()){
      this.setState({errorMessage: t('stakeholdersInsightsPage.noDegreeErrorMessage')}, ()=>{
        this.resetErrorMessage();
      });
    } else if (isDegreeSumInvalid(stakeHolders)){
       this.setState({errorMessage: t('stakeholdersInsightsPage.degreeErrorMessage')}, ()=>{
         this.resetErrorMessage();
       });
    } else {
      this.props.updateViewCallback(view);
      this.props.updateStakeHolderCallback(stakeHolders);
    }
  }

  updateStakeHolders(stakeHolders){
    this.setState({stakeHolders: stakeHolders}, ()=>{
      this.forceUpdate();
    });
  }

  resetErrorMessage(){
    setTimeout(()=> {
      this.setState({errorMessage: ""});
    }, 3000);
  }

  isFormInValid = () => {
    let isFormInValid;
    const { stakeHolders } = this.state;
    for (let index=0; index<stakeHolders.length; index++){
      const weight = stakeHolders[index].weight;
      if (weight === null){
        isFormInValid = true;
        break;
      } else {
        isFormInValid = false;
      }
    }
    return isFormInValid;
  }

  getStakeHolderView = (stakeHolder, index) => {
    const elementId = `key_${stakeHolder.name}_${index}`;
    return(
      <EditStakeHolderDegreeView
        key={ elementId }
        index={ index }
        stakeHolder={ stakeHolder }
        errors={this.state.errors}
        onChangeCallback={(params)=> this.handleChange(index, params)}
        deleteStakeHolderCallback={(params)=> this.props.deleteStakeHolderCallback(params)}
      />
    )
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount() {
    if (this.props.stakeHolderResponse){
      const { stakeHolderResponse } = this.props;
      this.updateStakeHolders(stakeHolderResponse.stakeHolderResponse);
    }
  }

  componentWillMount(){
    const { stakeHolderResponse } = this.props;
    const stakeHolders = stakeHolderResponse.stakeHolderResponse;
    this.updateStakeHolders(stakeHolders);
  }

  componentWillReceiveProps(newProps){
    if (!isEqual(newProps.stakeHolderResponse, this.props.stakeHolderResponse)){
      this.updateStakeHolders(newProps.stakeHolderResponse.stakeHolderResponse);
    }
  }

  componentWillUnmount(){
    this.setState({errorMessage: ""});
    this.props.clearStatesCallback();
  }

  render(){
    const { t } = this.props;
    const { errorMessage, stakeHolders } = this.state;

    return(
      <div className="step-two-view">
        <div className="back-btn-wrapper">
          <div className="back-btn" onClick={()=> this.goBack(STEP_ONE)}>
            <div className="icon-wrapper">
              <span className="icon-icn_back"></span>
            </div>
            <div className="btn-text">
              {t('stakeholdersInsightsPage.backToStakeholderStep1')}
            </div>
          </div>
        </div>
        <div className="view-heading-wrapper">
          <div className="heading">
            {t('stakeholdersInsightsPage.step2ViewHeading')}
          </div>
        </div>
        <div className="step-two-form-wrapper">
          <Form onSubmit={(e) => this.handleSubmit(e)}>
            { stakeHolders.map((stakeHolder, index)=> (this.getStakeHolderView(stakeHolder, index)) ) }
            <div className="error-wrapper" style={{marginTop: '20px'}}>
              { errorMessage && <ErrorMessageView error={errorMessage} /> }
            </div>
            <div className='form-footer'>
              <Button className='app-btn orange-solid-btn' type="submit">
                {t('common.next')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  };

}

export default translate(['translations'], translateOptions)(connect(null)(StepTwoView));