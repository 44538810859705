import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../../../i18n/config';
import { Form, Button, Grid } from 'semantic-ui-react';
import { isEqual } from 'lodash';

import EditStakeHolderView from './edit-stake-holder-view/edit-stake-holder-view';
import ErrorMessageView from '../../../../common/error-message-view';
import EmptyStateView from '../../../../common/empty-state-view';
import { STEP_TWO } from '../../../../../constants/misc';

class StepOneView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    this.state={
      newStakeHolder: "",
      isError: false
    };

    this.handleAddStakeHolderChange = this.handleAddStakeHolderChange.bind(this);
  };

  /***************************
   * METHODS
   ***************************/
  handleChange(index, params){
    const { stakeHolders } = this.state;
    stakeHolders[index] = params;
    this.setState({stakeHolders, errors:undefined});
  }

  handleSubmit(){
    const { newStakeHolder } = this.state;
    if(newStakeHolder) {
      if(this.state.listStakeHolderName.indexOf(newStakeHolder) === -1) {
        const params = {
          name: newStakeHolder
        }
        this.props.addStakeHolderCallback(params);
      } else {
        this.setState({isError: true});
      }
    } else {
      this.setState({isStakeholderNameMissingError: true});
    }
  }

  handleAddStakeHolderChange(e){
    let stakeholderName = e.target.value;
    this.setState({
      newStakeHolder: stakeholderName.toLowerCase(),
      isStakeholderNameMissingError: false
    });
    if(this.state.isError) {
      this.setState({isError: false});
    }
  }

  updateView(){
    const { stakeHolders } = this.state;
    this.props.updateViewCallback(STEP_TWO);
    this.props.updateStakeHolderCallback(stakeHolders);
  }

  updateStakeHolders(stakeHolders){
    let existingStakeholders = this.state.stakeHolders;
    let newStakeHolders = stakeHolders.map((stakeholder) => {
      if(existingStakeholders && existingStakeholders.length > 0) {
        existingStakeholders.map((existingStakeholder) => {
          if((existingStakeholder.id === stakeholder.id) && (existingStakeholder.notes !== stakeholder.notes)) {
            stakeholder.notes = existingStakeholder.notes;
          }
          if((existingStakeholder.id === stakeholder.id) && (existingStakeholder.name !== stakeholder.name)) {
            stakeholder.name = existingStakeholder.name;
          }
        })
      }
      return stakeholder
    })
    this.setState({stakeHolders: newStakeHolders}, ()=>{
      this.forceUpdate();
    });
    let stakeholderNameList = stakeHolders.map((stakeHolder) => {
      let stakeholderName = stakeHolder.name;
      return stakeholderName.toLowerCase()
    })
    this.setState({listStakeHolderName: stakeholderNameList});
  }

  clearInput = ()=> {
    this.setState({newStakeHolder: ""});
  }

  clearError = () => {
    setTimeout(() => {
      this.setState({isError: false, isStakeholderNameMissingError: false});
    }, 5000);
  }

  /***************************
   * VIEWS
   ***************************/
  getAddStakeHolderView(){
    const { t, addStakeHolderResponse } = this.props;
    const { newStakeHolder, isError, isStakeholderNameMissingError } = this.state;
    return(
      <Form onSubmit={(e) => this.handleSubmit(e)}>
        <Form.Field className='form-field'>
          <Grid columns='equal' verticalAlign='middle'>
            <Grid.Row className='no-padding'>
              <Grid.Column width={14} className="stakeholder-name">
                <input
                  type='text'
                  placeholder={t('stakeholdersInsightsPage.addStakeHolderPlaceholder')}
                  onChange={this.handleAddStakeHolderChange}
                  value={newStakeHolder}
                  className='no-margin'
                  title={t('stakeholdersInsightsPage.addStakeHolderPlaceholder')}
                />
              </Grid.Column>
              <Grid.Column width={2}>
                <Button className='orange-solid-btn' type="submit">
                  {t('common.add')}
                </Button>
              </Grid.Column>
            </Grid.Row>
            <div className="error-msg-wrapper">
              { isError && !isStakeholderNameMissingError && <ErrorMessageView error={t('stakeholdersInsightsPage.stakeholderExistsError')} />}
              { !isError && isStakeholderNameMissingError && <ErrorMessageView error={t('stakeholdersInsightsPage.stakeholderNameMissingError')} />}
            </div>
          </Grid>
        </Form.Field>
      </Form>
    )
  }

  getStakeHolderView = (stakeHolder, index) => {
    const elementId = `key_${stakeHolder.id}_${index}`;
    return(
      <EditStakeHolderView
        key={ elementId }
        index={ index }
        stakeHolder={ stakeHolder }
        errors={this.state.errors}
        onChangeCallback={(params)=> this.handleChange(index, params)}
        deleteStakeHolderCallback={(params)=> this.props.deleteStakeHolderCallback(params)}
      />
    )
  }

  getEmptyStateView = () => {
    const { t } = this.props;
    return(
      <EmptyStateView message={t('stakeholdersInsightsPage.stakeHolderEmptyStateMsg')} />
    )
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentWillMount(){
    const { stakeHolderResponse } = this.props;
    const stakeHolders = stakeHolderResponse.stakeHolderResponse;
    this.updateStakeHolders(stakeHolders);
  }

  componentWillReceiveProps(newProps){
    if (!isEqual(newProps.stakeHolderResponse, this.props.stakeHolderResponse))
      this.updateStakeHolders(newProps.stakeHolderResponse.stakeHolderResponse);

    if (newProps.addStakeHolderResponse){
      if (newProps.addStakeHolderResponse.isSuccess){
        this.clearInput();
      } else {
        this.setState({isError: true, isStakeholderNameMissingError: false}, () => {
          this.clearError()
        });
      }
    }
  }

  componentWillUnmount(){
    this.props.clearStatesCallback();
  }

  render(){
    const { t } = this.props;
    const { stakeHolders } = this.state;

    return(
      <div className="step-one-view">
        <div className="add-stake-holder-form-view">
          { this.getAddStakeHolderView() }
        </div>
        <div className="step-one-form-wrapper">
          <Form onSubmit={(e) => this.handleSubmit(e)}>
            { (stakeHolders.length > 0) ?
              stakeHolders.map((stakeHolder, index)=> (this.getStakeHolderView(stakeHolder, index)) )
              : this.getEmptyStateView() }
            <div className='form-footer'>
              <Button className='app-btn orange-solid-btn' type="submit" onClick={()=> this.updateView()} disabled={(stakeHolders.length === 0)}>
                {t('common.next')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  };

}

export default translate(['translations'], translateOptions)(connect(null)(StepOneView));