import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Icon } from 'semantic-ui-react';
import { translate } from 'react-i18next';

import { translateOptions } from '../../i18n/config';
import { BRAND_LOGO_FOOTER } from '../../constants/images';

class Footer extends Component {

  /***************************
   * VIEWS
   ***************************/
  getLeftSectionView(){
    const { t } = this.props;
    return(
      <div className="left-section-content">
        <div className="our-location-section">
          <div className="heading">{t('footer.ourLocationHeading')}</div>
          <div className="content">{t('footer.locationLine1')}<br /> {t('footer.locationLine2')}</div>
        </div>
        <div className="get-in-touch-section">
          <div className="heading">{t('footer.getInTouch')}</div>
          <div className="content">info@purple-ivy.se</div>
          <div className="contact-number">+46 706 99 8242</div>
        </div>
      </div>
    )
  };

  getRightSectionView(){
    const { t } = this.props;
    return(
      <div className="right-section-content">
        <div className="about-us-section">
          <div className="heading">{t('footer.aboutUs')}</div>
          <div className="content">{t('footer.purplePolicy')}</div>
          <div className="social-link-wrapper">
            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/purple-ivy/">
              <Icon name="linkedin in" />
            </a>
          </div>
        </div>
        <div className="brand-logo-wrapper">
          <img src={BRAND_LOGO_FOOTER} alt="Purple IVY" />
        </div>
      </div>
    )
  };

  /***************************
   * LIFECYCLE
   ***************************/
  render() {
    const { t } = this.props;
    return (
      <div className="footer-view">
        <Container className="footer-content-wrapper">
          <div className="left-section">
            {this.getLeftSectionView()}
          </div>
          <div className="right-section">
            {this.getRightSectionView()}
          </div>
        </Container>
        <div className="all-right-reserved-section">
          <Container className="copy-right-text-wrapper">
            <div className="">
              <Icon name="copyright outline" />{t('footer.allRightReserved')}
            </div>
          </Container>
        </div>
      </div>
    );
  };
}

export default translate(['translations'], translateOptions)(connect(null)(Footer));