import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../../i18n/config';
import {STEP_ONE, STEP_THREE, STEP_TWO} from "../../../../constants/misc";
import {DONE_ICON} from "../../../../constants/images";

class LeftSidePanel extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    this.state={};
  };

  /***************************
   * VIEWS
   ***************************/

  /***************************
   * METHODS
   ***************************/
  isStepOneComplete = () => {
    const { activeStep } = this.props;
    if ((activeStep === STEP_TWO) || (activeStep === STEP_THREE))
      return true;
    else
      return false;
  }

  isStepTwoComplete = () => {
    const { activeStep } = this.props;
    if ((activeStep === STEP_THREE))
      return true;
    else
      return false;
  }

  /***************************
   * LIFECYCLE
   ***************************/
  render(){
    const { t, activeStep } = this.props;
    return(
      <div className="left-side-panel">
        <div className="time-line-bar"></div>
        <div className="business-ability-steps-wrapper">
          <div className="business-ability-step">
            <div className="icon-wrapper">
              { !this.isStepOneComplete() && <div className={`icon ${(activeStep === STEP_ONE) ? 'active' : ''}`}>1</div>}
              { this.isStepOneComplete() && <img alt="complete-icon" className="complete-icon" src={DONE_ICON} /> }
            </div>
            <div className="content-wrapper">
              <div className={`heading ${(activeStep === STEP_ONE) ? 'active' : '' }`}>
                {t('businessAbilityPage.step1Heading')}
              </div>
              <div className="sub-heading">{t('businessAbilityPage.step1SubHeading')}</div>
            </div>
          </div>
          <div className="business-ability-step">
            <div className="icon-wrapper">
              { !this.isStepTwoComplete() && <div className={`icon ${(activeStep === STEP_TWO) ? 'active' : ''}`}>2</div>}
              { this.isStepTwoComplete() && <img alt="complete-icon" className="complete-icon" src={DONE_ICON} />}
            </div>
            <div className="content-wrapper">
              <div className={`heading ${(activeStep === STEP_TWO) ? 'active' : '' }`}>
                {t('businessAbilityPage.step2Heading')}
              </div>
              <div className="sub-heading">{t('businessAbilityPage.step2SubHeading')}</div>
            </div>
          </div>
          <div className="business-ability-step">
            <div className="icon-wrapper">
              <div className={`icon ${(activeStep === STEP_THREE) ? 'active' : ''}`}>3</div>
            </div>
            <div className="content-wrapper">
              <div className={`heading ${(activeStep === STEP_THREE) ? 'active' : '' }`}>
                {t('businessAbilityPage.step3Heading')}
              </div>
              <div className="sub-heading">{t('businessAbilityPage.step3SubHeading')}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

}

export default translate(['translations'], translateOptions)(connect(null)(LeftSidePanel));