export function getErrorMessage(error) {
  let errorString = "";
  if (Array.isArray(error)){
    for (let index=0; index<error.length; index++){
      errorString += error[index].join(',');
    }
  } else if (error && error.hasOwnProperty('message')){
    errorString = error.message;
  } else if (error){
    for(var key in error){
      errorString += `${error[key]} `;
    }
  } else {
    errorString = JSON.stringify(error);
  }

  return errorString;
}

export function getQueryParamValue(queryParams, key) {
  const params = new URLSearchParams(queryParams);
  return params.get(key);
}

export function removeSlash(string) {
  return string.replace(/\//g, '');
}

export function isDegreeSumInvalid(data) {
  let degreeSum = 0;
  for (let i=0; i<data.length; i++){
    const stakeHolder = data[i];
    if (stakeHolder.weight)
      degreeSum = degreeSum + parseInt(stakeHolder.weight);
  }
  if (degreeSum != 100)
    return true;
  else
    return false;
}

export function replaceOrgDetails(orgName, stringToReplace) {
  let returnValue = stringToReplace.replace(/your company/gi,orgName);
  return returnValue;
}