import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Form } from 'semantic-ui-react'
import { translate } from 'react-i18next';
import { translateOptions } from '../../../i18n/config';
import { isEqual } from 'lodash';
import { isDegreeSumInvalid } from '../../../utils/string-utils';
import { getActiveOrg } from '../../../helpers/auth-helpers';
import ErrorMessageView from '../../common/error-message-view';
import EditWeightageComponent from './edit-weightage-component/edit-weightage-component';
import { CLOSE_ICON } from '../../../constants/images';

class EditWeightageModal extends Component {
  constructor(props){
    super(props);
    const { userSession } = this.props;
    this.state = {
      org_id: getActiveOrg(userSession),
      modalOpened: this.props.open,
      weightageChangeValues: [],
      closeLoader: false
    }
  }

  /***************************
   * METHODS
   ***************************/
  updateWeightageValues(weightageValues) {
    const weightage = weightageValues.map((item) => {
      return {
        id: item.id,
        weight: item.weight,
        name: item.name
      }
    });
    this.setState({weightageChangeValues: weightage});
  }
  handleModalClose() {
    if(this.state.modalOpened) {
      this.setState({modalOpened: false});
      this.props.handleModalCloseCallback('weightage');
    }
  }

  handleWeightChange(e, index) {
    const weightageChange = this.state.weightageChangeValues;
    weightageChange[index].weight = parseInt(e.target.value);
    this.setState({weightageChangeValues: weightageChange});
  }

  handleSubmit() {
    const orgParams = {
      org_id: this.state.org_id,
      org_topic_id: this.props.question.org_topic_version,
      type: this.props.analysisType
    }
    const {t} = this.props;
    if(isDegreeSumInvalid(this.state.weightageChangeValues)) {
      this.setState({error: t('questionnaireQuestion.weightageError')});
    } else {
      this.props.updateEditWeightageValues(orgParams,this.state.weightageChangeValues);
      this.setState({weightageChangeValues: []});
      this.handleModalClose();
    }
  }

  /***************************
   * VIEWS
   ***************************/
  getWeightageView(item, index) {
    const elementId = item.id+'_'+item.name;
    if(this.state.weightageChangeValues.length === index+1 && !this.state.closeLoader) {
      this.setState({closeLoader: true})
    }
    return(
      <EditWeightageComponent 
        item={item}
        index={index}
        key={elementId}
        handleWeightChange={(e, index) => this.handleWeightChange(e, index)}
      />
    )
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentWillReceiveProps(newProps) {
    if((!isEqual(newProps.editWeightageValues, this.props.editWeightageValues)) || this.state.weightageChangeValues.length === 0 ) {
      this.updateWeightageValues(newProps.editWeightageValues.editWeightageValues);
    }
  }

  render(){
    const { t } = this.props;
    let { weightageChangeValues, closeLoader } = this.state;
    return(
      <div>
        <Modal className="edit-weightage-model" size={this.props.size} open={this.state.modalOpened} onClose={()=>this.handleModalClose()}>
          <Modal.Header>
            <div className="header-content">
              {t('editWeightageModal.heading')}
            </div>
            <div className="close-icon" onClick={()=>this.handleModalClose()}>
              <img src={CLOSE_ICON} alt="close-icon"/>
            </div>
          </Modal.Header>
          <Modal.Content>
            <div className={ !closeLoader ? "ui active inverted dimmer" : "ui inverted dimmer"}>
              <div className="ui large text loader">Loading</div>
            </div>
            <Form onSubmit={()=>this.handleSubmit()}>
              {
                weightageChangeValues && weightageChangeValues.length>0 &&
                weightageChangeValues.map((item, index) =>this.getWeightageView(item,index))
              }
              {
                this.state.error &&
                <ErrorMessageView error={this.state.error}/>
              }
              <button className="ui orange button save-weightage" type='submit'>
                {t('common.save')}
              </button>
            </Form>
          </Modal.Content>
        </Modal>
      </div>
    )
  };

};

function mapStateToProps(state) {
  return {
    userSession: state.auth.userSession,
    editWeightageValues: state.app.editWeightageValues,
  };
}

export default translate(['translations'], translateOptions)(connect(mapStateToProps)(EditWeightageModal));