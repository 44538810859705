import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Form, Grid } from 'semantic-ui-react';
import { translateOptions } from '../../../../../../i18n/config';

class EditStrategyPeriodView extends Component {
  constructor(props){
    super(props);
    this.state = {
      fields:{
        id: this.props.strategyPeriod.id,
        year: this.props.strategyPeriod.year || ""
      }
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(field, e){
    let fields = this.state.fields;
    if (field === "year" && e.target.value)
      fields["year"] = parseInt(e.target.value);
    else
      fields[field] = e.target.value;
    this.setState({
      fields,
    });
    this.props.onChangeCallback(fields);
  }

  strategyPeriodChange(year) {
    this.props.strategyPeriodSelected(year);
  }

  render(){
    const { year } = this.state.fields;

    return(
      <Form.Field className='form-field' style={styles.marginBottom5}>
        <Grid columns='equal' verticalAlign='middle'>
          <Grid.Row className='no-padding'>
            <Grid.Column width={7}>
              <div className={this.props.classNames} value={year} onClick={() => this.strategyPeriodChange(year)}>
                {year}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Field>
    );
  }

}

export default translate(['translations'], translateOptions)(connect(null)(EditStrategyPeriodView));

const styles = {
  marginBottom5: {
    marginBottom: '5px'
  }
}