import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container } from 'semantic-ui-react'
import { translate } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { translateOptions } from '../../i18n/config';

import { BRAND_LOGO_HEADER } from '../../constants/images';
import { LOGIN_VIEW, REGISTER_VIEW } from '../../constants/misc';
import { getActiveUserEmail, isUserSessionActive } from '../../helpers/auth-helpers';
import { requestOrganisationDetails } from '../../actions/app-actions';
import {getQueryParamValue} from "../../utils/string-utils";

class Header extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    this.state = {
      isUserSessionActive: false
    };
  };

  showModalView = (view) => {
    this.props.showModalViewCallback(view);
  };

  logoutUser = ()=> {
    this.props.logoutUserCallback();
  };

  /***************************
   * VIEWS
   ***************************/
  getLeftSectionView = ()=> {
    const { isUserSessionActive } = this.state;
    return(
      <div className="left-section">
        {
          !isUserSessionActive &&
          <div className="brand-logo-wrapper">
            <Link to="/">
              <img className="brand-logo" src={BRAND_LOGO_HEADER} alt="Purple IVY" />
            </Link>
          </div>
        }
        {
          isUserSessionActive &&
          this.getLoggedInHeaderIcon()
        }
      </div>
    )
  };

  getRightSectionView = ()=> {
    const { isUserSessionActive } = this.state;
    return(
      <div className="right-section">
        { isUserSessionActive ? this.getLoggedInStateView() : this.getLoggedOutStateView() }
      </div>
    )
  };

  getLoggedOutStateView= ()=> {
    const { t } = this.props;
    return(
      <div className="logged-out-state-wrapper">
        <div className="link" onClick={()=> this.showModalView(LOGIN_VIEW)}>{t('header.customerLogin')}</div>
      </div>
    );
  };

  getLoggedInStateView = ()=> {
    const { t, userSession } = this.props;
    return(
      <div className="logged-in-state-wrapper">
        <Link to={'/profile'}>
          <div className="link">{getActiveUserEmail(userSession)}</div>
        </Link>
        <div className="link" onClick={()=> this.logoutUser()}>{t('header.logout')}</div>
      </div>
    );
  };

  getLoggedInHeaderIcon = () => {
    const orgDetails = this.props.organisationDetails;
    let orgIcon;
    if (orgDetails) {
      orgDetails.organisationDetails.icon !== null ?
        orgIcon = orgDetails.organisationDetails.icon :
        orgIcon = orgDetails.organisationDetails.purple_ivy_icon;
    }
    return (
      <div className="brand-logo-wrapper loggedin">
        {
          orgDetails &&
          <Link to="/">
            <img className="brand-logo" src={orgIcon} alt="Purple IVY" />
          </Link>
        }
      </div>
    );
  }
  /***************************
   * METHODS
   ***************************/
  updateUserSession = (sessionStatus)=> {
    this.setState({isUserSessionActive: sessionStatus});
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount(){
    if (isUserSessionActive(this.props.userSession)) {
      this.updateUserSession(true);
      this.props.requestOrganisationDetails(this.props.userSession.user.org);
    }
    else {
      this.updateUserSession(false);
    }
  }

  componentDidUpdate(){
    if(this.state.isUserSessionActive && !this.props.organisationDetails) {
      this.props.requestOrganisationDetails(this.props.userSession.user.org);
    }
  }

  componentWillReceiveProps(newProps){
    const {search} = newProps.location;
    const navigateTo = getQueryParamValue(search, 'ref');
    if (isUserSessionActive(newProps.userSession)) {
      this.updateUserSession(true);
    }
    else
      this.updateUserSession(false);
    if (navigateTo !== null && navigateTo === LOGIN_VIEW)
      this.showModalView(LOGIN_VIEW);
  }

  render() {
    return (
      <div className="header-view">
        <Container className="header-wrapper">
          <div className="left-section-wrapper">
            {this.getLeftSectionView()}
          </div>
          <div className="right-section-wrapper">
            {this.getRightSectionView()}
          </div>
        </Container>
      </div>
    );
  };
}

function mapStateToProps(state) {
  return {
    userSession: state.auth.userSession,
    organisationDetails: state.app.organisationDetails
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators ({
    requestOrganisationDetails: requestOrganisationDetails
  }, dispatch);
}

export default withRouter(translate(['translations'], translateOptions)(connect(mapStateToProps, mapDispatchToProps)(Header)));