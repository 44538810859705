import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../i18n/config';

import LeftSidePanel from './left-panel-view/left-panel-view';
import RightSidePanel from './right-panel-view/right-panel-view';
import { STEP_ONE } from '../../../constants/misc';

class StakeholderInsightsContainer extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    this.state={
      activeStep: STEP_ONE
    };
  };

  /***************************
   * METHODS
   ***************************/
  handleUpdateView = (view)=> {
    this.setState({activeStep: view});
  }


  /***************************
   * LIFECYCLE
   ***************************/
  render(){
    const { activeStep } = this.state;
    const { stakeHolderResponse, addStakeHolderResponse, gradesResponse } = this.props;
    return(
      <div className="stakeholder-insights-container">
        <LeftSidePanel
          activeStep={activeStep}
        />
        {stakeHolderResponse && <RightSidePanel
          activeStep={activeStep}
          stakeHolderResponse={stakeHolderResponse}
          addStakeHolderResponse={addStakeHolderResponse}
          gradesResponse={gradesResponse}
          updateViewCallback={(step)=> this.handleUpdateView(step)}
          addStakeHolderCallback={(params)=> this.props.addStakeHolderCallback(params)}
          deleteStakeHolderCallback={(params)=> this.props.deleteStakeHolderCallback(params)}
          updateStakeHolderCallback={(params)=> this.props.updateStakeHolderCallback(params)}
          getGradesCallback={()=> this.props.getGradesCallback()}
          updateGradesCallback={(params)=> this.props.updateGradesCallback(params)}
          UpdateStakeHolderConfiguredCallback={(params)=> this.props.UpdateStakeHolderConfiguredCallback(params)}
          clearStatesCallback={()=> this.props.clearStatesCallback()}
          showOrHideModel= {()=> this.props.showOrHideModel()}
          showOrHideReviewModal={() => this.props.showOrHideReviewModal()}
        />}
      </div>
    );
  };

}

export default translate(['translations'], translateOptions)(connect(null)(StakeholderInsightsContainer));