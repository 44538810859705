export function isDataAvailable(data) {
  if (data && (data.length > 0))
    return true;
  else
    return false;
}

export function formDataSetForGraph(data, xAxisKey, yAxisKey) {
  let returnList;
  let indexVal = 1;
  if(data && (data.length > 0)) {
    returnList = data.map((item) => {
      return item.topics.map((topic) => {
        if(topic[xAxisKey] === 0 && topic[yAxisKey] === 0) {
          topic.index = null;
        }
        else {
          topic.index = indexVal;
        }
        indexVal++;
        return topic;
      })
    })
  }
  return returnList;
}

export function formLineDataSetForGraph(data, xAxisKey, yAxisKey) {
  let returnList;
  if(data) {
    returnList = [
      {
        [xAxisKey]: data.points,
        [yAxisKey]: 100,
        z: 100,
      },
      {
        [xAxisKey]: data.points,
        [yAxisKey]: data.points,
        z: 100,
      },
      {
        [xAxisKey]: 100,
        [yAxisKey]: data.points,
        z: 100,
      },
    ]
  }
  return returnList;
}

export function getListColor(index) {
  const colors = [
    '#f6e0ba',
    '#b4efe2',
    '#adcdf1',
    '#F7DC6F',
    '#FADBD8',
    '#5499C7',
    '#85929E',
    '#E74C3C',
    '#FFA07A',
    '#2ECC71'
  ];
  return colors;
}

export function getArrowAngle(arrowType) {
  if (arrowType === 'growing_interest_among_stakeholders') {
    return 270;
  }
  if (arrowType === 'growing_interest_among_stakeholders_and_relevance_to_the_business_strategy') {
    return 315;
  }
  if (arrowType === 'growing_relevance_to_the_business_strategy') {
    return 360;
  }
}

export function getTopicNamesForCoordinates(currentPayload, dataSet) {
  let topic = [];
  currentPayload.map((payload) => {
    if(payload.name === "stakeholder_perceptions") {
      return payload;
    }
    let xKey = payload.name;
    let yKey = "stakeholder_perceptions";
    let x = payload.payload[payload.name];
    let y = payload.payload[yKey];
    let matchingDataSet = dataSet.map((data) => {
      let finalData = data.filter((dataItem) => {
        return (dataItem[xKey] === x && dataItem[yKey] === y) ? dataItem : '';
      })
      return finalData
    })
    matchingDataSet.map((data) => {
      data.map((item) => {
        topic.push(item.index+ ". " + item.topic);
        return item;
      })
      return data;
    })
    return payload;
  })
  return topic;
}