import {refreshToken} from '../utils/app-api-utils';
let currentInterval;

export function getAuthToken() {
  let auth;
  const userSession = JSON.parse(localStorage.getItem('state'));
  const authToken = userSession.auth.userSession.token;

  auth = authToken ? `Bearer ${authToken}` : '';
  return auth;
}export function getRefreshToken() {
  let refresh;
  const userSession = JSON.parse(localStorage.getItem('state'));
  const refreshToken = userSession.auth.userSession.refresh_token; // TODO: This should probably be stored in a better and more secure way.. Cookie or something? Localstorage is a vulnerable location

  refresh = (refreshToken) ? refreshToken : '';
  return refresh;
}

export const setStateToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
    if(state.auth.userSession.token) {
      clearInterval(currentInterval);
      currentInterval = setInterval(()=> {
        isTokenValid(state.auth.userSession.token);
      }, 60 * 5 * 1000);
    }
  } catch(err) {
    console.log(err);
  }
};

export const getStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch(err) {
    return undefined;
  }
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export function isUserSessionActive(userSession) {
  let isSessionActive = false;
  if (userSession && userSession.token) {
    isSessionActive = true;
  } else {
    isSessionActive = false;
  }
  return isSessionActive;
};

export function isUserActive() {
  let isSessionActive;
  let userSession = JSON.parse(localStorage.getItem('state'));
  if (userSession && userSession.auth.userSession.token) {
    isSessionActive = true;
  } else {
    isSessionActive = false;
  }
  return isSessionActive;
};

export function isTokenValid(token) {
  const jwt = decodeJwtToken(token);
  let currentTime = new Date();
  let authTokenExpiryTime = new Date(jwt.exp * 1000);
  var timeDiff = Math.abs(authTokenExpiryTime.getTime() - currentTime.getTime());
  var minuteDiff = Math.round(timeDiff / 60000);
  if (minuteDiff <= 30) {
    refreshToken();
  }
};

export function decodeJwtToken(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  const tokenDetails = JSON.parse(window.atob(base64));

  return tokenDetails;
};

export function getActiveUserEmail(userResponse) {
  if (userResponse)
    return userResponse.user.email;
  else
    return "User";
}

export function getActiveOrg(userSession) {
  if (userSession){
    const userDetails = userSession.user;
    const userOrg = userDetails.org;
    return userOrg.id;
  }
}

export function getCompanyName(userSession) {
  if (userSession){
    const userDetails = userSession.user;
    const userOrg = userDetails.org;
    return userOrg.name;
  }
}
