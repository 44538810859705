import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../i18n/config';
import { Button, TextArea } from 'semantic-ui-react';
import {UP_ARROW_ICON, DOWN_ARROW_ICON} from "../../../constants/images";
import { isEqual } from 'lodash';
import EditWeightageModal from '../../questionnaire-view/questionnaire-modal-component/edit-weightage-modal';

class QuestionnaireQuestionList extends Component {
  constructor(props){
    super(props);
    this.state = {
      fields: {
        id: this.props.question.id,
        topicCategory: this.props.question.topic_category,
        gradeLevelID: this.props.question.grade_level.id,
        gradeLevelName: this.props.question.grade_level.name,
        explanation: this.props.question.explanation,
        topicName: this.props.question.topic,
        index: this.props.index,
        perception: this.props.question.perception,
        topicDefinition: this.props.question.topic_description
      },
      editmode: false,
      showEditWeightageModal: false,
      showTopicDefinition: false
    }
  }

  handleEditWeightage(){
    const params = {
      org_topic_version_id: this.props.question.org_topic_version,
    }
    this.props.getEditWeightageValues(params, this.props.analysisType);
    this.setState({showEditWeightageModal: !this.state.showEditWeightageModal});
  }

  handleModalClose(modal){
    if(modal === 'weightage') {
      this.setState({showEditWeightageModal: false});
    }
  }

  getSelectedGradeView() {
    let classname;
    let gradename;
    const { gradeLevelName } = this.state.fields;
    if (gradeLevelName.toUpperCase() === 'LOW') {
      gradename = 'LOW';
      classname = 'green-btn';
    } else if (gradeLevelName.toUpperCase() === 'MEDIUM') {
      gradename = 'MEDIUM';
      classname = 'orange-btn';
    } else if (gradeLevelName.toUpperCase() === 'HIGH') {
      gradename = 'HIGH';
      classname = 'red-btn';
    }
    classname += " preview-btn-grade";
    return (
      <Button key={this.state.fields.gradeLevelID} className={classname}>
        {gradename}
      </Button>
    )
  }

  getEditModeGradeLevel() {
    const { questionnaireGradePoints } = this.props;
    const { gradeLevelName } = this.state.fields;
    if (!questionnaireGradePoints) {
      this.props.getAllGradePoints();
    }
    if (questionnaireGradePoints && questionnaireGradePoints.questionnaireGradePoints.length > 0) {
      const editGradeList = questionnaireGradePoints.questionnaireGradePoints.map((grade) => {
        let classname;
        let gradename = grade.name.toUpperCase();
        if(gradename === 'LOW') {
          classname = "green opacity-btn";           
        } else if(gradename === 'MEDIUM') {
          classname = "yellow opacity-btn";
        } else if (gradename === 'HIGH') {
          classname = "red opacity-btn";
        }
        if (gradename !== gradeLevelName.toUpperCase()) {
          classname += " basic";
        }
        return (
          <Button key={grade.name} className={classname} onClick={()=>this.onGradeSelect(grade.name.toUpperCase(), grade.id)}>
            {grade.name.toUpperCase()}
          </Button>
        )
      })
      return editGradeList;
    }
  }

  onGradeSelect(name, id) {
    let newfield = this.state.fields;
    newfield['gradeLevelID'] = id;
    newfield['gradeLevelName'] = name
    this.setState({fields: newfield});
  }

  editpreviewQuestion(question) {
    this.setState({editmode: true});
    this.props.editCallBack(question);
  }

  closeEdit(question) {
    this.setState({editmode: false});
    this.props.editCallBack();
  }

  handleExplanationChange(e) {
    let {fields} = this.state;
    fields.explanation = e.target.value ? e.target.value : null;
    this.setState({fields: fields});
  }

  capturePerception(value){
    let {fields} = this.state;
    if(fields.perception === value) {
      fields.perception = null;
    }
    else {
      fields.perception = value;
    }
    this.setState({fields: fields});
  }

  handleSave(question) {
    let perceptionValue = this.state.fields.perception ? this.state.fields.perception.toUpperCase() : null;
    const params = {
      question: question.question,
      explanation: this.state.fields.explanation,
      id: question.id,
      grade_level: this.state.fields.gradeLevelID,
      perception: perceptionValue,
    }
    this.props.handleSubmit(params);
    this.closeEdit();
  }

  handleShowTopicDefinition() {
    this.setState({showTopicDefinition: !this.state.showTopicDefinition})
  }

  render(){
    const { t } = this.props; 
    const { topicCategory, index, explanation, topicName, perception, topicDefinition  } = this.state.fields;
    let editModeClass = this.state.editmode ? "questionnaire-item edit-mode" : "questionnaire-item";
    if (this.props.openPreviewMode) {
      editModeClass += " opacity-preview";
    }
    return(
      <div className={editModeClass} key={this.props.key}>
        <div className="category-weight-wrapper">
          <div className="category-name">
            {topicCategory}
          </div>
          <div className="weightage" onClick={()=>this.handleEditWeightage()}>
            {t('questionnaireQuestion.weightageLinkText')}
          </div>
        </div>
        <div className="ui grid question-content">
          <div className="question-number">
            {index+1}
          </div>
          <div className="fourteen wide column topic-name">
            {topicName}
          </div>
          {
            this.state.editmode &&
            <div className="one wide column preview-edit-save" onClick={()=>this.handleSave(this.props.question)}>Save</div>
          }
          {
            !this.state.editmode &&
            <div className="one wide column icon-icn-edit" onClick={()=>this.editpreviewQuestion(this.props.question)}></div>
          }
        </div>
        {
          this.state.editmode &&
          <div className='show-topic-definition' onClick={()=> this.handleShowTopicDefinition()}>
            Read topic definition
          </div>
        }
        {
          this.state.showTopicDefinition && this.state.editmode &&
          <div className="topic-definition-wrapper">
            {topicDefinition}
          </div>
        }
        <div className="selected-grade margin-60">
          {
            !this.state.editmode &&
            this.getSelectedGradeView()
          }
          {
            this.state.editmode &&
            this.getEditModeGradeLevel()
          }
        </div>
        <div className="question-explaination ui form margin-60">
          {
            this.state.editmode &&
            <TextArea autoHeight defaultValue={explanation ? explanation : ''} placeholder={t('questionnaireQuestion.descriptionPlaceholder')} onChange={(e) => {this.handleExplanationChange(e)}} rows={3}/>
          }
          {
            !this.state.editmode && !explanation &&
            <TextArea value={''} placeholder={t('questionnaireQuestion.descriptionPlaceholder')} disabled rows={3}/>
          }
          {
            !this.state.editmode && explanation &&
            <div className='question-explanation-container' rows={3}>
              {explanation}
            </div>
          }
        </div>
        {
          this.state.editmode &&
          <div className="importance margin-60">
            <div className={ perception === 'RISING_IN_IMPORTANCE' ? "rise cursor-pointer selected-perception" : "cursor-pointer rise"} onClick={()=>this.capturePerception('RISING_IN_IMPORTANCE')}>
              Rising in importance
              <img className="up-arrow" src={UP_ARROW_ICON}/>
            </div>
            <div className={ perception === 'BECOMING_LESS_RELEVANT' ? "less-relevent selected-perception cursor-pointer" : "less-relevent cursor-pointer"} onClick={()=>this.capturePerception('BECOMING_LESS_RELEVANT')}>
              Becoming less relevent
              <img className="up-arrow" src={DOWN_ARROW_ICON}/>
            </div>
          </div>
        }
        {
          !this.state.editmode &&
          <div className="importance margin-60">
            {
              perception === 'RISING_IN_IMPORTANCE' &&
              <div className="rise selected-perception">
                Rising in importance
                <img className="up-arrow" src={UP_ARROW_ICON}/>
              </div>
            }
            {
              perception === 'BECOMING_LESS_RELEVANT' &&
              <div className="less-relevent selected-perception">
                Becoming less relevent
                <img className="up-arrow" src={DOWN_ARROW_ICON}/>
              </div>
            }
          </div>
        }
        {
            this.state.showEditWeightageModal &&
            <EditWeightageModal 
              size={'small'}
              open={this.state.showEditWeightageModal}
              question={this.props.question}
              analysisType={this.props.analysisType}
              updateEditWeightageValues={(orgParams, postData) => this.props.updateEditWeightageValues(orgParams, postData)}
              handleModalCloseCallback={(modal)=>this.handleModalClose(modal)}
            />
          }
      </div>
    );
  }

}

export default translate(['translations'], translateOptions)(connect(null)(QuestionnaireQuestionList));
