import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../../i18n/config';

class EditWeightageComponent extends Component {
  constructor(props){
    super(props);
    this.state = {
      fields: {
        id: this.props.item.id,
        name: this.props.item.name,
        weight: this.props.item.weight
      }
    }
  }

  render(){
    const { t } = this.props;
    const {name, weight} = this.state.fields;
    return(
      <div className="ui middle aligned equal width grid edit-weightage-item" key={this.props.key}>
        <div className="two column row">
          <div className="analysis-type-name column">
            <input
              type='text'
              value={name}
              className='no-margin clear-opacity'
              disabled
            />
          </div>
          <div className="analysis-type-weight column">
            <input
              type='number'
              placeholder={t('questionnaireQuestion.editWeightPlaceholder')}
              onChange={(e)=>this.props.handleWeightChange(e,this.props.index)}
              defaultValue={weight.toString()}
              min="0"
            />
          </div>
        </div>
      </div>
    )
  };

};

export default translate(['translations'], translateOptions)(connect(null)(EditWeightageComponent));