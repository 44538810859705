import axios from 'axios';
import {
  receiveLoginResponse,
  receiveLogoutResponse,
  receiveRegisterResponse, receiveResetPasswordLinkResponse, receiveSetPasswordResponse,
  receiveRefreshTokenResponse, receiveUpdateUserNameResponse, receiveChangePasswordResponse
} from '../actions/auth-actions';
import { getAuthToken, getRefreshToken } from '../helpers/auth-helpers';
import {
  receiveAddBusinessCriterionResponse,
  receiveAddStakeHoldersResponse, receiveBusinessCriteriasResponse, receiveBusinessCriterionStrategyPeriodResponse,
  receiveDeleteBusinessCriterionResponse,
  receiveDeleteStakeHoldersResponse,
  receiveGradesResponse,
  receiveStakeHoldersResponse, receiveUpdateBusinessCriterionResponse,
  receiveUpdateBusinessCriterionStrategyPeriodResponse, receiveUpdateGradesResponse,
  receiveUpdateStakeHoldersResponse,
  recieveOragnisationDetails,
  receiveUpdateSelectedStrategyPeriodResponse,
  recieveUpdateStakeHolderConfigured, recieveUpdateBusinessCriteriaConfigured,
  receiveAnalyseDriversResponseResponse, recieveUpdateAnalysisTypeSelectedResponse,
  recieveListOfTopicsResponse, recieveValidateStakeholderAndCriteriaConfig,
  recieveQuestionnaireQuestion, recieveupdateQuestionnaireQuestionResponse,
  recieveListOfTopicCategoriesResponse, recieveQuestionnaireGradePointsResponse,
  recieveGetEditWeightageValuesResponse, recieveUpdateEditWeightageValuesResponse,
  recieveGetPreviewQuestionnaireDetailsResponse, recieveEditPreviewQuestionAnswer,
  recieveGetStrategyReportValues, recieveGetListOfRespondents,
  recieveStakeholderInterviewsResponse, recieveValueChainAndBoundariesResponse,
  recieveLastVisitedForStrategyReports, recieveStrategyReportFiles, receiveOrgVersionsResponse,
  recieveEditRespondent, receiveVerifyEmailResponse, recieveGetRespondentUsingIdRespondent
} from "../actions/app-actions";

export function apiEndPoint() {
  return `${window.location.protocol}//${window.location.hostname}/api`;
}

// Auth API Calls
export function login(dispatch, params) {
  let url = `${apiEndPoint()}/rest-auth/login/`;
  axios.post(
    url,
    params
  )
  .then(response => {
    const successResponse = response.data;
    dispatch(receiveLoginResponse(successResponse));
  })
  .catch(error => {
    if (error){
      const errorResponse = error.response.data;
      dispatch(receiveLoginResponse(errorResponse));
    }
  });
};

export function register(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/register/`;
  axios.post(
    url,
    params
  )
  .then(response => {
    const successResponse = response.data;
    dispatch(receiveRegisterResponse(successResponse));
  })
  .catch(error => {
    if (error){
      const errorResponse = error.response.data;
      dispatch(receiveRegisterResponse(errorResponse));
    }
  });
};

export function getResetPasswordLink(dispatch, params) {
  let url = `${apiEndPoint()}/rest-auth/password/reset/`;
  axios.post(
    url,
    params
  )
  .then(response => {
    const postEmailResponse = response.data;
    dispatch(receiveResetPasswordLinkResponse(postEmailResponse));
  })
  .catch(error => {
    if (error){
      const errorResponse = error.response.data;
      dispatch(receiveResetPasswordLinkResponse(errorResponse));
    }
  });
};

export function resetPassword(dispatch, params){
  let url = `${apiEndPoint()}/rest-auth/password/reset/confirm/`;
  axios.post(
    url,
    params
  )
  .then(response => {
    const successResponse = response.data;
    dispatch(receiveSetPasswordResponse(successResponse));
  })
  .catch(error => {
    if (error){
      const errorResponse = error.response.data;
      dispatch(receiveSetPasswordResponse(errorResponse));
    }
  });
};

export function refreshToken() {
  let url = `${apiEndPoint()}/rest-auth/refresh-token/`;
  let refresh_token  = getRefreshToken();
  refresh_token = refresh_token.substring(7); // Removing the prefix "Bearer "
  const params = {
    refresh_token: refresh_token
  };
  axios.post(
    url,
    params, {
      'Content-Type': 'application/json',
    }
  )
  .then(response => {
    const successResponse = response.data;
    receiveRefreshTokenResponse(successResponse);
  })
  .catch(error => {
    if (error) {
      const errorResponse = error.response.data;
      receiveRefreshTokenResponse(errorResponse);
    }
  });
};

export function updateUserName(dispatch, params) {
  let url = `${apiEndPoint()}/users/`;
  axios.patch(
    url,
    params, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      }
    }
  )
  .then(response => {
    const successResponse = response.data;
    dispatch(receiveUpdateUserNameResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      const errorResponse = error.response.data;
      dispatch(receiveUpdateUserNameResponse(errorResponse));
    }
  });
}

export function changePassword(dispatch, params) {
  let url = `${apiEndPoint()}/rest-auth/password/change/`;
  axios.post(
    url,
    params, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      }
    }
  )
  .then(response => {
    const successResponse = response.data;
    dispatch(receiveChangePasswordResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      const errorResponse = error.response.data;
      dispatch(receiveChangePasswordResponse(errorResponse));
    }
  });
}

export function verifyEmail(dispatch, params) {
  let url = `${apiEndPoint()}/users/email/verify/`;
  axios.post(
    url,
    params, {
      headers: {
        'Content-Type': 'application/json',
      }
    }
  )
  .then(response => {
    const successResponse = response.data;
    dispatch(receiveVerifyEmailResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      const errorResponse = error.response.data;
      dispatch(receiveVerifyEmailResponse(errorResponse));
    }
  });
}

export function logout(dispatch) {
  let url = `${apiEndPoint()}/rest-auth/logout/`;
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': getAuthToken()
    };
  const data = {'refresh_token': getRefreshToken()}
  axios.post(
      url,
      data,
      {headers})
  .then(response => {
    const logoutResponse = response.data;
    dispatch(receiveLogoutResponse(logoutResponse));
  })
  .catch(error => {
    if (error.response.status === 401) {
      logout(dispatch);
    }
  });
};

export function getOrgVersions(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/versions/`;
  axios.get(
    url,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(receiveOrgVersionsResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(receiveOrgVersionsResponse(errorResponse));
      }
    }
  });
}

// Stakeholders
export function getStakeHolders(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/stakeholders/`;
  axios.get(
    url,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(receiveStakeHoldersResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(receiveStakeHoldersResponse(errorResponse));
      }
    }
  });
};

export function addStakeHolder(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/stakeholders/`;
  axios.post(
    url,
    params, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(receiveAddStakeHoldersResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(receiveAddStakeHoldersResponse(errorResponse));
      }
    }
  });
};

export function deleteStakeHolder(dispatch, params){
  let url = `${apiEndPoint()}/orgs/${params.org_id}/stakeholders/${params.stakeholder_id}/`;
  axios.delete(
    url,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    let successResponse;
    if (response.status === 204){
      successResponse = { data: {message: `Stakeholder with id ${params.stakeholder_id} has been removed.`}, success: true };
    } else {
      successResponse = response.data;
    }
    dispatch(receiveDeleteStakeHoldersResponse(successResponse, params.stakeholder_id));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response.data;
        dispatch(receiveDeleteStakeHoldersResponse(errorResponse, params.stakeholder_id));
      }
    }
  });
};

export function updateStakeHolder(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/stakeholders/`;
  axios.put(
    url,
    params, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(receiveUpdateStakeHoldersResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(receiveUpdateStakeHoldersResponse(errorResponse));
      }
    }
  });
};

// Grades
export function getGrades(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/grades/`;
  axios.get(
    url,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(receiveGradesResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(receiveGradesResponse(errorResponse));
      }
    }
  });
};

export function updateGrades(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/grades/`;
  axios.put(
    url,
    params, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(receiveUpdateGradesResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(receiveUpdateGradesResponse(errorResponse));
      }
    }
  });
};

// Business Criterion
export function getBusinessCriterias(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/business-criterion/`;
  axios.get(
    url,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(receiveBusinessCriteriasResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(receiveBusinessCriteriasResponse(errorResponse));
      }
    }
  });
};

export function addBusinessCriterion(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/business-criterion/`;
  axios.post(
    url,
    params, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(receiveAddBusinessCriterionResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response.data;
        dispatch(receiveAddBusinessCriterionResponse(errorResponse));
      }
    }
  });
};

export function deleteBusinessCriterion(dispatch, params){
  let url = `${apiEndPoint()}/orgs/${params.org_id}/business-criterion/${params.business_criteria_id}/`;
  axios.delete(
    url,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    let successResponse;
    if (response.status === 204){
      successResponse = { data: {message: `Business criterion with id ${params.business_criteria_id} has been removed.`}, success: true };
    } else {
      successResponse = response.data;
    }
    dispatch(receiveDeleteBusinessCriterionResponse(successResponse, params.business_criteria_id));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response.data;
        dispatch(receiveDeleteBusinessCriterionResponse(errorResponse, params.business_criteria_id));
      }
    }
  });
};

export function updateBusinessCriterion(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/business-criterion/`;
  axios.put(
    url,
    params, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(receiveUpdateBusinessCriterionResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(receiveUpdateBusinessCriterionResponse(errorResponse));
      }
    }
  });
};

//Strategy Period
export function getBusinessCriterionStrategyPeriod(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/business-criterion/strategy-period-options/`;
  axios.get(
    url,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(receiveBusinessCriterionStrategyPeriodResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(receiveBusinessCriterionStrategyPeriodResponse(errorResponse));
      }
    }
  });
};

export function updateBusinessCriterionStrategyPeriod(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/business-criterion/strategy-period-options/`;
  axios.put(
    url,
    params, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(receiveUpdateBusinessCriterionStrategyPeriodResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(receiveUpdateBusinessCriterionStrategyPeriodResponse(errorResponse));
      }
    }
  });
};

export function getOrganisationDetails(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.id}/`;
  axios.get(
    url,{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(recieveOragnisationDetails(successResponse));
  })
  .catch(error => {
    if(error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(recieveOragnisationDetails(errorResponse));
      }   
    }
  });
};

export function getSelectedStrategyPeriod(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/business-criterion/strategy-period/`;
  axios.get(
    url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(receiveUpdateSelectedStrategyPeriodResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(receiveUpdateSelectedStrategyPeriodResponse(errorResponse));
      }
    }
  });
}

export function UpdateSelectedStrategyPeriod(dispatch, params, org_id) {
  let url = `${apiEndPoint()}/orgs/${org_id}/business-criterion/strategy-period/`;
  axios.get(
    url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    if(response.data && response.data.data && response.data.data.id) {
      params.org_id = org_id;
      params.strategy_period_id = response.data.data.id;
      EditSelectedStrategyPeriod(dispatch, params);
    }
    else {
      addSelectedStrategyPeriod(dispatch, params, org_id);
    }
  })
}

export function addSelectedStrategyPeriod(dispatch, params, org_id) {
  let url = `${apiEndPoint()}/orgs/${org_id}/business-criterion/strategy-period/`;
  axios.post(
    url,
    params, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    const getPostData = {
      org_id: org_id
    }
    getSelectedStrategyPeriod(dispatch, getPostData);
    dispatch(receiveUpdateSelectedStrategyPeriodResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(receiveUpdateSelectedStrategyPeriodResponse(errorResponse));
      }
    }
  });
}

export function EditSelectedStrategyPeriod(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/business-criterion/strategy-period/${params.strategy_period_id}/`;
  let postData = {
    "year": params.year,
  }
  axios.put(
    url,
    postData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    getSelectedStrategyPeriod(dispatch, params);
    dispatch(receiveUpdateSelectedStrategyPeriodResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(receiveUpdateSelectedStrategyPeriodResponse(errorResponse));
      }
    }
  });
}

export function UpdateStakeHolderConfigured(dispatch, params, org_id) {
  let url = `${apiEndPoint()}/orgs/${org_id}/config/`;
  axios.patch(
    url,
    params, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(recieveUpdateStakeHolderConfigured(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(recieveUpdateStakeHolderConfigured(errorResponse));
      }
    }
  });
}

export function UpdateBusinessCriteriaConfigured(dispatch, params, org_id) {
  let url = `${apiEndPoint()}/orgs/${org_id}/config/`;
  axios.patch(
    url,
    params, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(recieveUpdateBusinessCriteriaConfigured(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(recieveUpdateBusinessCriteriaConfigured(errorResponse));
      }
    }
  });
}

export function getAnalyseDrivers(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/analyze-drivers/`;
  axios.get(
    url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(receiveAnalyseDriversResponseResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(receiveAnalyseDriversResponseResponse(errorResponse));
      }
    }
  });
}

export function getStakeholderInterviews(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/stakeholder-interviews/`;
  axios.get(
    url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(recieveStakeholderInterviewsResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(recieveStakeholderInterviewsResponse(errorResponse));
      }
    }
  });
}

export function getValueChainAndBoundaries(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/value-chain-and-boundaries/`;
  axios.get(
    url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(recieveValueChainAndBoundariesResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(recieveValueChainAndBoundariesResponse(errorResponse));
      }
    }
  });
}

export function getStrategyReportFiles(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/strategy-and-reports/`;
  axios.get(
    url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(recieveStrategyReportFiles(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(recieveStrategyReportFiles(errorResponse));
      }
    }
  });
}

export function UpdateAnalysisTypeSelected(dispatch, params, type, org_id) {
  const analysisType = type.type === 'business-criteria'? 'business_criteria' : 'stakeholder';
  let url = `${apiEndPoint()}/orgs/${org_id}/respondents/?type=${analysisType}`;
  axios.post(
    url,
    params, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(recieveUpdateAnalysisTypeSelectedResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        let errorResponse = {};
        if(!error.response.data.error) {
          errorResponse['data'] = {
            success: false,
            error: {
              message: 'Internal server error. Please try again'
            },
            data: null
          }
          errorResponse['success'] = false;
        } else {
          errorResponse = error.response;
        }
        dispatch(recieveUpdateAnalysisTypeSelectedResponse(errorResponse));
      }
    }
  });
}

export function getListOfTopics(dispatch, org_id) {
  let url = `${apiEndPoint()}/orgs/${org_id}/topics/`;
  axios.get(
    url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(recieveListOfTopicsResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(recieveListOfTopicsResponse(errorResponse));
      }    
    }
  });
}

export function getValidateStakeholderAndCriteriaConfig(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/config/`;
  axios.get(
    url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(recieveValidateStakeholderAndCriteriaConfig(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(recieveValidateStakeholderAndCriteriaConfig(errorResponse));
      }
    }
  });
}

export function getQuestionnaireQuestion(dispatch, params, type, categoryId) {
  const analysisType = type === 'business-criteria'? 'business_criteria' : 'stakeholder';
  let url = `${apiEndPoint()}/orgs/${params.org_id}/respondents/${params.respondent_id}/questions/?type=${analysisType}`;
  if (categoryId) {
    url += `&org_topic_category_version_id=${categoryId}`;
  }
  axios.get(
    url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(recieveQuestionnaireQuestion(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(recieveQuestionnaireQuestion(errorResponse));
      }
    }
  });
}

export function updateQuestionnaireQuestion(dispatch, params) {
  const analysisType = params.type === 'business-criteria'? 'business_criteria' : 'stakeholder';
  let url = `${apiEndPoint()}/orgs/${params.org_id}/respondents/${params.respondent_id}/questions/?type=${analysisType}`;
  let postData = {
    question: params.question,
    org_topic_version: params.org_topic_version,
    grade_level: params.grade,
    explanation: params.explanation,
    perception: params.perception
  }
  axios.post(
    url,
    postData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    getTopicCategories(dispatch, params, params.type);
    getPreviewQuestionnaireDetails(dispatch, params);
    const successResponse = response.data;
    dispatch(recieveupdateQuestionnaireQuestionResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response && error.response.status === 401) {
        logout(dispatch);
      }
      else {
        let errorResponse = error.response ? error.response.data : null;
        if(!errorResponse) {
          errorResponse = {
            error: true,
            success: false
          }
        }
        dispatch(recieveupdateQuestionnaireQuestionResponse(errorResponse));
      }
    }
  });
}

export function getTopicCategories(dispatch, params, type) {
  const analysisType = type === 'business-criteria'? 'business_criteria' : 'stakeholder';
  let url = `${apiEndPoint()}/orgs/${params.org_id}/respondents/${params.respondent_id}/topic-categories/?type=${analysisType}`;
  axios.get(
    url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(recieveListOfTopicCategoriesResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(recieveListOfTopicCategoriesResponse(errorResponse));
      }
    }
  });
}

export function getQuestionnaireGradePoints(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/grades/`;
  axios.get(
    url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(recieveQuestionnaireGradePointsResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(recieveQuestionnaireGradePointsResponse(errorResponse));
      }
    }
  });
}

export function getEditWeightageValues(dispatch, params, type) {
  let url;
  if(type==='stakeholder') {
    url = `${apiEndPoint()}/orgs/${params.org_id}/topics/${params.org_topic_version_id}/stakeholder-weight/`;
  }
  else if(type==='business-criteria') {
    url = `${apiEndPoint()}/orgs/${params.org_id}/topics/${params.org_topic_version_id}/business-criteria-weight/`;
  }
  axios.get(
    url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(recieveGetEditWeightageValuesResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(recieveGetEditWeightageValuesResponse(errorResponse));
      }
    }
  });
}

export function updateEditWeightageValues(dispatch, orgParams, postData) {
  let url;
  if(orgParams.type==='stakeholder') {
    url = `${apiEndPoint()}/orgs/${orgParams.org_id}/topics/${orgParams.org_topic_id}/stakeholder-weight/`;
  }
  else if(orgParams.type==='business-criteria') {
    url = `${apiEndPoint()}/orgs/${orgParams.org_id}/topics/${orgParams.org_topic_id}/business-criteria-weight/`;
  }
  axios.patch(
    url,
    postData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(recieveUpdateEditWeightageValuesResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(recieveUpdateEditWeightageValuesResponse(errorResponse));
      }
    }
  });
}

export function getPreviewQuestionnaireDetails(dispatch, params) {
  const analysisType = params.type === 'business-criteria'? 'business_criteria' : 'stakeholder';
  let url = `${apiEndPoint()}/orgs/${params.org_id}/respondents/${params.respondent_id}/questions-preview/?type=${analysisType}`;
  axios.get(
    url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(recieveGetPreviewQuestionnaireDetailsResponse(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(recieveGetPreviewQuestionnaireDetailsResponse(errorResponse));
      }
    }
  });
}

export function updatEditPreviewQuestionAnswer(dispatch, params) {
  const analysisType = params.type === 'business-criteria'? 'business_criteria' : 'stakeholder';
  let url = `${apiEndPoint()}/orgs/${params.org_id}/respondents/${params.respondent_id}/questions-preview/?type=${analysisType}`;
  let postData = [{
    id: params.id,
    question: params.question,
    explanation: params.explanation,
    perception: params.perception,
    grade_level: params.grade_level
  }];
  axios.put(
    url,
    postData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(recieveEditPreviewQuestionAnswer(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(recieveEditPreviewQuestionAnswer(errorResponse));
      }
    }
  });
}

export function getStrategyReportValues(dispatch, params) {
  let url;
  if(params.version) {
    url = `${apiEndPoint()}/orgs/${params.org_id}/reports/?version=${params.version}`;
  }
  else {
    url = `${apiEndPoint()}/orgs/${params.org_id}/reports/`;
  }
  axios.get(
    url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(recieveGetStrategyReportValues(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(recieveGetStrategyReportValues(errorResponse));
      }
    }
  });
}

export function updateLastVisitedForStrategyReports(dispatch, params) {
  let url;
  if(params.version) {
    url = `${apiEndPoint()}/orgs/${params.org_id}/reports/viewed/?version=${params.version}`;
  } else {
    url = `${apiEndPoint()}/orgs/${params.org_id}/reports/viewed/`;
  }
  let postData;
  axios.post(
    url,
    postData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(recieveLastVisitedForStrategyReports(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(recieveLastVisitedForStrategyReports(errorResponse));
      }
    }
  });
}

export function getListOfRespondents(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/respondents/?filter=true`;
  axios.get(
    url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(recieveGetListOfRespondents(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(recieveGetListOfRespondents(errorResponse));
      }
    }
  });
}

export function editRespondent(dispatch, params, org_id) {
  let url = `${apiEndPoint()}/orgs/${org_id}/respondents/${params.respondent_id}/`;
  let postData = {
    name: params.name,
    description: params.description
  }
  axios.patch(
    url, postData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    //getListOfRespondents(dispatch, params);
    dispatch(recieveEditRespondent(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response && error.response.status === 401) {
        logout(dispatch);
      }
      else {
        let errorResponse = {};
        if(!error.response.data.error) {
          errorResponse['data'] = {
            success: false,
            error: {
              message: 'Internal server error. Please try again'
            },
            data: null
          }
          errorResponse['success'] = false;
        } else {
          errorResponse = error.response;
        }
        dispatch(recieveEditRespondent(errorResponse));
      }
    }
  });
}

export function getRespondentUsingId(dispatch, params) {
  let url = `${apiEndPoint()}/orgs/${params.org_id}/respondents/${params.respondent_id}/`;
  axios.get(
    url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getAuthToken()
      },
    })
  .then(response => {
    const successResponse = response.data;
    dispatch(recieveGetRespondentUsingIdRespondent(successResponse));
  })
  .catch(error => {
    if (error) {
      if (error.response.status === 401) {
        logout(dispatch);
      }
      else {
        const errorResponse = error.response;
        dispatch(recieveGetRespondentUsingIdRespondent(errorResponse));
      }
    }
  });
}