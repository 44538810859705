import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { Container, Breadcrumb } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { isEqual } from 'lodash';

import BusinessAbilityContainer from './business-ability-container/business-ability-container';
import ReviewBusinessCriteriasConfigurationsView from './business-ability-container/review-business-criteria-configurations/review-business-criterias-configurations';

import { translateOptions } from '../../i18n/config';
import { scrollToTop } from '../../utils/misc-utils';
import {
  requestBusinessCriterias,
  requestUpdateBusinessCriterionStrategyPeriod,
  requestAddBusinessCriterion,
  requestDeleteBusinessCriterion,
  requestUpdateBusinessCriterion,
  requestClearBusinessCriterionViewStates,
  requestBusinessCriterionStrategyPeriod,
  requestUpdateSelectedStrategyPeriod,
  requestUpdateBusinessCriteriaConfigured,
  requestValidateStakeholderAndCriteriaConfig,
  clearStakeholderAndCriteriaConfig,
  requestGetSelectedBusinessCriterionStrategyPeriod
} from '../../actions/app-actions';
import {getActiveOrg} from "../../helpers/auth-helpers";
import AlertModalView from "../common/alert-modal";

class BusinessAbilityView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);
    const { userSession } = this.props;
    this.state={
      org_id: getActiveOrg(userSession),
      showLoader: false
    };
  };

  /***************************
   * METHODS
   ***************************/
  init(){
    const { org_id } = this.state;
    const params = {
      org_id: org_id
    }
    this.getrequestValidateStakeholderAndCriteriaConfig();
    this.props.clearStakeholderAndCriteriaConfig();
    this.props.requestBusinessCriterias(params);
  }

  getStrategyPeriod = ()=> {
    const { org_id } = this.state;
    const params = {
      org_id: org_id
    }
    this.props.requestBusinessCriterionStrategyPeriod(params);
    this.getSelectedStrategyPeriod()
  }

  addBusinessCriterion = (params)=> {
    const { org_id } = this.state;
    params['org_id'] = org_id;
    this.props.requestAddBusinessCriterion(params);
  }

  deleteBusinessCriterion = (params)=> {
    const { org_id } = this.state;
    params['org_id'] = org_id;
    this.props.requestDeleteBusinessCriterion(params);
  }

  updateBusinessCriterion = (params)=> {
    const { org_id } = this.state;
    params['org_id'] = org_id;
    this.props.requestUpdateBusinessCriterion(params);
  }

  getSelectedStrategyPeriod = ()=> {
    const { org_id } = this.state;
    let params = {
      org_id: org_id
    }
    this.props.requestGetSelectedBusinessCriterionStrategyPeriod(params);
  }

  updateStrategyPeriod = (params)=> {
    const { org_id } = this.state;
    params['org_id'] = org_id;
    this.props.requestUpdateBusinessCriterionStrategyPeriod(params);
  }

  updateSelectedStrategyPeriod = (params)=> {
    const { org_id } = this.state;
    this.props.requestUpdateSelectedStrategyPeriod(params, org_id);
  }

  updateBusinessCriteriaConfigured = (params)=> {
    const { org_id } = this.state;
    this.props.requestUpdateBusinessCriteriaConfigured(params, org_id);
  }

  clearStates = () => {
    this.props.requestClearBusinessCriterionViewStates();
  }

  getrequestValidateStakeholderAndCriteriaConfig = () => {
    let params = {};
    const { org_id } = this.state;
    params['org_id'] = org_id;
    this.props.requestValidateStakeholderAndCriteriaConfig(params);
  }

  showOrHideReviewModal() {
    this.setState({reviewModalOpened: true});
  }

  showOrHideModel() {
    this.setState({showLoader: true});
  }

  handleReviewModalClose() {
    this.setState({reviewModalOpened: false});
  }

  setAnalysisTypeValuesConfig(config) {
    this.setState({analysisTypesConfigured: config})
  }

  updateStrategyPperiodResponse(selectedStrategyPeriodForBuisnessCriteria) {
    this.setState({selectedStrategyPeriodForBuisnessCriteria: selectedStrategyPeriodForBuisnessCriteria});
  }

  showLoaderToggle() {
    const {showLoader, analysisTypesConfigured} = this.state;
    if (analysisTypesConfigured) {
      if(analysisTypesConfigured.analysisTypesConfigured.is_business_criteria_configured &&
      !analysisTypesConfigured.analysisTypesConfigured.is_stakeholder_configured) {
        return false;
      } else if (analysisTypesConfigured.analysisTypesConfigured.is_business_criteria_configured &&
        analysisTypesConfigured.analysisTypesConfigured.is_stakeholder_configured) {
        return false;
      } else if(showLoader) {
        return true;
      }
    }
    return false; 
  }

  handlePrint() {
    window.print();
  }
  /***************************
   * VIEWS
   ***************************/
  getHeroBannerContentView(){
    const { t } = this.props;
    return(
      <Container className="relative-position">
        <div className="banner-content-wrapper">
          <div className="banner-heading">
            <p className="small-heading">{t('businessAbilityPage.bannerSmallHeading')}</p>
            <p className="big-heading">{t('businessAbilityPage.bannerBigHeading')}</p>
          </div>
          <div className="banner-content-para">{t('businessAbilityPage.bannerContent')}</div>
        </div>
      </Container>
    )
  };

  getBreadcrumbView(){
    const { t } = this.props;
    return(
      <Breadcrumb size='large'>
        <Breadcrumb.Section>
          <Link to="/analysis-types">{t('businessAbilityPage.methods')}</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon='right chevron' />
        <Breadcrumb.Section active>{t('businessAbilityPage.InfluenceOnTheBusiness')}</Breadcrumb.Section>
      </Breadcrumb>
    )
  }

  alertModalView(openvalue) {
    return (<AlertModalView 
      size={'small'}
      open={openvalue}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      onClose={()=> this.handleModalClose()}
      message={'Please configure stakeholders before  proceeding'}
      redirectTo={'/stakeholder-insights'}
      continueMessage={'Proceed'}/>)
  }

  continuePageView() {
    return (<AlertModalView 
      size={'small'}
      open={true}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      onClose={()=> this.handleModalClose()}
      message={'Configurations complete. Begin your questionnaire'}
      redirectTo={'/stakeholder/questionnaire'}
      continueMessage={'Continue to questionnaire'}/>)
  }

  getReviewBusinessCriteriaView() {
    return (
      <ReviewBusinessCriteriasConfigurationsView
        size={'small'}
        open={true}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={()=> this.handleReviewModalClose()}
        selectedStrategyPeriodForBuisnessCriteria={this.props.selectedStrategyPeriodForBuisnessCriteria}
        businessCriteriaResponse={this.props.businessCriteriaResponse}
        updateBusinessCriteriaConfigured={(params)=> this.updateBusinessCriteriaConfigured(params)}
        showOrHideModel= {()=> this.showOrHideModel()}
        getSelectedStrategyPeriod= {() => this.getSelectedStrategyPeriod()}
      />
    )
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount() {
    scrollToTop();

    this.init()
  }

  componentWillReceiveProps(newProps){
    if (!isEqual(newProps.updateBusinessCriterionResponse, this.props.updateBusinessCriterionResponse))
      this.init();
    
    if (!isEqual(newProps.selectedStrategyPeriodForBuisnessCriteria, this.props.selectedStrategyPeriodForBuisnessCriteria))
      this.updateStrategyPperiodResponse(newProps.selectedStrategyPeriodForBuisnessCriteria);
    
    if (newProps.analysisTypesConfigured)
    this.setAnalysisTypeValuesConfig(newProps.analysisTypesConfigured);
  }

  render(){
    const { businessCriteriaResponse, addBusinessCriteriaResponse, businessCriterionStrategyPeriodResponse } = this.props;
    const { analysisTypesConfigured } = this.state;
    let showLoader = this.showLoaderToggle();
    return(
      <div className="business-ability-view">
        <div className={ (!showLoader) ? "ui inverted dimmer loading-wrapper" : "ui active inverted dimmer loading-wrapper"}>
          <div className="ui large text loader">Loading</div>
        </div>
        <div className="hero-banner-wrapper">
          <div className="hero-banner">
            { this.getHeroBannerContentView() }
          </div>
        </div>
        <div className="breadcrumb-wrapper">
          <Container>
            {this.getBreadcrumbView()}
            <div className="print-link" onClick={()=>this.handlePrint()}>Print</div>
          </Container>
        </div>
        <Container className="main-content-wrapper">
          <BusinessAbilityContainer
            businessCriteriaResponse={businessCriteriaResponse}
            businessCriterionStrategyPeriodResponse={businessCriterionStrategyPeriodResponse}
            getStrategyPeriodCallback={()=> this.getStrategyPeriod()}
            addBusinessCriteriaResponse={addBusinessCriteriaResponse}
            addBusinessCriterionCallback={(params)=> this.addBusinessCriterion(params)}
            deleteBusinessCriterionCallback={(params)=> this.deleteBusinessCriterion(params)}
            updateBusinessCriterionCallback={(params)=> this.updateBusinessCriterion(params)}
            updateStrategyPeriodCallback={(params)=> this.updateStrategyPeriod(params)}
            updateSelectedStrategyPeriodCallback={(params) => this.updateSelectedStrategyPeriod(params)}
            updateBusinessCriteriaConfigured={(params)=> this.updateBusinessCriteriaConfigured(params)}
            clearStatesCallback={()=> this.clearStates()}
            showOrHideModel= {()=> this.showOrHideModel()}
            showOrHideReviewModal= {()=> this.showOrHideReviewModal()}
            selectedStrategyPeriodForBuisnessCriteria= {this.state.selectedStrategyPeriodForBuisnessCriteria}
          />
        </Container>
          {
            analysisTypesConfigured &&
            !analysisTypesConfigured.analysisTypesConfigured.is_stakeholder_configured &&
            analysisTypesConfigured.analysisTypesConfigured.is_business_criteria_configured &&
            this.alertModalView(true)
          }
          {
            analysisTypesConfigured && 
            analysisTypesConfigured.analysisTypesConfigured.is_stakeholder_configured &&
            analysisTypesConfigured.analysisTypesConfigured.is_business_criteria_configured &&
            this.continuePageView()
          }
          {
            this.state.reviewModalOpened && this.state.selectedStrategyPeriodForBuisnessCriteria &&
            this.getReviewBusinessCriteriaView()
          }
      </div>
    );
  };

}

function mapStateToProps(state) {
  return {
    userSession: state.auth.userSession,
    businessCriteriaResponse: state.app.businessCriteriaResponse,
    addBusinessCriteriaResponse: state.app.addBusinessCriteriaResponse,
    deleteBusinessCriterionResponse: state.app.deleteBusinessCriterionResponse,
    updateBusinessCriterionResponse: state.app.updateBusinessCriterionResponse,
    businessCriterionStrategyPeriodResponse: state.app.businessCriterionStrategyPeriodResponse,
    updateBusinessCriterionStrategyPeriodResponse: state.app.updateBusinessCriterionStrategyPeriodResponse,
    analysisTypesConfigured: state.app.analysisTypesConfigured,
    selectedStrategyPeriodForBuisnessCriteria: state.app.selectedStrategyPeriodForBuisnessCriteria
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    requestBusinessCriterias: requestBusinessCriterias,
    requestAddBusinessCriterion: requestAddBusinessCriterion,
    requestDeleteBusinessCriterion: requestDeleteBusinessCriterion,
    requestUpdateBusinessCriterion: requestUpdateBusinessCriterion,
    requestClearBusinessCriterionViewStates: requestClearBusinessCriterionViewStates,
    requestBusinessCriterionStrategyPeriod: requestBusinessCriterionStrategyPeriod,
    requestUpdateBusinessCriterionStrategyPeriod: requestUpdateBusinessCriterionStrategyPeriod,
    requestUpdateSelectedStrategyPeriod: requestUpdateSelectedStrategyPeriod,
    requestUpdateBusinessCriteriaConfigured: requestUpdateBusinessCriteriaConfigured,
    requestValidateStakeholderAndCriteriaConfig: requestValidateStakeholderAndCriteriaConfig,
    clearStakeholderAndCriteriaConfig: clearStakeholderAndCriteriaConfig,
    requestGetSelectedBusinessCriterionStrategyPeriod: requestGetSelectedBusinessCriterionStrategyPeriod
  },dispatch)
}

export default translate(['translations'], translateOptions)(connect(mapStateToProps, mapDispatchToProps)(BusinessAbilityView));