import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react'

class AlertModalView extends Component {
  constructor(props){
    super(props);
    this.state = {
      modalOpened: this.props.open
    }
  }
  handleModalClose() {
    if(this.state.modalOpened) {
      this.setState({modalOpened: false});
    }
  }

  render(){
    return(
      <div>
        <Modal className={"alert-modal-view " + this.props.classnames} size={this.props.size} closeOnEscape={this.props.closeOnEscape} closeOnDimmerClick={this.props.closeOnDimmerClick} open={this.state.modalOpened} onClose={()=>this.handleModalClose()}>
          <Modal.Header>
            {
              this.props.blockProceed &&
              <div className="icon-alert-icon"></div>
            }
            Alert
          </Modal.Header>
          <Modal.Content>
            <p>{this.props.message}</p>
            {
              this.props.additionalMessage &&
              <p>{this.props.additionalMessage}</p>
            }
          </Modal.Content>
            <Modal.Actions>
              <a href={this.props.redirectTo}>
                <Button color="orange" icon='checkmark' labelPosition='left' content={this.props.continueMessage} />
              </a>
            </Modal.Actions>
        </Modal>
      </div>
    )
  };

};

export default AlertModalView;