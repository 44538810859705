import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../i18n/config';
import SimpleScatterChart from './graph-component/graph-component';
import { Container, Dropdown } from 'semantic-ui-react';
import { getCompanyName, getActiveOrg } from "../../helpers/auth-helpers";
import { requestGetStrategyReportValues, requestOrgVersions, requestUpdateLastVisitedForReports } from '../../actions/app-actions';
import StrategyAndReportLegend from './chart-legend/chart-legend';
import { SCATTER_CHART, AXIS_EXPLANATIONS } from '../../constants/scatter-chart';
import { replaceOrgDetails } from '../../utils/string-utils';
import { RIGHT_ARROW_ICON } from '../../constants/images';
import { scrollToTop } from '../../utils/misc-utils';
import Moment from 'react-moment';
import { isEqual } from 'lodash';

class StrategyAndReportView extends Component {
  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.tickLabelOptions = [
      {
        text: "0-100",
        key: "number",
        value: "number"
      },
      {
        text: "Medium - Very High",
        key: "text",
        value: "text"
      }
    ];

    this.state = {
      org_id: getActiveOrg(userSession),
      impactOnPeopleAndSociety: false,
      impactOnValueCreation: true,
      companyName: getCompanyName(userSession),
      selectedTickLabel: this.tickLabelOptions[0].value
    }

    this.handleVersionChange = this.handleVersionChange.bind(this)
    this.handleTickLabelChange = this.handleTickLabelChange.bind(this)
  }

  /***************************
   * MEDTHODS
   ***************************/
  getOrgVersions() {
    const { org_id } = this.state;
    const params = {
      org_id: org_id
    }
    this.props.requestOrgVersions(params);
  }

  callGetStrategyReportValues(version) {
    const { org_id, selectedVersion } = this.state;
    const params = {
      org_id: org_id,
      version: version || selectedVersion
    }
    if(version) {
      this.setState({selectedVersion: version});
    }
    this.props.requestGetStrategyReportValues(params);
  }

  setStrategyReportValues(strategyReportValues) {
    this.setState({strategyReportValues: strategyReportValues});
  }

  toggleReports() {
    const {impactOnPeopleAndSociety, impactOnValueCreation} = this.state;
    this.setState({
      impactOnPeopleAndSociety: !impactOnPeopleAndSociety,
      impactOnValueCreation: !impactOnValueCreation
    })
  }

  handleVersionChange(e, { value }) {
    this.setState({selectedVersion: value});
    this.callGetStrategyReportValues(value)
  }

  handleTickLabelChange(e, { value }) {
    this.setState({
      selectedTickLabel: value
    });
  }

  getOptionsDataForVersions() {
    const {organisationVersions} = this.props.organisationVersions;

    let versionOptionsData = [];
    for (let versionIndex=0; versionIndex<organisationVersions.length; versionIndex++){
      const version = organisationVersions[versionIndex].version;
      // Extracting version number integer from backend version
      // Example: v1.0 => 1.0
      const versionNumber = version.split('v')[1]
      versionOptionsData.push({
        key: version,
        text: `Version ${versionNumber}`,
        value: version,
      })
    }
    return versionOptionsData;
  }

  handlePrint() {
    let params = {
      org_id: this.state.org_id
    }
    if (this.props.versioned) {
      params.version = this.state.selectedVersion
    }
    this.props.requestUpdateLastVisitedForReports(params);
    window.print();
  }

  /***************************
   * VIEWS
   ***************************/
  getImpactOnValueCreationView() {
    const { strategyReportValues } = this.props;
    return(
      <SimpleScatterChart
        selectedTickLabel={this.state.selectedTickLabel}
        strategyReportValues= {strategyReportValues.strategyReportValues}
        xAxisKey={SCATTER_CHART.INFLUENCE_ON_BUSINESS_KEY}
        yAxisKey={SCATTER_CHART.STAKEHOLDER_PERCEPTIONS_KEY}
        xAxizLabel={SCATTER_CHART.INFLUENCE_ON_BUSINESS_LABEL}
        yAxizLabel={SCATTER_CHART.STAKEHOLDER_PERCEPTIONS_LABEL}
        getChartExplanationView= {()=> this.getChartExplanationView()}/>
    );
  }

  getImpactOnPeopleAndSociety() {
    const { strategyReportValues } = this.props;
    return(
      <SimpleScatterChart
        selectedTickLabel={this.state.selectedTickLabel}
        strategyReportValues= {strategyReportValues.strategyReportValues}
        xAxisKey={SCATTER_CHART.IMPACT_ON_PEOPLE_KEY}
        yAxisKey={SCATTER_CHART.STAKEHOLDER_PERCEPTIONS_KEY}
        xAxizLabel={SCATTER_CHART.IMPACT_ON_PEOPLE_LABEL}
        yAxizLabel={SCATTER_CHART.STAKEHOLDER_PERCEPTIONS_LABEL}
        getChartExplanationView= {()=> this.getChartExplanationView()}/>
    );
  }

  getLegendViewFormImpactOnBusiness() {
    const { strategyReportValues } = this.props;
    return(
      <StrategyAndReportLegend
        strategyReportValues= {strategyReportValues.strategyReportValues}
        impactOnValueCreation= {this.state.impactOnValueCreation}
        impactOnPeopleAndSociety = {this.state.impactOnPeopleAndSociety}
      />
    )
  }

  getLegendViewFormImpactOnPeopleAndPlanet() {
    const { strategyReportValues } = this.props;
    return(
      <StrategyAndReportLegend
        strategyReportValues= {strategyReportValues.strategyReportValues}
        impactOnValueCreation= {this.state.impactOnValueCreation}
        impactOnPeopleAndSociety = {this.state.impactOnPeopleAndSociety}
      />
    )
  }

  getChartExplanationView() {
    let stakeholderPerceptionsInfo = AXIS_EXPLANATIONS.STAKEHOLDER_PERCEPTIONS;
    let influenceOnBusinessInfo = AXIS_EXPLANATIONS.INFLUENCE_ON_BUSINESS;

    stakeholderPerceptionsInfo = {
      __html: replaceOrgDetails(this.state.companyName, stakeholderPerceptionsInfo),
    }

    if(this.state.impactOnValueCreation) {
      influenceOnBusinessInfo = {
        __html: replaceOrgDetails(this.state.companyName, influenceOnBusinessInfo),
      }  
      return (
        <div className="graph-explanation-wrapper">
          <div className="stakeholder-perceptions-info" dangerouslySetInnerHTML={stakeholderPerceptionsInfo}>
          </div>
          <div className="influence-on-business-info" dangerouslySetInnerHTML={influenceOnBusinessInfo}>
          </div>
        </div>
      )
    } else if(this.state.impactOnPeopleAndSociety) {
      return (
        <div className="graph-explanation-wrapper">
          <div className="stakeholder-perceptions-info" dangerouslySetInnerHTML={stakeholderPerceptionsInfo}>
          </div>
        </div>
      )
    }
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount() {
    scrollToTop();

    if (this.props.versioned) {
      this.getOrgVersions()
    }

    if(this.props.strategyReportValues) {
      this.setStrategyReportValues(this.props.strategyReportValues);
    }

    if (this.props.organisationVersions && this.props.versioned){
      const { organisationVersions } = this.props.organisationVersions
      const latestVersion = organisationVersions[0].version
      this.callGetStrategyReportValues(latestVersion)
    } else if(!this.props.versioned) {
      this.callGetStrategyReportValues()
    }
  }

  componentWillReceiveProps(newProps) {
    if(!isEqual(newProps.strategyReportValues, this.props.strategyReportValues)) {
      this.setStrategyReportValues(newProps.strategyReportValues);
    }

    if (!isEqual(newProps.organisationVersions, this.props.organisationVersions) && this.props.versioned){
      const { organisationVersions } = newProps.organisationVersions
      const latestVersion = organisationVersions[0].version
      this.callGetStrategyReportValues(latestVersion)
    }
  }

  componentDidUpdate() {
    scrollToTop();
  }

  render() {
    const { t, versioned, organisationVersions } = this.props;
    const { companyName, strategyReportValues } = this.state;
    let versionOptions;

    if (organisationVersions) {
      versionOptions = this.getOptionsDataForVersions()
    }
    
    return(
      <div className="results-wrapper">
        <div className={ (strategyReportValues) ? "" : "ui segment height-fill" }>
          <div className={ (strategyReportValues) ? "ui inverted dimmer" : "ui active inverted dimmer"}>
            <div className="ui large text loader">Loading</div>
          </div>
          <Container>
            <div className="heading-container">
              <div className="heading">
                <div className="heading-results">
                  {t('strategyReports.heading')}
                </div>
                <div className="heading-results-line-2">
                  {
                    this.state.impactOnValueCreation ? 
                    t('strategyReports.headingLineValueCreation') :
                    t('strategyReports.headingLinePeopleAndSociety')
                  }
                </div>
              </div>
              <div className="right-header-wrapper">
                {
                  !versioned && this.props.location.state && this.props.location.state.prevPath === 'questionnairePreviewPath' &&
                  <div className="matrix-back" onClick={this.props.history.goBack}>
                    {t('strategyReports.goBackToQuestionnaire')}
                  </div>
                }
                <div className="dropdown-wrapper">
                  {
                    versioned && organisationVersions &&
                    <div className="version-wrapper">
                      <label>{t('strategyReports.selectVersion')}</label>
                      <Dropdown
                        onChange={this.handleVersionChange}
                        placeholder='Select version'
                        selection
                        options={versionOptions}
                        defaultValue={versionOptions[0].value}
                      />
                    </div>
                  }
                  <div className="legend-select-wrapper">
                    <label>{t('strategyReports.selectLegendType')}</label>
                    <Dropdown
                      onChange={this.handleTickLabelChange}
                      placeholder='Select label'
                      selection
                      options={this.tickLabelOptions}
                      defaultValue={this.tickLabelOptions[0].value}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="company-heading-container">
              <div className="company-name">{companyName}</div>
              {
                strategyReportValues && strategyReportValues.strategyReportValues.date &&
                <Moment format="DD-MM-YYYY" date={strategyReportValues.strategyReportValues.date}/>
              }
              <div className="print-link" onClick={()=>this.handlePrint()}>Print</div>
            </div>
            <div className="results-container">
              {
                strategyReportValues && this.state.impactOnValueCreation &&
                this.getImpactOnValueCreationView()
              }
              {
                strategyReportValues && this.state.impactOnPeopleAndSociety &&
                this.getImpactOnPeopleAndSociety()
              }
              {
                strategyReportValues  && this.state.impactOnValueCreation &&
                this.getLegendViewFormImpactOnBusiness()
              }
              {
                strategyReportValues  && this.state.impactOnPeopleAndSociety &&
                this.getLegendViewFormImpactOnPeopleAndPlanet()
              }
            </div>
            <div className="view-other"onClick={()=>this.toggleReports()}>
              {
                strategyReportValues  && this.state.impactOnValueCreation &&
                t('strategyReports.viewImpactOnPeopleAndSociety')
              }
              {
                strategyReportValues  && this.state.impactOnPeopleAndSociety &&
                t('strategyReports.viewImpactOnValueCreation')
              }
              {
                strategyReportValues &&
                <img className="up-arrow" src={RIGHT_ARROW_ICON}/>
              }
            </div>
            <div className="padding-50">
            </div>
          </Container>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    userSession: state.auth.userSession,
    strategyReportValues: state.app.strategyReportValues,
    organisationVersions: state.app.organisationVersions
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    requestGetStrategyReportValues: requestGetStrategyReportValues,
    requestOrgVersions: requestOrgVersions,
    requestUpdateLastVisitedForReports: requestUpdateLastVisitedForReports
  },dispatch)
}

export default translate(['translations'], translateOptions)(connect(mapStateToProps, mapDispatchToProps)(StrategyAndReportView));