import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

//COMMON
import BaseLayout from '../components/base-layout-view/base-layout-view';

//COMPONENTS
import LandingPageView from '../components/landing-page-view/landing-page-view';
import EmailVerifiedView from '../components/email-verified-view/email-verified-view';
import AnalysisTypesView from '../components/analysis-types-view/analysis-types-view';
import StakeholderInsightsView from '../components/stakeholders-insights-view/stakeholders-insights-view';
import BusinessAbilityView from '../components/business-ability-view/business-ability-view';
import QuestionnaireView from '../components/questionnaire-view/questionnaire-view';
import AnalyseDriversView from '../components/analyze-drivers-view/analyse-drivers-view';
import ListOfTopicsView from '../components/list-of-topics-view/topics-data';
import RespondentQuestionnaireView from '../components/questionnaire-view/respondent-question-view';
import QuestionnairePreviewView from '../components/questionnaire-preview-view/questionnaire-preview-view';
import StrategyAndReportView from '../components/strategy-reports-view/strategy-reports-view';
import StakeholderInterviewsView from '../components/stakeholder-interviews-view/stakeholder-interviews-view';
import ValueChainAndBoundariesView from '../components/value-chain-and-boundaries-view/value-chain-and-boundaries-view';
import StrategyAndReportsFilesView from '../components/strategy-reports-file-view/strategy-reports-file-view';
import VersionedStrategyAndReportView from '../components/strategy-reports-view/strategy-reports-versioned-view';

import ProfileView from '../components/auth-module/profile-page-view/profile-page-view';

import SetPasswordPageView from '../components/auth-module/set-password-view/set-password-view';

import { isUserActive } from '../helpers/auth-helpers';
import { removeSlash } from '../utils/string-utils';
import topicsData from '../components/list-of-topics-view/topics-data';

const getRedirectionUrl = (redirectUrl) => {
  if (redirectUrl) return `/?ref=login&view=${removeSlash(redirectUrl.path)}`;
  else return '/?ref=login';
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isUserActive() ? (
        <Component {...props} />
      ) : (
        <Redirect to={getRedirectionUrl({ ...rest }, props)} />
      )
    }
  />
);

export default () => {
  return (
    <BrowserRouter>
      <Switch>
        <BaseLayout>
          <Route exact path="/" component={LandingPageView} />
          <Route
            exact
            path="/reset/:uid/:token"
            component={SetPasswordPageView}
          />
          <Route excat path="/email/verify/*" component={EmailVerifiedView} />

          <PrivateRoute exact path="/profile" component={ProfileView} />
          <PrivateRoute
            exact
            path="/analyse-drivers"
            component={AnalyseDriversView}
          />
          <PrivateRoute
            exact
            path="/stakeholder-interviews"
            component={StakeholderInterviewsView}
          />
          <PrivateRoute
            exact
            path="/value-chain-and-boundaries"
            component={ValueChainAndBoundariesView}
          />
          <PrivateRoute
            exact
            path="/strategy-reports-file"
            component={StrategyAndReportsFilesView}
          />
          <PrivateRoute
            exact
            path="/analysis-types"
            component={AnalysisTypesView}
          />
          <PrivateRoute
            exact
            path="/stakeholder-insights"
            component={StakeholderInsightsView}
          />
          <PrivateRoute
            exact
            path="/business-ability"
            component={BusinessAbilityView}
          />
          <PrivateRoute
            exact
            path="/:analysisType/questionnaire"
            component={QuestionnaireView}
          />
          <PrivateRoute
            exact
            path="/list-of-topics"
            component={ListOfTopicsView}
          />
          <PrivateRoute exact path="/fetched-topics" component={topicsData} />
          <PrivateRoute
            exact
            path="/:analysisType/questionnaire/question"
            component={RespondentQuestionnaireView}
          />
          <PrivateRoute
            exact
            path="/:analysisType/questionnaire-preview"
            component={QuestionnairePreviewView}
          />
          <PrivateRoute
            exact
            path="/strategy-report"
            component={StrategyAndReportView}
          />
          <PrivateRoute
            exact
            path="/all-strategy-reports"
            component={VersionedStrategyAndReportView}
          />
        </BaseLayout>
      </Switch>
    </BrowserRouter>
  );
};
