import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../i18n/config';
import { Container } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { requestUpdateUserName } from '../../../actions/auth-actions';
import { scrollToTop } from '../../../utils/misc-utils';
import ChangePasswordModal from './change-password-view/change-password-view';
import SuccessMessageView from '../../common/success-message-view';

class ProfileView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    this.state={
      userSession: null,
      editMode: false,
      changedName: null,
      openChangePasswordModal: false,
      passwordUpdated: false
    };
  }

  /***************************
   * METHODS
   ***************************/
  init() {
    this.setState({userSession: this.props.userSession})
  }

  toggleEditMode() {
    this.setState({editMode: true});
  }

  handleNameChange(e) {
    this.setState({changedName: e.target.value});
  }

  handleSubmit() {
    if(this.state.changedName) {
      let params = {
        first_name: this.state.changedName
      }
      this.props.requestUpdateUserName(params);

      let {userSession} = this.state;
      userSession.user.first_name = this.state.changedName;
      this.setState({userSession: userSession, editMode: false})
    } else {
      this.setState({editMode: false});
    }
  }

  openChangePasswordModal() {
    this.setState({openChangePasswordModal: true});
  }

  handleCloseModal(passwordUpdatedOnClose) {
    this.setState({openChangePasswordModal: false});
    if(passwordUpdatedOnClose.updatePasswordResponse) {
      this.passwordUpdatedSuccessFully();
    } else if(!passwordUpdatedOnClose.updatePasswordResponse && this.state.passwordUpdated) {
      this.setState({passwordUpdated: false});
    }
  }

  passwordUpdatedSuccessFully() {
    this.setState({passwordUpdated: true}, () => {
      this.clearMessage()
    });
  }

  clearMessage = () => {
    setTimeout(() => {
      this.setState({passwordUpdated: false});
    }, 10000);
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount(){
    scrollToTop();
    this.init()
  }

  render(){
    const { t, updatePasswordResponse } = this.props;
    const { userSession, editMode, openChangePasswordModal } = this.state;
    return(
      <div className="profile-view-wrapper">
        <Container className="profile-view">
          {
            userSession &&
            <div className="ui raised segment profile-form">
              {
                this.state.passwordUpdated &&
                <SuccessMessageView message={updatePasswordResponse.updatePasswordResponse}/>
              }
              <div className="user-name-container">
                <div>
                  {
                    !editMode &&
                    <div className="user-name">
                      {userSession.user.first_name}
                    </div>
                  }
                  {
                    editMode &&
                    <div className="ui input focus user-name">
                      <input type="text" defaultValue={userSession.user.first_name} onChange={(e)=>this.handleNameChange(e)} />
                    </div>
                  }
                  <div className="user-email">
                    {userSession.user.email}
                  </div>
                </div>
                <div>
                  {
                    !editMode &&
                    <div className="icon-icn-edit" onClick={()=>this.toggleEditMode()}/>
                  }
                  {
                    editMode &&
                    <div className="save-username" onClick={()=>this.handleSubmit()}>
                      {t('common.save')}
                    </div>
                  }
                  <Link to={'/all-strategy-reports'}>
                    <div className="view-reports">
                      View reports
                    </div>
                  </Link>
                </div>
              </div>
              <hr/>
              <div className="company-details-container">
                <div className="company-name-container">
                  <div className="company-header">
                    {t('common.company').toUpperCase()}
                  </div>
                  <div className="company-name">
                    {userSession.user.org.name}
                  </div>
                </div>
                <div className="phone-number-container">
                  <div className="company-header">
                    {t('common.phone').toUpperCase()}
                  </div>
                  <div className="company-name">
                    {userSession.user.org.phone}
                  </div>
                </div>
              </div>
              <div className="change-password" onClick={()=>this.openChangePasswordModal()}>
                {t('auth.changePassword')}
              </div>
            </div>
          }
          {
            userSession && openChangePasswordModal &&
            <ChangePasswordModal
              open={true}
              handleCloseModal={(passwordUpdatedOnClose)=> this.handleCloseModal(passwordUpdatedOnClose)}
            />
          }
        </Container>
      </div>
    )}
        
}

function mapStateToProps(state) {
  return {
    userSession: state.auth.userSession,
    updatePasswordResponse: state.auth.updatePasswordResponse
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    requestUpdateUserName, requestUpdateUserName
  },dispatch)
}

export default translate(['translations'], translateOptions)(connect(mapStateToProps, mapDispatchToProps)(ProfileView));