import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../../../i18n/config';
import { Form, Button } from 'semantic-ui-react';
import { STEP_TWO } from '../../../../../constants/misc';
import { isEqual } from 'lodash';

import ErrorMessageView from '../../../../common/error-message-view';
import EditGradesView from './edit-grade-view/edit-grade-view';

class StepThreeView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    this.state={
      errorMessage: ""
    };
  };

  /***************************
   * VIEWS
   ***************************/
  getDetermineValuesView(){
    const { t } = this.props;
    const { grades, errorMessage } = this.state;
    const gradesList = grades.map((grade, index) => {
      return (
        <EditGradesView
          key={`key_${grade.name}_${index}`}
          index={ index }
          grade={ grade }
          errors={this.state.errors}
          onChangeCallback={(params)=> this.handleChange(index, params)}
        />
      )
    });

    return(
      <Form onSubmit={(e) => this.handleSubmit(e)}>
        { gradesList }
        <div className="error-wrapper" style={{marginTop: '20px'}}>
          { errorMessage && <ErrorMessageView error={errorMessage} /> }
        </div>
        <div className='form-footer'>
          <Button className='app-btn orange-solid-btn' type="submit">
            {t('common.finish')}
          </Button>
        </div>
      </Form>
    )
  }

  /***************************
   * METHODS
   ***************************/
  handleChange(index, params){
    const { grades } = this.state;
    grades[index] = params;
    this.setState({grades, errors:undefined});
  }

  handleSubmit(){
    const { t } = this.props;
    const { grades } = this.state;
    if (this.isFormInValid()){
      this.setState({errorMessage: t('stakeholdersInsightsPage.noPointsErrorMessage')}, ()=>{
        this.resetErrorMessage();
      });
    } else {
      this.props.updateGradesCallback(grades);
      this.props.showOrHideReviewModal();
    }
  }

  updateView(view){
    this.props.updateViewCallback(view)
  }

  updateStakeHolders(grades){
    this.setState({grades: grades});
  }

  isFormInValid = () => {
    let isFormInValid;
    const { grades } = this.state;
    for (let index=0; index<grades.length; index++){
      const points = grades[index].points;
      if ((points === null) || points === ""){
        isFormInValid = true;
        break;
      } else {
        isFormInValid = false;
      }
    }
    return isFormInValid;
  }

  resetErrorMessage(){
    setTimeout(()=> {
      this.setState({errorMessage: ""});
    }, 3000);
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount() {
    if (this.props.gradesResponse){
      const { gradesResponse } = this.props;
      this.updateStakeHolders(gradesResponse.gradesResponse);
    }
  }

  componentWillMount(){
    this.props.getGradesCallback();
  }

  componentWillReceiveProps(newProps){
    if (!isEqual(newProps.gradesResponse, this.props.gradesResponse))
      this.updateStakeHolders(newProps.gradesResponse.gradesResponse);
  }

  componentWillUnmount(){
    this.setState({errorMessage: ""});
  }

  render(){
    const { t } = this.props;
    const { grades } = this.state;
    return(
      <div className="step-three-view">
        <div className="back-btn-wrapper">
          <div className="back-btn" onClick={()=> this.updateView(STEP_TWO)}>
            <div className="icon-wrapper">
              <span className="icon-icn_back"></span>
            </div>
            <div className="btn-text">
              {t('stakeholdersInsightsPage.backToStakeholderStep2')}
            </div>
          </div>
        </div>
        <div className="view-heading-wrapper">
          <div className="heading">
            {t('stakeholdersInsightsPage.step3ViewHeading')}
          </div>
        </div>
        <div className="step-three-form-wrapper">
          { grades && this.getDetermineValuesView() }
        </div>
      </div>
    );
  };

}

export default translate(['translations'], translateOptions)(connect(null)(StepThreeView));