import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/config';
import store, { history } from './store/store';
import AppRoutes from './routes/routes';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';

import 'semantic-ui-css/semantic.min.css';
import './styles/index.css';
import * as Sentry from '@sentry/browser';

console.log(process.env);
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
});

const target = document.querySelector('#root');
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AppRoutes />
      </ConnectedRouter>
    </Provider>
  </I18nextProvider>,
  target
);

// registerServiceWorker();
unregister();
