import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../i18n/config';
import { scrollToTop } from '../../utils/misc-utils';
import { requestQuestionnaireGradePoints,
  requestgetTopicCategories,
  requestGetQuestionnaireQuestion,
  requestupdateQuestionnaireQuestion,
  requestgetEditWeightageValues,
  requestUpdateEditWeightageValues, requestCleargetTopicCategories,
  resetAnswerQuestionnaireQuestionResponse } from '../../actions/app-actions';
import SingleQuestionView from '../common/questionnaire-template';
import {getActiveOrg} from "../../helpers/auth-helpers";
import { isEqual } from 'lodash';
import DifferentCategoryModal from './questionnaire-modal-component/different-category-modal';

class RespondentQuestionnaireView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.state={
      org_id: getActiveOrg(userSession),
      questionnaireQuestions: null,
      questionNumber: 1,
      EndOfQuestionnaireForTopicCategory: false,
      isLoading: false,
      isError: false
    };
  };

  /***************************
   * METHODS
   ***************************/
  getQuestionnaireData() {
    let respondentIdFromStorage;
    const {selectedSTakeHolderResponse} = this.props;
    if(selectedSTakeHolderResponse && selectedSTakeHolderResponse.selectedSTakeHolderResponse) {
      localStorage.setItem('currentRespondentId',selectedSTakeHolderResponse.selectedSTakeHolderResponse.id);
    } else {
      respondentIdFromStorage = localStorage.getItem('currentRespondentId')
    }
    if ((selectedSTakeHolderResponse && selectedSTakeHolderResponse.selectedSTakeHolderResponse) || respondentIdFromStorage) {
      const respondentId = selectedSTakeHolderResponse ?
        selectedSTakeHolderResponse.selectedSTakeHolderResponse.id :
        respondentIdFromStorage;
      
      const { org_id } = this.state;

      const params = {
        org_id: org_id,
        respondent_id: respondentId,
      }
      const type = this.props.match.params.analysisType;

      this.setState({questionnaireSortOnTopicEnabled: false, EndOfQuestionnaireForTopicCategory: false,});
      this.props.requestGetQuestionnaireQuestion(params, type);
      this.props.requestQuestionnaireGradePoints({org_id: org_id});
      this.props.requestgetTopicCategories(params, type);
    }
  }

  getQuestionnaireQuestionForTopic(categoryId) {
    const respondentIdFromStorage = localStorage.getItem('currentRespondentId');
    if (this.props.selectedSTakeHolderResponse || respondentIdFromStorage) {
      const {selectedSTakeHolderResponse} = this.props;
      const respondentId = selectedSTakeHolderResponse ? 
        selectedSTakeHolderResponse.selectedSTakeHolderResponse.id :
        respondentIdFromStorage;
      const { org_id } = this.state;

      const params = {
        org_id: org_id,
        respondent_id: respondentId,
      }
      const type = this.props.match.params.analysisType;
      const topicCategoryId = categoryId;

      this.setState({questionNumber: 1, EndOfQuestionnaireForTopicCategory: false, questionnaireSortOnTopicEnabled: true});
      this.props.requestGetQuestionnaireQuestion(params, type, topicCategoryId);
      this.props.requestQuestionnaireGradePoints({org_id: org_id});
      this.props.requestgetTopicCategories(params, type);
    }
  }

  getQuestionnaireTopicCategories() {
    let respondentIdFromStorage;
    const {selectedSTakeHolderResponse} = this.props;
    if(selectedSTakeHolderResponse && selectedSTakeHolderResponse.selectedSTakeHolderResponse) {
      localStorage.setItem('currentRespondentId',selectedSTakeHolderResponse.selectedSTakeHolderResponse.id);
    } else {
      respondentIdFromStorage = localStorage.getItem('currentRespondentId')
    }
    if ((selectedSTakeHolderResponse && selectedSTakeHolderResponse.selectedSTakeHolderResponse) || respondentIdFromStorage) {
      const respondentId = selectedSTakeHolderResponse ?
        selectedSTakeHolderResponse.selectedSTakeHolderResponse.id :
        respondentIdFromStorage;
      
      const { org_id } = this.state;

      const params = {
        org_id: org_id,
        respondent_id: respondentId,
      }
      const type = this.props.match.params.analysisType;

      this.props.requestCleargetTopicCategories();
      this.props.requestgetTopicCategories(params, type);
    } 
  }

  getEditWeightageValues(params) {
    const { org_id } = this.state;
    params['org_id'] = org_id;
    const type = this.props.match.params.analysisType;
    this.props.requestgetEditWeightageValues(params, type)
  }

  updateEditWeightageValues(orgParams, postData) {
    this.props.requestUpdateEditWeightageValues(orgParams, postData);
  }

  updateQuestionnaireAnswers(params) {
    const respondentIdFromStorage = localStorage.getItem('currentRespondentId');
    const {selectedSTakeHolderResponse} = this.props;
    const respondentId = selectedSTakeHolderResponse ?
      selectedSTakeHolderResponse.selectedSTakeHolderResponse.id :
      respondentIdFromStorage;
    const { org_id } = this.state;
    params.org_id = org_id;
    params.respondent_id = respondentId;
    this.props.requestupdateQuestionnaireQuestion(params)
    this.setState({
      isLoading: true
    })
  }

  nextQuestion(analysisType) {
    const respondentIdFromStorage = localStorage.getItem('currentRespondentId');
    let questions = this.state.questionnaireQuestions;

    const { org_id } = this.state;
    const {selectedSTakeHolderResponse, questionnaireTopicCategories} = this.props;
    const respondentId = selectedSTakeHolderResponse ?
      selectedSTakeHolderResponse.selectedSTakeHolderResponse.id :
      respondentIdFromStorage;
    const params = {
      org_id: org_id,
      respondent_id: respondentId,
    }
    
    if(questions.length > 1) {
      questions.splice(0,1);
      this.setState({questionnaireQuestions: questions, questionNumber: this.state.questionNumber+1});
      this.props.requestQuestionnaireGradePoints({org_id: org_id});
      this.props.requestgetTopicCategories(params, analysisType);
    } else if(this.state.questionnaireSortOnTopicEnabled) {
      this.props.requestCleargetTopicCategories();
      this.props.requestgetTopicCategories(params, analysisType);
      this.setState({EndOfQuestionnaireForTopicCategory: true});
    }
    else {
      this.props.history.push(`/${analysisType}/questionnaire-preview`);
    }
    this.props.resetAnswerQuestionnaireQuestionResponse()
  }
  
  setQuestionnaireQuestions(questions) {
    this.setState({questionnaireQuestions: questions});
  }

  /***************************
   * VIEWS
   ***************************/

  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount() {
    scrollToTop();
    this.getQuestionnaireData();
    if(this.props.questionnaireQuestions) {
      this.setQuestionnaireQuestions(this.props.questionnaireQuestions.questionnaireQuestions);
    }
  }

  componentDidUpdate(prevProps) {
    if(!isEqual(prevProps.questionnaireQuestions, this.props.questionnaireQuestions)) {
      this.setQuestionnaireQuestions(this.props.questionnaireQuestions.questionnaireQuestions);
    }
  }

  componentWillReceiveProps(newProps) {
    if(!isEqual(newProps.questionnaireQuestions, this.props.questionnaireQuestions)) {
      this.setQuestionnaireQuestions(newProps.questionnaireQuestions.questionnaireQuestions);
    }
    if(!isEqual(newProps.respondentAnswerQuestionnaireQuestionResponse, this.props.respondentAnswerQuestionnaireQuestionResponse)) {
      if(newProps.respondentAnswerQuestionnaireQuestionResponse.isError) {
        this.setState({
          isLoading: false,
          isError: true,
          errorMessage: newProps.respondentAnswerQuestionnaireQuestionResponse.respondentAnswerQuestionnaireQuestionResponse
        }, () => {
          this.props.resetAnswerQuestionnaireQuestionResponse()
        })

      } else if (newProps.respondentAnswerQuestionnaireQuestionResponse.isSuccess) {
        this.nextQuestion(newProps.match.params.analysisType);
        this.setState({
          isLoading: false,
          isError: false,
          errorMessage: null
        }, () => {
          this.props.resetAnswerQuestionnaireQuestionResponse()
        })
      }
    }
  }


  render(){
    const { t } = this.props;
    const { questionnaireQuestions, isLoading } = this.state;
    let lastQuestion = false;
    if (questionnaireQuestions && questionnaireQuestions.length === 1) {
      lastQuestion = true;
    }

    return(
      <div className="questionnaire-question-wrapper">
        <div className={ !questionnaireQuestions || isLoading ? "ui segment height-fill" : "" }>
          <div className={ !questionnaireQuestions || isLoading ? "ui active inverted dimmer" : "ui inverted dimmer"}>
            <div className="ui large text loader">Loading</div>
          </div>
          <p></p>
          {
            questionnaireQuestions &&
            questionnaireQuestions.length > 0 &&
            <SingleQuestionView
            question= {questionnaireQuestions[0]}
            topicCategories= {this.props.questionnaireTopicCategories}
            questionnaireGradePoints= {this.props.questionnaireGradePoints}
            getEditWeightageValues={(params, type) => this.getEditWeightageValues(params,type)}
            analysisType={this.props.match.params.analysisType}
            questionNumber={this.state.questionNumber}
            lastQuestion={lastQuestion}
            isError={this.state.isError}
            errorMessage={this.state.errorMessage}
            updateEditWeightageValues={(orgParams, postData)=>this.updateEditWeightageValues(orgParams, postData)}
            getQuestionnaireQuestionForTopic={(categoryId) => this.getQuestionnaireQuestionForTopic(categoryId)}
            updateQuestionnaireAnswers={(params)=> this.updateQuestionnaireAnswers(params)}
            getQuestionnaireTopicCategories={()=>this.getQuestionnaireTopicCategories()}
            EndOfQuestionnaireForTopicCategory={this.state.EndOfQuestionnaireForTopicCategory}
            />
          }
          {
            questionnaireQuestions &&
            questionnaireQuestions.length === 0 &&
            this.props.history.push(`/${this.props.match.params.analysisType}/questionnaire-preview`)
          }
        </div>
      </div>
    );
  };
}

function mapStateToProps(state) {
  return {
    userSession: state.auth.userSession,
    questionnaireQuestions: state.app.questionnaireQuestions,
    questionnaireTopicCategories: state.app.questionnaireTopicCategories,
    questionnaireGradePoints: state.app.questionnaireGradePoints,
    selectedSTakeHolderResponse: state.app.selectedSTakeHolderResponse,
    respondentAnswerQuestionnaireQuestionResponse: state.app.respondentAnswerQuestionnaireQuestionResponse
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    requestQuestionnaireGradePoints: requestQuestionnaireGradePoints,
    requestgetTopicCategories: requestgetTopicCategories,
    requestGetQuestionnaireQuestion: requestGetQuestionnaireQuestion,
    requestupdateQuestionnaireQuestion: requestupdateQuestionnaireQuestion,
    requestgetEditWeightageValues: requestgetEditWeightageValues,
    requestUpdateEditWeightageValues: requestUpdateEditWeightageValues,
    requestCleargetTopicCategories: requestCleargetTopicCategories,
    resetAnswerQuestionnaireQuestionResponse: resetAnswerQuestionnaireQuestionResponse
  },dispatch)
}

export default translate(['translations'], translateOptions)(connect(mapStateToProps, mapDispatchToProps)(RespondentQuestionnaireView));