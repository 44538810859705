import React, { Component } from 'react';
import {ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, LabelList, Label} from 'recharts';
import { formDataSetForGraph, formLineDataSetForGraph, getListColor, getArrowAngle, getTopicNamesForCoordinates } from '../../../utils/array-utils';
import { GRADE_CONSTANTS, TICK_COUNT } from '../../../constants/scatter-chart';
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';

const CustomShape = (props) => {
  const {cx, cy, fill, radius, index} = props;
  const { x, y } = props.node;

  const dataKey = props.xAxis ? props.xAxis.dataKey : 0;
  let key = "arrow"+index+dataKey;

  let currRadius = radius
  if ( x===0 && y===0 ) {
    currRadius = 0;
  }

  let showArrow = -1;
  if (props.xAxis.dataKey === "impact_on_people_and_planet") {
    if (props.impact_on_people_details && props.impact_on_people_details.arrow_type) {
      showArrow = getArrowAngle(props.impact_on_people_details.arrow_type)
    }
  }
  else if (props.xAxis.dataKey === "influence_on_the_business") {
    if (props.impact_on_value_creation_details && props.impact_on_value_creation_details.arrow_type) {
      showArrow = getArrowAngle(props.impact_on_value_creation_details.arrow_type)
    }
  }

  return (
    <g>
      <circle cx={cx} cy={cy} r={currRadius} fill={fill} />
      <g transform={`translate(${cx},${cy})`}>
        <text x={10} y={0} dy={4} textAnchor="left"></text>
      </g>
      {
        (currRadius !== 0) && showArrow >=0 &&
        <g transform={`translate(${cx},${cy})`}>
          <defs>
            <marker id={key} markerWidth="10" markerHeight="10" refX="0" refY="3" orient="auto" markerUnits="strokeWidth" viewBox="0 0 20 20">
              <path d="M0,0 L0,6 L9,3 z" fill="#f00" />
            </marker>
          </defs>
          <line x1="13" y1="0" x2="25" y2="0" stroke="#f00" transform={`rotate(${showArrow})`} strokeWidth="1.5" markerEnd={`url(#${key})`}/>
        </g>
      }
    </g>
   );
};


class CustomizedXAxisTick extends Component {
  render() {
    const {
      x, y, stroke, payload,
    } = this.props;

    let svgTextElement = null;

    if(this.props.index === 0) {
      svgTextElement = <text x={0} y={0} dy={16}>Medium</text>
    } else if(this.props.index === TICK_COUNT - 1) {
      svgTextElement = <text x={-40} y={0} dy={16}>Very high</text>
    }

    return (
      <g transform={`translate(${x},${y})`}>
        {svgTextElement}
      </g>
    );
  }
}

class CustomizedYAxisTick extends Component {
  render() {
    const {
      x, y, stroke, payload,
    } = this.props;

    let svgTextElement = null;

    if(this.props.index === 0) {
      svgTextElement = <text x={-60} y={-10} dy={16}>Medium</text>
    } else if(this.props.index === 5) {
      svgTextElement = <text x={-60} y={-10} dy={16}>Very high</text>
    }

    return (
      <g transform={`translate(${x},${y})`}>
        {svgTextElement}
      </g>
    );
  }
}


class SimpleScatterChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSet: [],
      lineDataSet: [],
    }

    this.customToolTip = this.customToolTip.bind(this);
  }

  customToolTip = (props) => {
    let topicNames;
    if (props.payload[0] != null) {
      topicNames = getTopicNamesForCoordinates(props.payload, this.state.dataSet);
      const newPayload = [
        ...props.payload,
      ];
      topicNames.map((topic, index) => {
        newPayload.push({
          name: 'Topic',
          separator: ' - ',
          value: topic
        })
        return topic
      })
      return <DefaultTooltipContent {...props} payload={newPayload} />;
    }
    return <DefaultTooltipContent {...props} />;
  }

  formLineDataSet() {
    const { xAxisKey, yAxisKey } = this.props;
    const gradesLinesLimit = [
      {
        points: GRADE_CONSTANTS.LOW_GRADE,
      },
      {
        points: GRADE_CONSTANTS.MEDIUM_GRADE,
      },
      {
        points: GRADE_CONSTANTS.HIGH_GRADE
      }
    ]
    const lineData = gradesLinesLimit.map((grade) => formLineDataSetForGraph(grade, xAxisKey, yAxisKey));
    this.setState({lineDataSet: lineData});
  }

  updateGraph(props){
    const {xAxisKey, yAxisKey} = props;
    const dataSet = formDataSetForGraph(props.strategyReportValues.categories, xAxisKey, yAxisKey);
    this.formLineDataSet();
    this.setState({dataSet: dataSet});
  }

  componentDidMount() {
    this.updateGraph(this.props)
  }

  componentWillReceiveProps(newProps){
    this.updateGraph(newProps)
  }

  render () {
    const {xAxisKey, yAxisKey, xAxizLabel, yAxizLabel, selectedTickLabel} = this.props;
    let fillColor = getListColor();
    let xAxis = null;
    let yAxis = null;

    if(selectedTickLabel === 'text') {
      xAxis = <XAxis dataKey={xAxisKey} type="number" tick={<CustomizedXAxisTick/>} tickCount={TICK_COUNT}>
              <Label value={xAxizLabel} position="insideBottom" offset={-10} />
            </XAxis>
      yAxis = <YAxis width={90}dataKey={yAxisKey} type="number" tick={<CustomizedYAxisTick/>} tickCount={TICK_COUNT}>
              <Label value={yAxizLabel} angle={-90} position='center'  offset={10} />
            </YAxis>
    } else {
      xAxis = <XAxis dataKey={xAxisKey} type="number" tickCount={TICK_COUNT}>
              <Label value={xAxizLabel} position="insideBottom" offset={-10} />
            </XAxis>
      yAxis = <YAxis width={90} dataKey={yAxisKey} type="number" tickCount={TICK_COUNT}>
              <Label value={yAxizLabel} angle={-90} position='center'  offset={10} />
            </YAxis>
    }

  	return (
      <div>
        {
          this.state.dataSet &&
          <ScatterChart width={500} height={500} margin={{top: 35, right: 35, bottom: 20, left: 20}}>
            <CartesianGrid fillOpacity={0.2} stroke="rgb(140, 160, 179, 0.4)" strokeDasharray="3 3" />
            {xAxis}
            {yAxis}
            {
              this.state.dataSet.map((data, index) => {
                return (
                  <Scatter name={data.topic} key={data.topic+'_'+index} strokeWidth={5} data={data} fill={fillColor[index]} radius={8} shape={<CustomShape />} >
                    <LabelList dataKey="index" fontSize={10}/>
                  </Scatter>
                )
              })
            }
            {
              this.state.lineDataSet &&
              this.state.lineDataSet.map((lineData, index) => {
                return (
                  <Scatter name='line data' key={'key'+index} data={lineData} line={{stroke: 'rgb(255, 80, 102, 0.69)', strokeWidth: 1}} radius={0} shape={<CustomShape />}>
                  </Scatter>
                )
              })
            }
            <Tooltip content={this.customToolTip} wrapperStyle={{zIndex: 1000}} cursor={{strokeDasharray: '3 3'}}/>
          </ScatterChart>
        }
        {
          this.props.getChartExplanationView()
        }
      </div>
    );
  }
}

export default SimpleScatterChart;