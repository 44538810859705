import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import { translateOptions } from '../../i18n/config';
import { scrollToTop } from '../../utils/misc-utils';
import {
  requestAnalyseDrivers
} from '../../actions/app-actions';
import {getActiveOrg} from "../../helpers/auth-helpers";
import AnalyseDriversComponent from './analyze-drivers-list-component';
import EmptyStateView from '../common/empty-state-view';

class AnalyseDriversView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);
    const { userSession } = this.props;
    this.state={
      org_id: getActiveOrg(userSession)
    };
  };

  /***************************
   * METHODS
   ***************************/
  /***************************
   * VIEWS
   ***************************/
  getAnalyseDriversView(analyseDriver, index) {
    const elementId = `key_${analyseDriver.file_name}_${index}`;
    return(
      <AnalyseDriversComponent
        key={ elementId }
        index={ index }
        analyseDriverFile={ analyseDriver }
      />
    )
  }

  getEmptyStateView() {
    const { t } = this.props;
    return(
      <EmptyStateView message={t('analyseDrivers.nofilesFound')} />
    )
  }


  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount() {
    scrollToTop();

    const { org_id } = this.state;
    const params = {
      org_id: org_id
    }

    this.props.requestAnalyseDrivers(params);
  }

  render(){
    const { t } = this.props;
    const { listAnalyseDrivers } = this.props;
    return(
    <Container>
      <div className={ !listAnalyseDrivers ? "ui active inverted dimmer" : "ui inverted dimmer"}>
        <div className="ui large text loader">Loading</div>
      </div>
      <div className="analyse-drivers-heading">
        {t('analyseDrivers.analyseDriversheading')}
      </div>
      <div className="analyse-divers-wrapper">
        { 
          listAnalyseDrivers && (listAnalyseDrivers.listAnalyseDrivers.length > 0) ?
          listAnalyseDrivers.listAnalyseDrivers.map((analyseDriver, index)=> (this.getAnalyseDriversView(analyseDriver, index)) )
            : this.getEmptyStateView() 
        }
      </div>
    </Container>
    );
  };

}

function mapStateToProps(state) {
  return {
    userSession: state.auth.userSession,
    listAnalyseDrivers: state.app.listAnalyseDrivers,
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    requestAnalyseDrivers: requestAnalyseDrivers
  },dispatch)
}

export default translate(['translations'], translateOptions)(connect(mapStateToProps, mapDispatchToProps)(AnalyseDriversView));