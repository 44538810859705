import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../i18n/config';
import { getListColor } from '../../../utils/array-utils';

class StrategyAndReportLegend extends Component {
  constructor(props) {
    super(props);
    this.topicIndex = 0;
  }

  getCategoryTableView(category, index) {
    const {t, impactOnValueCreation, impactOnPeopleAndSociety } = this.props;
    const categoryName = category.name;
    const elementId = categoryName+'_'+index;
    let fillColor = getListColor();
    return (
      <table className="ui basic table" key={elementId} style={{"backgroundColor": fillColor[index]}}>
        <thead>
          <tr>
            <th>{categoryName}</th>
            <th>{t('strategyReports.legendheading1')}</th>
            { impactOnValueCreation && <th>{t('strategyReports.legendheading2')}</th> }
            { impactOnPeopleAndSociety && <th>{t('strategyReports.legendheading3')}</th> }
          </tr>
        </thead>
        <tbody>
          {
            category.topics.map((topic, index) => {
              this.topicIndex++;
              return (
                <tr key={index}>
                  <td>{this.topicIndex}. {topic.topic}</td>
                  <td>{topic.stakeholder_perceptions}</td>
                  { impactOnValueCreation && <td>{topic.influence_on_the_business}</td> }
                  { impactOnPeopleAndSociety && <td>{topic.impact_on_people_and_planet}</td> }
                </tr>
              )
            })
          }
        </tbody>
      </table>
    )
  }

  componentWillUpdate(){
    this.topicIndex = 0
  }

  render() {
    const { strategyReportValues } = this.props;
    return(
      <div className="legend-wrapper">
          {
            strategyReportValues &&  strategyReportValues.categories.length > 0 &&
            strategyReportValues.categories.map((category, index) => this.getCategoryTableView(category, index))
          }
      </div>
    )
  }
}

export default translate(['translations'], translateOptions)(connect(null)(StrategyAndReportLegend));