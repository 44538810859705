import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import { translateOptions } from '../../i18n/config';
import { scrollToTop } from '../../utils/misc-utils';
import {
  requestStakeholderInterviews
} from '../../actions/app-actions';
import {getActiveOrg} from "../../helpers/auth-helpers";
import StakeholderInterviewsComponent from './stakeholder-interviews-component';
import EmptyStateView from '../common/empty-state-view';

class StakeholderInterviewsView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);
    const { userSession } = this.props;
    this.state={
      org_id: getActiveOrg(userSession)
    };
  };

  /***************************
   * METHODS
   ***************************/
  /***************************
   * VIEWS
   ***************************/
  getStakeholderInterviewsView(stakeholderInterview, index) {
    const elementId = `key_${stakeholderInterview.file_name}_${index}`;
    return(
      <StakeholderInterviewsComponent
        key={ elementId }
        index={ index }
        stakeholderInterviewFile={ stakeholderInterview }
      />
    )
  }

  getEmptyStateView() {
    const { t } = this.props;
    return(
      <EmptyStateView message={t('stakeholderInterviews.nofilesFound')} />
    )
  }


  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount() {
    scrollToTop();

    const { org_id } = this.state;
    const params = {
      org_id: org_id
    }

    this.props.requestStakeholderInterviews(params);
  }

  render(){
    const { t } = this.props;
    const { listStakeholderInterviews } = this.props;
    return(
    <Container>
      <div className={ !listStakeholderInterviews ? "ui active inverted dimmer" : "ui inverted dimmer"}>
        <div className="ui large text loader">Loading</div>
      </div>
      <div className="stakeholder-interviews-heading">
        {t('stakeholderInterviews.stakeholderInterviewsHeading')}
      </div>
      <div className="stakeholder-interviews-wrapper">
        { 
          listStakeholderInterviews && (listStakeholderInterviews.listStakeholderInterviews.length > 0) ?
          listStakeholderInterviews.listStakeholderInterviews.map((stakeholderInterview, index)=> (this.getStakeholderInterviewsView(stakeholderInterview, index)) )
            : this.getEmptyStateView() 
        }
      </div>
    </Container>
    );
  };

}

function mapStateToProps(state) {
  return {
    userSession: state.auth.userSession,
    listStakeholderInterviews: state.app.listStakeholderInterviews,
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    requestStakeholderInterviews: requestStakeholderInterviews
  },dispatch)
}

export default translate(['translations'], translateOptions)(connect(mapStateToProps, mapDispatchToProps)(StakeholderInterviewsView));