import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../../i18n/config';
import { withRouter } from 'react-router-dom';
import { Modal, Form, Button } from 'semantic-ui-react';
import ErrorMessageView from '../../../common/error-message-view';
import { requestChangePassword } from '../../../../actions/auth-actions';

class ChangePasswordModal extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    this.state={
      open: this.props.open,
      fields: {
        password: '',
        confirmPassword: ''
      },
      serverError: "",
      isFormViewVisible: true,
      errors: {}
    };

    this.close = this.close.bind(this);
  }

  /***************************
   * METHODS
   ***************************/
  close = (passwordUpdatedOnClose)=> {
    this.setState({ open: false });
    if ( passwordUpdatedOnClose && passwordUpdatedOnClose.updatePasswordResponse ) {
      this.props.handleCloseModal(passwordUpdatedOnClose);
    }
    else {
      passwordUpdatedOnClose = {
        updatePasswordResponse: false
      }
      this.props.handleCloseModal(passwordUpdatedOnClose);
    }
  }

  handleChange = (e)=> {
    let fields = this.state.fields;
    const field = e.target.name;
    fields[field] = e.target.value;
    this.setState({
      fields,
      errors: {}
    })
  }

  handleSubmit = (e)=> {
    e.preventDefault();
    const formValidationFeedback = this.handleFormValidation(this.state.fields);
    if(formValidationFeedback.isFormValid){
      const fields = this.state.fields;
      const params = {
        new_password1: fields.password,
        new_password2: fields.confirmPassword,
      };
      this.props.requestChangePassword(params);
    }else{
      this.setState({errors:formValidationFeedback.errors});
    }
  }

  handleFormValidation = (fields)=> {
    let errors = {};
    let formIsValid = true;
    let result = {};

    if(fields["password"] === ''){
      formIsValid = false;
      errors["password"] = "*This field is required.";
    } else if(fields["confirmPassword"] === ''){
      formIsValid = false;
      errors["confirmPassword"] = "*Please retype new password";
    } else if(fields["confirmPassword"] !== fields["password"]){
      formIsValid = false;
      errors["confirmPassword"] = "Password mismatch.";
    } else if(fields["password"].length < 8 || fields["confirmPassword"].length < 8) {
      formIsValid = false;
      errors["password"] = "This password is too short. It must contain at least 8 characters.";
    }

    result['errors'] = errors;
    result['isFormValid'] = formIsValid;
    return result;
  }

  getFieldErrorView(error) {
    return(
      <ErrorMessageView error={error} />
    )
  }

  passwordUpdatedSuccessFully() {
    let passwordUpdatedOnClose = {
      updatePasswordResponse: true
    }
    this.close(passwordUpdatedOnClose);
  }
  /***************************
   * LIFECYCLE
   ***************************/
  componentWillReceiveProps(newProps) {
    newProps.updatePasswordResponse ? this.passwordUpdatedSuccessFully() : '';
  }

  render(){
    const { t } = this.props;
    const { errors, serverError, open } = this.state;
    return(
      <Modal className="change-password-module-view" open={open} onClose={this.close} size={'tiny'}>
        <Modal.Content>
          <div>
            <div className="heading">{t('auth.changePassword')}</div>
            <Form className="set-password-form" onSubmit= {this.handleSubmit}>
              <Form.Field>
                <input
                  name='password'
                  type='password'
                  placeholder={t('auth.password')}
                  onChange={this.handleChange}
                  className={`${errors['email'] && 'highlight-input'}`}
                />
                {errors['password'] && this.getFieldErrorView(errors["password"])}
              </Form.Field>
              <Form.Field>
                <input
                  name='confirmPassword'
                  type='password'
                  placeholder={t('auth.confirmPassword')}
                  onChange={this.handleChange}
                  className={`${errors['confirmPassword'] && 'highlight-input'}`}
                />
                {errors['confirmPassword'] && this.getFieldErrorView(errors["confirmPassword"])}
              </Form.Field>
              <div className="error-msg-wrapper">
                { serverError && this.getFieldErrorView(serverError)}
              </div>
              <div className="btn-wrapper">
                <Button className="submit-button" type='submit'>{t('common.submit')}</Button>
                <Button className="cancel-button" type='button' onClick={()=>this.close()}>{t('common.cancel')}</Button>
              </div>
            </Form>
          </div>
        </Modal.Content>
      </Modal>
    );
  };
}



function mapStateToProps(state) {
  return {
    userSession: state.auth.userSession,
    updatePasswordResponse: state.auth.updatePasswordResponse
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    requestChangePassword, requestChangePassword
  },dispatch)
}

export default withRouter(translate(['translations'], translateOptions)(connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal)));