import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../../i18n/config';

import StepOneView from './step-one-view/step-one-view';
import StepTwoView from './step-two-view/step-two-view';
import StepThreeView from './step-three-view/step-three-view';

import {
  STEP_ONE,
  STEP_THREE,
  STEP_TWO
} from '../../../../constants/misc';

class RightSidePanel extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    this.state={};
  };

  /***************************
   * VIEWS
   ***************************/
  getStepOneView = ()=> {
    const { stakeHolderResponse, addStakeHolderResponse } = this.props;
    return(
      <StepOneView
        stakeHolderResponse={stakeHolderResponse}
        addStakeHolderResponse={addStakeHolderResponse}
        updateViewCallback={(step)=> this.props.updateViewCallback(step)}
        addStakeHolderCallback={(params)=> this.props.addStakeHolderCallback(params)}
        deleteStakeHolderCallback={(params)=> this.props.deleteStakeHolderCallback(params)}
        updateStakeHolderCallback={(params)=> this.props.updateStakeHolderCallback(params)}
        clearStatesCallback={()=> this.props.clearStatesCallback()}
      />
    )
  }

  getStepTwoView = ()=> {
    const { stakeHolderResponse } = this.props;
    return(
      <StepTwoView
        stakeHolderResponse={stakeHolderResponse}
        updateViewCallback={(step)=> this.props.updateViewCallback(step)}
        deleteStakeHolderCallback={(params)=> this.props.deleteStakeHolderCallback(params)}
        updateStakeHolderCallback={(params)=> this.props.updateStakeHolderCallback(params)}
        clearStatesCallback={()=> this.props.clearStatesCallback()}
      />
    )
  }

  getStepThreeView = ()=> {
    const { gradesResponse } = this.props;
    return(
      <StepThreeView
        gradesResponse={gradesResponse}
        updateViewCallback={(step)=> this.props.updateViewCallback(step)}
        getGradesCallback={()=> this.props.getGradesCallback()}
        updateGradesCallback={(params)=> this.props.updateGradesCallback(params)}
        UpdateStakeHolderConfiguredCallback={(params)=> this.props.UpdateStakeHolderConfiguredCallback(params)}
        showOrHideModel= {()=> this.props.showOrHideModel()}
        showOrHideReviewModal= {() => this.props.showOrHideReviewModal()}
      />
    )
  }

  /***************************
   * LIFECYCLE
   ***************************/
  render(){
    const { activeStep } = this.props;

    return(
      <div className="right-side-panel">
        { (activeStep === STEP_ONE) && this.getStepOneView() }

        { (activeStep === STEP_TWO) && this.getStepTwoView() }

        { (activeStep === STEP_THREE) && this.getStepThreeView() }
      </div>
    );
  };

}

export default translate(['translations'], translateOptions)(connect(null)(RightSidePanel));