import React, { Component } from 'react';

class SuccessMessageView extends Component {

  render(){
    return(
      <div className='success-message-view'>{this.props.message}</div>
    )
  };

};

export default SuccessMessageView;