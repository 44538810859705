import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../i18n/config';
import { scrollToTop } from '../../utils/misc-utils';
import { requestStakeHolders, requestAnalysisTypeSelected, requestBusinessCriterias, requestClearRespondentValues,
  requestGetListOfRespondents, requestValidateStakeholderAndCriteriaConfig,requestEditRespondent } from '../../actions/app-actions';
import SelectStakeHolderForQuestionnaire from './stakeholder-input-screen/stakeholder-input-screen';
import SelectBuisnessCriteriaForQuestionnaire from './business-criteria-input-screen/business-criteria-input-screen';
import {getActiveOrg} from "../../helpers/auth-helpers";
import AlertModalView from "../common/alert-modal";

class QuestionnaireView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.state={
      org_id: getActiveOrg(userSession),
      selectedAnalysisTypeForQuestionnaire: false,
      chooseRespondantError: null
    };
  };

  /***************************
   * METHODS
   ***************************/
  init() {
    localStorage.setItem('currentRespondentId','');
    this.props.requestClearRespondentValues();
    const params = {
      org_id: this.state.org_id
    }
    this.getrequestValidateStakeholderAndCriteriaConfig();
    this.props.requestGetListOfRespondents(params);
  }

  getStakeHolders() {
    const { org_id } = this.state;
    const params = {
      org_id: org_id
    }
    this.props.requestStakeHolders(params);
  }

  getBusinessCriterias() {
    const { org_id } = this.state;
    const params = {
      org_id: org_id
    }
    this.props.requestBusinessCriterias(params);
  }

  updateAnalysisTypeSelected(params, type) {
    const { org_id } = this.state;
    this.props.requestAnalysisTypeSelected(params, type, org_id);
  }

  requestEditRespondent(params) {
    const { org_id } = this.state;
    this.props.requestEditRespondent(params, org_id);
  }

  getSelectedAnalysisType() {
    const { selectedSTakeHolderResponse } = this.props; 
    if((selectedSTakeHolderResponse &&
      selectedSTakeHolderResponse.selectedSTakeHolderResponse &&
      selectedSTakeHolderResponse.isError) && (!this.state.chooseRespondantError)) {
      this.setState({chooseRespondantError: selectedSTakeHolderResponse.selectedSTakeHolderResponse});
    } else if((selectedSTakeHolderResponse && selectedSTakeHolderResponse.selectedSTakeHolderResponse && !selectedSTakeHolderResponse.isError) &&
      !this.state.selectedAnalysisTypeForQuestionnaire) {
      this.setState({selectedAnalysisTypeForQuestionnaire: true});
    }
  }

  getrequestValidateStakeholderAndCriteriaConfig = () => {
    let params = {};
    const { org_id } = this.state;
    params['org_id'] = org_id;
    this.props.requestValidateStakeholderAndCriteriaConfig(params);
  }

  /***************************
   * VIEWS
   ***************************/
  alertModalView(openvalue) {
    return (<AlertModalView 
      size={'small'}
      open={openvalue}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      onClose={()=> this.handleModalClose()}
      message={'Please publish topics in admin before  proceeding'}
      redirectTo={'/analysis-types'}
      continueMessage={'Back'}
      blockProceed={true}
      classnames={'alert-block-modal'}/>
    )
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount() {
    scrollToTop();
    this.init();
  }

  componentDidUpdate() {
    this.getSelectedAnalysisType();
  }

  render(){
    const { stakeHolderResponse, listOfRespondents, businessCriteriaResponse, analysisTypesConfigured} = this.props
    let showLoader = true;
    if (this.props.match.params.analysisType === "stakeholder" && stakeHolderResponse) {
      showLoader = false;
    } else if (this.props.match.params.analysisType === "business-criteria" && businessCriteriaResponse) {
      showLoader = false;
    }
    return(
      <div className="questionnaire-wrapper">
        <div className={ showLoader ? "ui segment" : "" }>
          <div className={ showLoader ? "empty-div" : "" }></div>
          <div className={ showLoader ? "ui active inverted dimmer" : "ui inverted dimmer"}>
            <div className="ui large text loader">Loading</div>
          </div>
          {
            analysisTypesConfigured &&
            !analysisTypesConfigured.analysisTypesConfigured.are_topics_published &&
            this.alertModalView(true)
          }
          {
            !this.state.selectedAnalysisTypeForQuestionnaire  &&
            this.props.match.params.analysisType === "stakeholder" &&
            listOfRespondents &&
            <SelectStakeHolderForQuestionnaire 
              stakeHolderResponse= {stakeHolderResponse}
              getStakeHolderCallBack= {()=> this.getStakeHolders()}
              respondentList= {listOfRespondents.listOfRespondents.STAKEHOLDER}
              updateAnalysisTypeSelectedCallBack = {(params, type)=> this.updateAnalysisTypeSelected(params, type)}
              chooseRespondantError = {this.state.chooseRespondantError}
              requestEditRespondent= {(params)=> this.requestEditRespondent(params)}
            />
          }
          {
            !this.state.selectedAnalysisTypeForQuestionnaire &&
            this.props.match.params.analysisType === "business-criteria" &&
            listOfRespondents &&
            <SelectBuisnessCriteriaForQuestionnaire
              businessCriteriaResponse = {this.props.businessCriteriaResponse}
              getBusinessCriteriasCallBack = {()=> this.getBusinessCriterias()}
              respondentList= {listOfRespondents.listOfRespondents.BUSINESS_CRITERIA}
              updateAnalysisTypeSelectedCallBack = {(params, type)=> this.updateAnalysisTypeSelected(params, type)}
              chooseRespondantError = {this.state.chooseRespondantError}
              requestEditRespondent= {(params)=> this.requestEditRespondent(params)}
            />
          }
          {
            this.state.selectedAnalysisTypeForQuestionnaire &&
            this.props.history.push(`/${this.props.match.params.analysisType}/questionnaire/question`)
          }
        </div>
      </div>
    );
  };
}

function mapStateToProps(state) {
  return {
    userSession: state.auth.userSession,
    stakeHolderResponse: state.app.stakeHolderResponse,
    selectedSTakeHolderResponse: state.app.selectedSTakeHolderResponse,
    businessCriteriaResponse: state.app.businessCriteriaResponse,
    listOfRespondents: state.app.listOfRespondents,
    analysisTypesConfigured: state.app.analysisTypesConfigured,
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    requestStakeHolders: requestStakeHolders,
    requestAnalysisTypeSelected: requestAnalysisTypeSelected,
    requestBusinessCriterias: requestBusinessCriterias,
    requestClearRespondentValues: requestClearRespondentValues,
    requestGetListOfRespondents: requestGetListOfRespondents,
    requestValidateStakeholderAndCriteriaConfig: requestValidateStakeholderAndCriteriaConfig,
    requestEditRespondent: requestEditRespondent
  },dispatch)
}

export default translate(['translations'], translateOptions)(connect(mapStateToProps, mapDispatchToProps)(QuestionnaireView));