import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../i18n/config';
import { getActiveOrg } from "../../helpers/auth-helpers";
import { scrollToTop } from '../../utils/misc-utils';
import StrategyAndReportView from './strategy-reports-view';

class VersionedStrategyAndReportView extends Component {
  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.state = {
      org_id: getActiveOrg(userSession),
    }
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount() {
    scrollToTop();
  }

  render() {
    return(
      <div className="versioned-results-wrapper">
        <StrategyAndReportView
          versioned={true}
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    userSession: state.auth.userSession,
  };
}

export default translate(['translations'], translateOptions)(connect(mapStateToProps)(VersionedStrategyAndReportView));