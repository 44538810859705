import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../i18n/config';
import { withRouter } from 'react-router-dom';
import { Modal } from 'semantic-ui-react';
import { isEqual } from 'lodash';

import LoginView from './login-view/login-view';
import RegisterView from './register-view/register-view';
import RegisterSuccessView from './register-success-view/register-success-view';
import ForgotPasswordView from './forgot-password-view/forgot-password-view';
import PasswordResetLinkSentView from './password-reset-link-sent-view/password-reset-link-sent-view';
import EmptyModalView from './empty-modal-view/empty-modal-view';

import {
  LOGIN_VIEW,
  FORGOT_PASSWORD_VIEW,
  LINK_SENT_SUCCESS_VIEW,
  REGISTER_SUCCESS_VIEW,
  REGISTER_VIEW
} from '../../constants/misc';
import {
  requestClearAuthStates,
  requestLogin,
  requestLogout,
  requestRegister,
  requestResetPassword
} from '../../actions/auth-actions';
import {
  isUserActive,
  isUserSessionActive
} from '../../helpers/auth-helpers';
import { getQueryParamValue } from '../../utils/string-utils';

class AuthModule extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    this.state={
      open: false,
      modalView: ""
    };

    this.close = this.close.bind(this);
  }

  /***************************
   * METHODS
   ***************************/
  show = (modalView)=> {
    this.setState({ open: true, modalView: modalView});
  }

  close = ()=> {
    this.setState({ open: false });
  }

  updateView = (view)=> {
    setTimeout(()=>{
      this.setState({modalView: view});
    }, 0);
  }

  login = (params)=> {
    this.props.requestLogin(params);
  }

  register = (params)=> {
    this.props.requestRegister(params);
  }

  resetPassword = (params)=> {
    this.props.requestResetPassword(params);
  }

  logout = () => {
    this.props.requestLogout();
  }

  clearAuthStates = () => {
    this.props.requestClearAuthStates();
  }

  /***************************
   * VIEWS
   ***************************/
  getLoginView = ()=> {
    return(
      <LoginView
        onRef={ref => (this.loginView = ref)}
        onRegisterLinkClickCallback={()=> this.updateView(REGISTER_VIEW)}
        onForgotPasswordLinkClickCallback={()=> this.updateView(FORGOT_PASSWORD_VIEW)}
        onFormSubmitCallback={(params)=> this.login(params)}
        requestClearAuthStatesCallback={()=> this.clearAuthStates()}
      />
    )
  }

  getRegisterView = ()=> {
    return(
      <RegisterView
        onRef={ref => (this.registerView = ref)}
        onLoginLinkClickCallback={()=> this.updateView(LOGIN_VIEW)}
        onFormSubmitCallback={(params)=> this.register(params)}
        requestClearAuthStatesCallback={()=> this.clearAuthStates()}
      />
    )
  }

  getForgotPasswordView = ()=> {
    return(
      <ForgotPasswordView
        onRef={ref => (this.forgotPasswordView = ref)}
        onLoginLinkClickCallback={()=> this.updateView(LOGIN_VIEW)}
        onFormSubmitCallback={(params)=> this.resetPassword(params)}
        requestClearAuthStatesCallback={()=> this.clearAuthStates()}
      />
    )
  }

  getLinkSentSuccessView = ()=> {
    return(
      <PasswordResetLinkSentView
        onLoginLinkClickCallback={()=> this.updateView(LOGIN_VIEW)}
      />
    )
  }

  getRegisterSuccessView=() => {
    return(
      <RegisterSuccessView
        onLoginLinkClickCallback={()=> this.updateView(LOGIN_VIEW)}
      />
    )
  }

  getEmptyModalView = ()=> {
    return(
      <EmptyModalView
        onLoginLinkClickCallback={()=> this.updateView(LOGIN_VIEW)}
      />
    )
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount() {
    const {search} = this.props.location;
    const navigateTo = getQueryParamValue(search, 'ref');
    this.props.onRef(this);

    if (navigateTo !== null && navigateTo === 'login')
      if (!isUserActive())
        this.show(LOGIN_VIEW);
  };

  componentWillReceiveProps(newProps){
    const {search} = newProps.location;
    const navigateTo = getQueryParamValue(search, 'view');
    if (isUserSessionActive(newProps.userSession) !== isUserSessionActive(this.props.userSession)){
      this.close();
      setTimeout(()=> {
        if (navigateTo)
          this.props.history.push(`/${navigateTo}`);
        else
          this.props.history.push(`/`);
      }, 100)
    } else if (!isEqual(newProps.loginResponse, this.props.loginResponse)){
      if (newProps.loginResponse && newProps.loginResponse.isError){
        this.loginView.populateLoginError(newProps.loginResponse.message);
        setTimeout(()=> {
          this.clearAuthStates()
        }, 3000)
      }
    }

    if (!isEqual(newProps.registerResponse, this.props.registerResponse)){
      if (newProps.registerResponse) {
        if (newProps.registerResponse.isSuccess){
          this.updateView(REGISTER_SUCCESS_VIEW);
        } else {
          this.registerView.populateLoginError(newProps.registerResponse.registerResponse);
          setTimeout(()=> {
            this.clearAuthStates()
          }, 3000)
        }
      }
    }

    if (!isEqual(newProps.resetPasswordResponse, this.props.resetPasswordResponse))
      if (newProps.resetPasswordResponse && newProps.resetPasswordResponse.isSuccess)
        this.updateView(LINK_SENT_SUCCESS_VIEW);
  };

  componentWillUnmount() {
    this.props.onRef(undefined);
  };

  render(){
    const { open, modalView } = this.state;

    return(
      <Modal className="auth-module-view" open={open} onClose={this.close}>
        <Modal.Content>
          { (modalView === LOGIN_VIEW) && this.getLoginView() }

          { (modalView === REGISTER_VIEW) && this.getRegisterView() }

          { (modalView === FORGOT_PASSWORD_VIEW) && this.getForgotPasswordView() }

          { (modalView === LINK_SENT_SUCCESS_VIEW) && this.getLinkSentSuccessView() }

          { (modalView === REGISTER_SUCCESS_VIEW) && this.getRegisterSuccessView() }

          { (modalView === "") && this.getEmptyModalView() }
        </Modal.Content>
      </Modal>
    );
  };
}



function mapStateToProps(state) {
  return {
    userSession: state.auth.userSession,
    loginResponse: state.auth.loginResponse,
    resetPasswordResponse: state.auth.resetPasswordResponse,
    registerResponse: state.auth.registerResponse,
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    requestLogin: requestLogin,
    requestRegister: requestRegister,
    requestLogout: requestLogout,
    requestResetPassword: requestResetPassword,
    requestClearAuthStates: requestClearAuthStates
  },dispatch)
}

export default withRouter(translate(['translations'], translateOptions)(connect(mapStateToProps, mapDispatchToProps)(AuthModule)));