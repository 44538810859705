import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import { Container, Button, Form, Dimmer, Loader } from 'semantic-ui-react';
import { translateOptions } from '../../../i18n/config';
import { scrollToTop } from '../../../utils/misc-utils';
import EmptyStateView from '../../common/empty-state-view';
import BuisnessCriteriaListView from './business-criteria-list/business-criteria-list';
import ChooseRespondentModal from '../questionnaire-modal-component/input-screen-modal';
import ErrorMessageView from '../../common/error-message-view';

class SelectBuisnessCriteriaForQuestionnaire extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);
    this.state = {
      selectedCriteria: null,
      businessCriteriaName: '',
      businessCriterisDescription: '',
      openChooseRespondent: false,
      error: false,
      chooseRespondantError: null,
      existingCriteriaSelected: false,
      isLoading: false
    }
    this.handleOnChange = this.handleOnChange.bind(this);
  };

  /***************************
   * METHODS
   ***************************/
  handleFormSubmit(){
    if (this.vaidateSubmit()) {
      const params = {
        'business_criteria': this.state.selectedCriteria,
        name: this.state.businessCriteriaName,
        description: this.state.businessCriterisDescription,
      }
      this.setState({isLoading: true});
      if (!this.state.existingCriteriaSelected) {
        this.props.updateAnalysisTypeSelectedCallBack(params, {type: 'business-criteria'});
      }
      else {
        params.respondent_id = this.state.selectedRespondentId;
        this.props.requestEditRespondent(params)
      }
    } else {
      this.setState({error: true});
    }
  }

  handleOnChange(id){
    if(this.state.error) {
      this.setState({error: false});
    }
    this.setState({selectedCriteria: id, existingCriteriaSelected: false});
  }

  handleNameOnChange(e) {
    const value = e.target.value;
    if(this.state.error) {
      this.setState({error: false});
    }
    this.setState({'businessCriteriaName': value, chooseRespondantError: null});
  }

  handleDescriptionOnChange(e) {
    const value = e.target.value;
    if(this.state.chooseRespondantError) {
      this.setState({chooseRespondantError: null});
    }
    if(this.state.error) {
      this.setState({error: false});
    }
    this.setState({'businessCriterisDescription': value});
  }

  vaidateSubmit() {
    if (this.state.selectedCriteria && this.state.businessCriteriaName && this.state.businessCriterisDescription) {
      return true;
    }
    else {
      return false;
    }
  }

  handleModalOpen() {
    this.setState({openChooseRespondent: true});
  }

  handleModalClose(value) {
    this.setState({openChooseRespondent: value});
  }

  respondentSelected(respondent) {
    this.setState({
      businessCriteriaName: respondent.name,
      businessCriterisDescription: respondent.description,
      selectedCriteria: respondent.business_criteria,
      existingCriteriaSelected: true,
      selectedRespondentId: respondent.id,
      error: false,
      chooseRespondantError: null
    })
  }

  /***************************
   * VIEWS
   ***************************/
  getCriteriaView = (businessCriteria, index) => {
    const elementId = `key_${businessCriteria.name}_${index}`;
    return(
      <BuisnessCriteriaListView
        key={ elementId }
        index={ index }
        businessCriteria={ businessCriteria }
        updateSelectedCriteriaChange={this.handleOnChange}
        defaultSelectedCriteria={this.state.selectedCriteria === businessCriteria.id ? true:false}
      />
    )
  }

  getEmptyStateView = () => {
    const { t } = this.props;
    return(
      <EmptyStateView message={t('businessAbilityPage.businessCriteriaEmptyStateMsg')} />
    )
  }

  getChooseRespondantModal() {
    return (
      <ChooseRespondentModal
        open={true}
        size={'tiny'}
        respondentList={this.props.respondentList}
        handleModalCloseCallback={(value)=>this.handleModalClose(value)}
        respondentSelected={(respondent)=>this.respondentSelected(respondent)}
      />
    )
  }
  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount() {
    scrollToTop();
    this.props.getBusinessCriteriasCallBack();
  }

  componentDidUpdate() {
    if(this.state.chooseRespondantError || this.state.error) {
      scrollToTop();
    }
  }

  componentWillReceiveProps(newProps) {
    if(newProps.chooseRespondantError) {
      this.setState({chooseRespondantError: newProps.chooseRespondantError, isLoading: false});
    } else if (this.state.chooseRespondantError && !newProps.chooseRespondantError) {
      this.setState({chooseRespondantError: null});
    }
  }

  render(){
    const { t } = this.props;
    const { businessCriteriaResponse } = this.props;
    return(
      <Form className="ui form" onSubmit={(e) => this.handleFormSubmit(e)}>
        {
          this.state.isLoading &&
          <Dimmer active inverted>
            <Loader>Loading</Loader>
          </Dimmer>
        }
        <Container className='select-stake-holder-wrapper'>
          <div className="small-header-container">
            <div className='small-header'>
              {t('questionnaireSelectBuisnessCriteria.criteriaHeadingSmall')}
            </div>
            <Link to={`/stakeholder/questionnaire`}>
              <div className="toggle-questionnaire">
                {t('questionnaireSelectBuisnessCriteria.stakeholderQuestionnaire')}
              </div>
            </Link>
          </div>
          <div className="large-header-container">
            <div className="large-header">
              {t('questionnaireSelectBuisnessCriteria.criteriaHeadingLarge')}
            </div>
            {
              this.props.respondentList && this.props.respondentList.length !==0 &&
              <div className="choose-respondent toggle-questionnaire" onClick={()=>this.handleModalOpen()}>
                {t('questionnaireSelectBuisnessCriteria.chooseRespondent')}
              </div>
            }
          </div>
          {
            this.state.error &&
            <ErrorMessageView error={t('questionnaireSelectBuisnessCriteria.incompleteFormError')}/>
          }
          {
            this.state.chooseRespondantError &&
            <ErrorMessageView error={this.state.chooseRespondantError}/>
          }
          <div className="ui input fluid">
            <input type="text" value={this.state.businessCriteriaName} placeholder={t('questionnaireSelectBuisnessCriteria.criteriaInputNameField')} onChange={(e)=> this.handleNameOnChange(e)}/>
          </div>
          <div className="grouped fields">
            { 
              businessCriteriaResponse && (businessCriteriaResponse.businessCriteriaResponse.length > 0) ?
              businessCriteriaResponse.businessCriteriaResponse.map((businessCriteria, index)=> (this.getCriteriaView(businessCriteria, index)) )
              : this.getEmptyStateView() 
            }
          </div>
          <div className="ui input fluid">
            <textarea rows='3' type="text" value={this.state.businessCriterisDescription} placeholder={t('questionnaireSelectBuisnessCriteria.criteriaDescribePlaceholder')} onChange={(e)=> this.handleDescriptionOnChange(e)}/>
          </div>
          <div className='form-footer'>
            <Button className='orange-solid-btn questionnaire-btn' type="submit">
              {t('questionnaireSelectBuisnessCriteria.footerButton')}
            </Button>
          </div>
          {
            this.state.openChooseRespondent &&
            this.getChooseRespondantModal()
          }
        </Container>
      </Form>
    );
  };
}

function mapStateToProps(state) {
  return {
    userSession: state.auth.userSession,
  };
}

export default translate(['translations'], translateOptions)(connect(mapStateToProps)(SelectBuisnessCriteriaForQuestionnaire));