import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../i18n/config';

import { Button } from 'semantic-ui-react';

class PasswordResetLinkSentView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    this.state={

    };
  }

  /***************************
   * METHODS
   ***************************/
  showLoginView = ()=> {
    this.props.onLoginLinkClickCallback();
  };


  /***************************
   * VIEWS
   ***************************/
  render(){
    const { t } = this.props;
    return(
      <div className="password-reset-link-sent-view">
        <div className="heading">{t('auth.linkSent')}</div>
        <div className="form-text">{t('auth.linkSentSuccessMsg')}</div>
        <div className="btn-wrapper">
          <Button className="app-btn" type='submit' onClick={()=> this.showLoginView()}>
            {t('auth.backToLogin')}
          </Button>
        </div>
      </div>
    );
  };
}

export default translate(['translations'], translateOptions)(PasswordResetLinkSentView);