import React, { Component } from 'react';

class ErrorMessageView extends Component {

  render(){
    return(
      <div className='error-message-view'>{this.props.error}</div>
    )
  };

};

export default ErrorMessageView;