import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { translateOptions } from '../../i18n/config';

import {
  BUSINESS_POINT_ONE, BUSINESS_POINT_THREE, BUSINESS_POINT_TWO,
  HERO_BANNER, MATERIALITY_PROCESS
} from '../../constants/images';
import {
  MATERIALITY_STEP_FIVE,
  MATERIALITY_STEP_FOUR, MATERIALITY_STEP_ONE, MATERIALITY_STEP_SIX, MATERIALITY_STEP_THREE,
  MATERIALITY_STEP_TWO
} from '../../constants/materiality-steps';
import { scrollToTop } from '../../utils/misc-utils';
import * as Sentry from '@sentry/browser';

class LandingPageView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    this.state={
      activeMaterialityStep: MATERIALITY_STEP_ONE
    };
  };

  /***************************
   * METHODS
   ***************************/
  setActiveMaterialityStep(activeMaterialityStep){
    this.setState({activeMaterialityStep});
  };

  /***************************
   * VIEWS
   ***************************/
  getHeroBannerContentView(){
    const { t } = this.props;
    return(
      <Container className="relative-position">
        <div className="banner-content-wrapper">
          <div className="banner-heading">
            <p className="small-heading">{t('common.brand')}</p>
            <p className="big-heading">{t('common.materialityProcess')}</p>
          </div>
          <div className="banner-content-para">{t('landingPage.bannerText')}</div>
          <div className="contact-us-btn-wrapper">
            <a href="mailto:info@purple-ivy.se" target="_blank">
              <div className="contact-us-btn">
                <div className="btn-text">{t('common.contactUs')}</div>
                <div className="icon-arrow-icon"></div>
              </div>
            </a>
          </div>
        </div>
        <div className="business-key-points-wrapper">
          <img className="business-key-image" src={BUSINESS_POINT_ONE} alt="" />
          <img className="business-key-image" src={BUSINESS_POINT_TWO} alt="" />
          <img className="business-key-image" src={BUSINESS_POINT_THREE} alt="" />
        </div>
      </Container>
    )
  };

  getSectionOneView(){
    const { t } = this.props;
    return(
      <div className="section-one-content">
        <Container className="content-wrapper">
          <div className="section-heading">{t('landingPage.sectionOneHeading')}</div>
          <ul className="key-point-collection unordered-list">
            <li className="list-item key-point">{t('landingPage.sectionOnePoint1')}</li>
            <li className="list-item key-point">{t('landingPage.sectionOnePoint2')}</li>
            <li className="list-item key-point">{t('landingPage.sectionOnePoint3')}</li>
          </ul>
        </Container>
      </div>
    )
  };

  getSectionThreeView(){
    const { t } = this.props;
    return(
      <div className="section-two-content">
        <Container className="content-wrapper">
          <div className="section-heading">{t('landingPage.sectionTwoHeading')}</div>
          <div className="section-content">
            <div className="section-text">{t('landingPage.sectionTwoContent')}</div>
            <div className="section-banner-header">{t('landingPage.sectionTwoBannerHeading')} </div>
            <div className="section-banner-wrapper">
              <img className="section-banner" src={MATERIALITY_PROCESS} alt={t('common.materialityProcess')} />
              <div className="steps-text-wrapper">
                <div className="step one" onClick={()=> this.setActiveMaterialityStep(MATERIALITY_STEP_ONE)}>
                  {t('materialityProcessSteps.step1')}
                </div>
                <div className="step two" onClick={()=> this.setActiveMaterialityStep(MATERIALITY_STEP_TWO)}>
                  {t('materialityProcessSteps.step2')}
                </div>
                <div className="step three" onClick={()=> this.setActiveMaterialityStep(MATERIALITY_STEP_THREE)}>
                  {t('materialityProcessSteps.step3')}
                </div>
                <div className="step four" onClick={()=> this.setActiveMaterialityStep(MATERIALITY_STEP_FOUR)}>
                  {t('materialityProcessSteps.step4')}
                </div>
                <div className="step five" onClick={()=> this.setActiveMaterialityStep(MATERIALITY_STEP_FIVE)}>
                  {t('materialityProcessSteps.step5')}
                </div>
                <div className="step six" onClick={()=> this.setActiveMaterialityStep(MATERIALITY_STEP_SIX)}>
                  {t('materialityProcessSteps.step6')}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    )
  };

  getMaterialityStepView(){
    const { activeMaterialityStep } = this.state;
    return(
      <Link to={activeMaterialityStep.url}>
        <div className="materiality-process-step">
          <div className="step-icon">
            <div className={activeMaterialityStep.icon} />
          </div>
          <div className="step-content">
            <div className="heading">{activeMaterialityStep.heading}</div>
            <div className="content">{activeMaterialityStep.content}</div>
          </div>
        </div>
      </Link>
    )
  };

  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount() {
    scrollToTop();
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render(){
    const { t } = this.props;
    return(
      <div className="landing-page-view">
        <div className="hero-banner-wrapper">
          { this.getHeroBannerContentView() }
          <img className="hero-banner" src={HERO_BANNER} alt={t('common.materialityProcess')} />
        </div>
        <div className="section-one">
          {this.getSectionOneView()}
        </div>
        <div className="section-two">
          {this.getSectionThreeView()}
        </div>
        <div className="section-three">
          <Container>
            { this.getMaterialityStepView() }
          </Container>
        </div>
      </div>
    );
  };
}

export default translate(['translations'], translateOptions)(connect(null)(LandingPageView));