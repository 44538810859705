import ActionTypes from '../constants/action-type';
import { getErrorMessage } from '../utils/string-utils';
import {
  addStakeHolder, deleteStakeHolder, getGrades,
  getStakeHolders, updateGrades, updateStakeHolder,
  getBusinessCriterias, addBusinessCriterion, deleteBusinessCriterion, updateBusinessCriterion,
  getBusinessCriterionStrategyPeriod, updateBusinessCriterionStrategyPeriod, getOrganisationDetails,
  UpdateSelectedStrategyPeriod, UpdateBusinessCriteriaConfigured, UpdateStakeHolderConfigured,
  getAnalyseDrivers, UpdateAnalysisTypeSelected, getListOfTopics, getValidateStakeholderAndCriteriaConfig,
  getQuestionnaireQuestion, updateQuestionnaireQuestion, getTopicCategories, getQuestionnaireGradePoints,
  getEditWeightageValues, updateEditWeightageValues, getPreviewQuestionnaireDetails,
  updatEditPreviewQuestionAnswer, getStrategyReportValues, getListOfRespondents,
  getValueChainAndBoundaries, getStakeholderInterviews, getSelectedStrategyPeriod,
  updateLastVisitedForStrategyReports, getStrategyReportFiles, getOrgVersions, editRespondent,
  verifyEmail, getRespondentUsingId
} from '../utils/app-api-utils';

export function requestStakeHolders(params){
  return(dispatch) => {
    getStakeHolders(dispatch, params);
  }
}

export function receiveStakeHoldersResponse(response){
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_STAKE_HOLDER_RESPONSE,
    response
  }
}

export function requestAddStakeHolders(params){
  return(dispatch) => {
    addStakeHolder(dispatch, params);
  }
}

export function receiveAddStakeHoldersResponse(response){
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_ADD_STAKE_HOLDER_RESPONSE,
    response
  }
}

export function requestDeleteStakeHolders(params){
  return(dispatch) => {
    deleteStakeHolder(dispatch, params);
  }
}

export function receiveDeleteStakeHoldersResponse(response, deletedStakeholderID){
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
    response.deletedStakeholderID = deletedStakeholderID;
  }
  return{
    type: ActionTypes.RECEIVE_DELETE_STAKE_HOLDER_RESPONSE,
    response
  }
}

export function requestUpdateStakeHolders(params){
  return(dispatch) => {
    updateStakeHolder(dispatch, params);
  }
}

export function receiveUpdateStakeHoldersResponse(response){
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_UPDATE_STAKE_HOLDER_RESPONSE,
    response
  }
}

export function requestGradess(params){
  return(dispatch) => {
    getGrades(dispatch, params);
  }
}

export function receiveGradesResponse(response){
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_GRADES_RESPONSE,
    response
  }
}

export function requestUpdateGrades(params){
  return(dispatch) => {
    updateGrades(dispatch, params);
  }
}

export function receiveUpdateGradesResponse(response){
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_UPDATE_GRADES_RESPONSE,
    response
  }
}

export function requestClearStakeHolderViewStates() {
  return{
    type: ActionTypes.CLEAR_STAKEHOLDER_VIEW_STATES
  }
}

//
export function requestBusinessCriterias(params){
  return(dispatch) => {
    getBusinessCriterias(dispatch, params);
  }
}

export function receiveBusinessCriteriasResponse(response){
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_BUSINESS_CRITERIA_RESPONSE,
    response
  }
}

export function requestAddBusinessCriterion(params){
  return(dispatch) => {
    addBusinessCriterion(dispatch, params);
  }
}

export function receiveAddBusinessCriterionResponse(response){
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_ADD_BUSINESS_CRITERIA_RESPONSE,
    response
  }
}

export function requestDeleteBusinessCriterion(params){
  return(dispatch) => {
    deleteBusinessCriterion(dispatch, params);
  }
}

export function receiveDeleteBusinessCriterionResponse(response, deletedBusinessCriteriaID){
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
    response.deletedBusinessCriteriaID = deletedBusinessCriteriaID;
  }
  return{
    type: ActionTypes.RECEIVE_DELETE_BUSINESS_CRITERIA_RESPONSE,
    response
  }
}

export function requestUpdateBusinessCriterion(params){
  return(dispatch) => {
    updateBusinessCriterion(dispatch, params);
  }
}

export function receiveUpdateBusinessCriterionResponse(response){
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_UPDATE_BUSINESS_CRITERIA_RESPONSE,
    response
  }
}

export function requestBusinessCriterionStrategyPeriod(params){
  return(dispatch) => {
    getBusinessCriterionStrategyPeriod(dispatch, params);
  }
}

export function receiveBusinessCriterionStrategyPeriodResponse(response){
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_BUSINESS_CRITERIA_STRATEGY_PERIOD_RESPONSE,
    response
  }
}

export function requestUpdateBusinessCriterionStrategyPeriod(params){
  return(dispatch) => {
    updateBusinessCriterionStrategyPeriod(dispatch, params);
  }
}

export function receiveUpdateBusinessCriterionStrategyPeriodResponse(response){
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_UPDATE_BUSINESS_CRITERIA_STRATEGY_PERIOD_RESPONSE,
    response
  }
}

export function requestClearBusinessCriterionViewStates() {
  return{
    type: ActionTypes.CLEAR_BUSINESS_CRITERIA_VIEW_STATES
  }
}

export function requestOrganisationDetails(params) {
  return(dispatch) => {
    getOrganisationDetails(dispatch, params);
  }
}

export function recieveOragnisationDetails(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_ORGANISATION_DETAILS_RESPONSE,
    response
  }
}

export function requestOrgVersions(params) {
  return(dispatch) => {
    getOrgVersions(dispatch, params);
  }
}

export function receiveOrgVersionsResponse(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_ORGANISATION_VERSIONS_RESPONSE,
    response
  }
}
export function requestGetSelectedBusinessCriterionStrategyPeriod(params) {
  return(dispatch) => {
    getSelectedStrategyPeriod(dispatch, params);
  }
}

export function requestUpdateSelectedStrategyPeriod(params, org_id) {
  return(dispatch) => {
    UpdateSelectedStrategyPeriod(dispatch, params, org_id);
  }
}

export function receiveUpdateSelectedStrategyPeriodResponse(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_UPDATE_STRATEGY_PERIOD_RESPONSE,
    response
  }
}

export function requestUpdateStakeHolderConfigured(params, org_id) {
  return(dispatch) => {
    UpdateStakeHolderConfigured(dispatch, params, org_id);
  }
}

export function recieveUpdateStakeHolderConfigured(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_VALIDATE_CONFIGS_FOR_QUESTIONNAIRE,
    response
  }
}

export function requestUpdateBusinessCriteriaConfigured(params, org_id) {
  return(dispatch) => {
    UpdateBusinessCriteriaConfigured(dispatch, params, org_id);
  }
}

export function recieveUpdateBusinessCriteriaConfigured(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_VALIDATE_CONFIGS_FOR_QUESTIONNAIRE,
    response
  }
}

export function requestAnalyseDrivers(params) {
  return(dispatch) => {
    getAnalyseDrivers(dispatch, params);
  }
}

export function receiveAnalyseDriversResponseResponse(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_ANALYSE_DRIVERS_RESPONSE,
    response
  }
}

export function requestStakeholderInterviews(params) {
  return(dispatch) => {
    getStakeholderInterviews(dispatch, params);
  }
}

export function recieveStakeholderInterviewsResponse(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_STAKEHOLDER_INTERVIEWS_RESPONSE,
    response
  }
}

export function requestValueChainAndBoundaries(params) {
  return(dispatch) => {
    getValueChainAndBoundaries(dispatch, params);
  }
}

export function recieveValueChainAndBoundariesResponse(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_VALUE_CHAIN_BOUNDARIES_RESPONSE,
    response
  }
}

export function requestGetStrategyReportFiles(params) {
  return(dispatch) => {
    getStrategyReportFiles(dispatch, params);
  }
}

export function recieveStrategyReportFiles(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_STRATEGY_AND_REPORTS_FILES_RESPONSE,
    response
  }
}

export function requestAnalysisTypeSelected(params, type, org_id) {
  return(dispatch) => {
    UpdateAnalysisTypeSelected(dispatch, params, type, org_id)
  }
}

export function recieveUpdateAnalysisTypeSelectedResponse(response) {
  if (response.data.hasOwnProperty('success') && !response.data.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.data.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_SELECTED_STAKEHOLDER_RESPONSE,
    response
  }
}

export function requestListOfTopics(org_id) {
  return(dispatch) => {
    getListOfTopics(dispatch, org_id)
  }
}

export function recieveListOfTopicsResponse(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_LIST_OF_TOPICS_RESPONSE,
    response
  }
}

export function requestValidateStakeholderAndCriteriaConfig(params) {
  return(dispatch) => {
    getValidateStakeholderAndCriteriaConfig(dispatch, params)
  }
}

export function recieveValidateStakeholderAndCriteriaConfig(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_VALIDATE_CONFIGS_FOR_QUESTIONNAIRE,
    response
  }
}

export function requestGetQuestionnaireQuestion(params, type, categoryId) {
  return(dispatch) => {
    getQuestionnaireQuestion(dispatch, params, type, categoryId)
  }
}

export function recieveQuestionnaireQuestion(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_QUESTIONNAIRE_QUESTION,
    response
  }
}

export function requestupdateQuestionnaireQuestion(params) {
  return(dispatch) => {
    updateQuestionnaireQuestion(dispatch, params)
  }
}

export function recieveupdateQuestionnaireQuestionResponse(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_QUESTIONNAIRE_QUESTION_RESPONSE,
    response
  }
}

export function requestgetTopicCategories(params, type) {
  return(dispatch) => {
    getTopicCategories(dispatch, params, type)
  }
}


export function recieveListOfTopicCategoriesResponse(response){
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_LIST_OF_TOPIC_CATEGORIES_RESPONSE,
    response
  }
}

export function requestQuestionnaireGradePoints(params) {
  return(dispatch) => {
    getQuestionnaireGradePoints(dispatch, params)
  }
}


export function recieveQuestionnaireGradePointsResponse(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_QUESTIONNAIRE_GRADE_POINTS_RESPONSE,
    response
  }
}

export function requestgetEditWeightageValues(params, type) {
  return(dispatch) => {
    getEditWeightageValues(dispatch, params, type);
  }
}

export function recieveGetEditWeightageValuesResponse(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_EDIT_WEIGHTAGE_VALUES_RESPONSE,
    response
  }
}

export function requestUpdateEditWeightageValues(orgParams, postData) {
  return(dispatch) => {
    updateEditWeightageValues(dispatch, orgParams, postData)
  }
}

export function recieveUpdateEditWeightageValuesResponse(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_UPDATE_EDIT_WEIGHTAGE_VALUES_RESPONSE,
    response
  }
}

export function requestGetPreviewQuestionnaireDetails(params) {
  return(dispatch) => {
    getPreviewQuestionnaireDetails(dispatch, params);
  }
}

export function recieveGetPreviewQuestionnaireDetailsResponse(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_PREVIEW_QUESTIONNAIRE_RESPONSE,
    response
  }
}

export function requestEditPreviewQuestionAnswer(params) {
  return(dispatch) => {
    updatEditPreviewQuestionAnswer(dispatch, params);
  }
}

export function recieveEditPreviewQuestionAnswer(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_EDIT_QUESTINNAIRE_ANSWER_RESPONSE,
    response
  }
}

export function requestGetStrategyReportValues(params) {
  return(dispatch) => {
    getStrategyReportValues(dispatch, params);
  }
}

export function recieveGetStrategyReportValues(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_STRATEGY_REPORT_VALUES_RESPONSE,
    response
  }
}

export function requestUpdateLastVisitedForReports(params) {
  return(dispatch) => {
    updateLastVisitedForStrategyReports(dispatch, params);
  }
}

export function recieveLastVisitedForStrategyReports(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_STRATEGY_REPORT_LAST_VISITED_RESPONSE,
    response
  }
}

export function requestClearRespondentValues() {
  return {
    type: ActionTypes.REQUEST_CLEAR_RESPONDENT
  }
}

export function requestGetListOfRespondents(params) {
  return(dispatch) => {
    getListOfRespondents(dispatch, params)
  }
}

export function recieveGetListOfRespondents(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_LIST_OF_RESPONDENTS_RESPONSE,
    response
  }
}

export function clearStakeholderAndCriteriaConfig() {
  return {
    type: ActionTypes.REQUEST_CLEAR_STAKEHOLDER_CRITERIA_CONFIG
  }
}

export function clearPreviewQuestionnaireDetails() {
  return {
    type: ActionTypes.REQUEST_CLEAR_QUESTIONNAIRE_PREVIEW
  }
}

export function requestCleargetTopicCategories() {
  return {
    type: ActionTypes.REQUEST_CLEAR_QUESTIONNAIRE_QUESTIONNAIRE_TOPIC_CATEGORIES
  }
}

export function requestEditRespondent(params, org_id) {
  return(dispatch) => {
    editRespondent(dispatch, params, org_id);
  }
}

export function recieveEditRespondent(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.data.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_EDIT_RESPONDENT,
    response
  }
}

export function requestVerifyEmail(params) {
  return(dispatch) => {
    verifyEmail(dispatch, params);
  }
}

export function receiveVerifyEmailResponse(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_VERIFY_EMAIL,
    response
  }
}

export function requestGetRespondentUsingId(params) {
  return(dispatch) => {
    getRespondentUsingId(dispatch, params);
  }
}

export function recieveGetRespondentUsingIdRespondent(response) {
  if (response.hasOwnProperty('success') && !response.success){
    response.isSuccess = false;
    response.isError = true;
    response.response = getErrorMessage(response.error);
  } else {
    response.isSuccess = true;
    response.isError = false;
    response.response = response.data;
  }
  return{
    type: ActionTypes.RECEIVE_INDIVIDUAL_RESPONDENT,
    response
  }
}

export function resetAnswerQuestionnaireQuestionResponse() {
  return {
    type: ActionTypes.RESET_ANSWER_QUESTIONNAIRE_QUESTION_RESPONSE
  }
}