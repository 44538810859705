import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { translate } from 'react-i18next';
import { Container } from 'semantic-ui-react';
import { translateOptions } from '../../i18n/config';
import { scrollToTop } from '../../utils/misc-utils';
import {
  requestGetPreviewQuestionnaireDetails, requestQuestionnaireGradePoints,
  requestEditPreviewQuestionAnswer, clearPreviewQuestionnaireDetails, requestgetTopicCategories,
  requestUpdateEditWeightageValues, requestgetEditWeightageValues, requestGetRespondentUsingId
} from '../../actions/app-actions';
import {getActiveOrg} from "../../helpers/auth-helpers";
import QuestionnaireQuestionList from './questionnaire-question-list/questionnaire-question-list';
import EmptyStateView from '../common/empty-state-view';
import { isEqual } from 'lodash';

class QuestionnairePreviewView extends Component {
  /***************************
   * CONSTRUCTOR
   ***************************/
  constructor(props) {
    super(props);

    const { userSession } = this.props;
    this.state={
      org_id: getActiveOrg(userSession),
      questionnaireQuestions: null,
      editModeQuestionId: null,
    };
  };

  /***************************
   * METHODS
   ***************************/
  getPreviewDetails() {
    const respondentIdFromStorage = localStorage.getItem('currentRespondentId');
    const { org_id } = this.state;
    if (this.props.selectedSTakeHolderResponse || respondentIdFromStorage) {
      const {selectedSTakeHolderResponse} = this.props;
      const respondentId = selectedSTakeHolderResponse ?
        selectedSTakeHolderResponse.selectedSTakeHolderResponse.id :
        respondentIdFromStorage;

      const params = {
        org_id: org_id,
        respondent_id: respondentId,
      }
      this.props.requestgetTopicCategories(params, this.props.match.params.analysisType);
      params.type = this.props.match.params.analysisType;
      this.props.clearPreviewQuestionnaireDetails()
      this.props.requestGetPreviewQuestionnaireDetails(params);
      this.getAllGradePoints();
    }
    if (respondentIdFromStorage) {
      const params = {
        org_id: org_id,
        respondent_id: respondentIdFromStorage,
      }
      this.props.requestGetRespondentUsingId(params);
    }
  }

  getAllGradePoints() {
    const { org_id } = this.state;
    this.props.requestQuestionnaireGradePoints({org_id: org_id});
  }

  editCallBack(question) {
    if(question) {
      this.setState({editModeQuestionId: question.id});
    } else {
      this.setState({editModeQuestionId: null});
    }
  }

  handleSubmit(params) {
    const respondentIdFromStorage = localStorage.getItem('currentRespondentId');
    params.org_id = this.state.org_id;
    params.respondent_id = respondentIdFromStorage;
    params.type = this.props.match.params.analysisType;
    this.props.requestEditPreviewQuestionAnswer(params);
  }

  updateEditWeightageValues(orgParams, postData) {
    this.props.requestUpdateEditWeightageValues(orgParams, postData);
  }

  getEditWeightageValues(params) {
    const { org_id } = this.state;
    params['org_id'] = org_id;
    const type = this.props.match.params.analysisType;
    this.props.requestgetEditWeightageValues(params, type)
  }

  handlePrint() {
    window.print();
  }

  getRespondentName() {
    const { respondentQuestionnairePreview } = this.props;
    let respondentName = respondentQuestionnairePreview.respondentQuestionnairePreview.name;
    if (respondentQuestionnairePreview.respondentQuestionnairePreview.stakeholder_name){
      respondentName += '(' + respondentQuestionnairePreview.respondentQuestionnairePreview.stakeholder_name + ')'
    } else {
      respondentName += '(' + respondentQuestionnairePreview.respondentQuestionnairePreview.business_criteria_name + ')'
    }
    return respondentName;
  }
  /***************************
   * VIEWS
   ***************************/
  getListOfQuestions(question, index) {
    const elementId = `key_${question.id}_${index}`;
    const openPreviewMode = this.state.editModeQuestionId === question.id ? false : true;
    return (
      <div key= {elementId}>
        {
          !this.state.editModeQuestionId &&
          <QuestionnaireQuestionList 
            question= {question}
            key= {elementId}
            index = {index}
            questionnaireGradePoints = {this.props.questionnaireGradePoints}
            getAllGradePoints = {()=> this.getAllGradePoints()}
            handleSubmit = {(params) => this.handleSubmit(params)}
            editCallBack = {(question) => this.editCallBack(question)}
            analysisType = {this.props.match.params.analysisType}
            updateEditWeightageValues = {(orgParams, postData) => this.updateEditWeightageValues(orgParams, postData)}
            getEditWeightageValues={(params, type) => this.getEditWeightageValues(params,type)}/>
        }
        {
          this.state.editModeQuestionId &&
          <QuestionnaireQuestionList 
          question= {question}
          key= {elementId}
          openPreviewMode = {openPreviewMode}
          index = {index}
          questionnaireGradePoints = {this.props.questionnaireGradePoints}
          getAllGradePoints = {()=> this.getAllGradePoints()}
          handleSubmit = {(params) => this.handleSubmit(params)}
          editCallBack = {(question) => this.editCallBack(question)}
          analysisType = {this.props.match.params.analysisType}
          updateEditWeightageValues = {(orgParams, postData) => this.updateEditWeightageValues(orgParams, postData)}
          getEditWeightageValues={(params, type) => this.getEditWeightageValues(params,type)}/>
        }
      </div>
    );
  }

  getEmptyStateView() {
    const { t } = this.props;
    return(
      <EmptyStateView message={t('questionnaireQuestion.zeroAnswersToPreview')} />
    )
  }

  viewMatrix() {
    this.props.history.push({
      pathname: `/strategy-report`,
      state: { prevPath: 'questionnairePreviewPath' }
    });
  }

  setPreviewQuestionnaireQuestion(previewQuestionnaireQuestion) {
    this.setState({previewQuestionnaireQuestion: previewQuestionnaireQuestion})
  }

  setTopicCategories(questionnaireTopicCategories) {
    this.setState({questionnaireTopicCategories: questionnaireTopicCategories})
  }

  /***************************
   * LIFECYCLE
   ***************************/
  componentDidMount() {
    scrollToTop();
    this.getPreviewDetails();
    if(this.props.previewQuestionnaireQuestion) {
      this.setState({previewQuestionnaireQuestion: this.props.previewQuestionnaireQuestion})
    }
    if(this.props.questionnaireTopicCategories) {
      this.setState({questionnaireTopicCategories: this.props.questionnaireTopicCategories})
    }
  }

  componentWillReceiveProps(newProps) {
    if(!isEqual(newProps.previewQuestionnaireQuestion, this.props.previewQuestionnaireQuestion)) {
      this.setPreviewQuestionnaireQuestion(newProps.previewQuestionnaireQuestion);
    }
    if(!isEqual(newProps.questionnaireTopicCategories, this.props.questionnaireTopicCategories)) {
      this.setTopicCategories(newProps.questionnaireTopicCategories);
    }
  }
  render(){
    const { t, respondentQuestionnairePreview } = this.props;
    const { previewQuestionnaireQuestion, questionnaireTopicCategories } = this.state;
    let headingQuestionnaire;
    let disableViewMatrix = false;
    if (this.props.match.params.analysisType === 'stakeholder') {
      headingQuestionnaire = t('questionnaireQuestion.headingStakeholder');
    } else {
      headingQuestionnaire = t('questionnaireQuestion.headingBusinessCriteria');
    }
    if (!previewQuestionnaireQuestion || (previewQuestionnaireQuestion && previewQuestionnaireQuestion.previewQuestionnaireQuestion.length === 0)) {
      disableViewMatrix = true;
    }
    return(
      <div className="questionnaire-preview-wrapper">
        <div className={ !previewQuestionnaireQuestion ? "ui segment height-fill" : "" }>
          <div className={ !previewQuestionnaireQuestion ? "ui active inverted dimmer" : "ui inverted dimmer"}>
            <div className="ui large text loader">Loading</div>
          </div>
          <Container>
            <div className="heading-wrapper">
              <div className="heading">
                {headingQuestionnaire}
              </div>
              <div className="print-link" onClick={()=>this.handlePrint()}>Print</div>
            </div>
            {
              questionnaireTopicCategories &&
              questionnaireTopicCategories.questionnaireTopicCategories.total_answers !== questionnaireTopicCategories.questionnaireTopicCategories.total_questions &&
              <Link to={`/${this.props.match.params.analysisType}/questionnaire/question`}>
                <div className="back-to-questionnaire">
                  {t('questionnaireQuestion.backToQuestionnaire')}
                </div>
              </Link>
            }
            <Link to={`/${this.props.match.params.analysisType}/questionnaire`}>
              <div className="back-to-questionnaire">
                {t('questionnaireQuestion.inputNewRespondent')}
              </div>
            </Link>
            <div className='respondant-container'>
              {respondentQuestionnairePreview && this.getRespondentName()}
            </div>
            <div className="questionnaire-list-preview">
              {
                previewQuestionnaireQuestion &&
                previewQuestionnaireQuestion.previewQuestionnaireQuestion.map((question, index) => this.getListOfQuestions(question, index))
              }
              {
                ((!previewQuestionnaireQuestion) ||  
                (previewQuestionnaireQuestion && previewQuestionnaireQuestion.previewQuestionnaireQuestion.length === 0)) &&
                this.getEmptyStateView()
              }
            </div>
            <div className="view-results">
              <button className={disableViewMatrix ? "ui button view-results-btn disabled" : "ui button view-results-btn"} onClick={()=> this.viewMatrix()}>{t('questionnaireQuestion.previewViewMatrix')}</button>
            </div>
          </Container>
        </div>
      </div>
    );
  };

}

function mapStateToProps(state) {
  return {
    userSession: state.auth.userSession,
    selectedSTakeHolderResponse: state.app.selectedSTakeHolderResponse,
    previewQuestionnaireQuestion: state.app.previewQuestionnaireQuestion,
    questionnaireGradePoints:  state.app.questionnaireGradePoints,
    questionnaireTopicCategories: state.app.questionnaireTopicCategories,
    respondentQuestionnairePreview: state.app.respondentQuestionnairePreview
  };
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    requestGetPreviewQuestionnaireDetails: requestGetPreviewQuestionnaireDetails,
    requestQuestionnaireGradePoints: requestQuestionnaireGradePoints,
    requestEditPreviewQuestionAnswer: requestEditPreviewQuestionAnswer,
    clearPreviewQuestionnaireDetails: clearPreviewQuestionnaireDetails,
    requestgetTopicCategories: requestgetTopicCategories,
    requestUpdateEditWeightageValues: requestUpdateEditWeightageValues,
    requestgetEditWeightageValues: requestgetEditWeightageValues,
    requestGetRespondentUsingId: requestGetRespondentUsingId
  },dispatch)
}

export default translate(['translations'], translateOptions)(connect(mapStateToProps, mapDispatchToProps)(QuestionnairePreviewView));