// IMPORTS
import brandLogoHeader from  '../assets/images/brand-logo-header.png';
import brandLogoFooter from  '../assets/images/brand-logo-footer.png';
import heroBanner from '../assets/images/hero-banner.jpg';

import businessPointOne from '../assets/images/u1.png';
import businessPointTwo from '../assets/images/u2.png';
import businessPointThree from '../assets/images/u3.png';
import materialityProcess from '../assets/images/materiality-process.png';
import faqIcon from '../assets/images/faq-icon.png';
import doneIcon from '../assets/images/done.svg';
import upArrow from '../assets/images/icn-arrow-up.svg';
import downArrow from '../assets/images/icn-arrow-down.svg';
import rightArrow from '../assets/images/arrow-right.svg';
import closeIcon from '../assets/images/icn-close.svg';

// VARIABLES
export const BRAND_LOGO_HEADER = brandLogoHeader;
export const BRAND_LOGO_FOOTER = brandLogoFooter;
export const HERO_BANNER = heroBanner;
export const BUSINESS_POINT_ONE = businessPointOne;
export const BUSINESS_POINT_TWO = businessPointTwo;
export const BUSINESS_POINT_THREE = businessPointThree;
export const MATERIALITY_PROCESS = materialityProcess;
export const FAQ_ICON = faqIcon;
export const DONE_ICON = doneIcon;
export const UP_ARROW_ICON = upArrow;
export const DOWN_ARROW_ICON = downArrow;
export const RIGHT_ARROW_ICON = rightArrow;
export const CLOSE_ICON = closeIcon;