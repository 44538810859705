import ActionTypes from '../constants/action-type';
import { Map as makeMap } from 'immutable';

export const initialState =  makeMap({

});

const app = (state=initialState, action)=> {

  if (!action.type) {
    console.error('Payload missing a type!', action);
  }

  switch (action.type) {

    case ActionTypes.RECEIVE_STAKE_HOLDER_RESPONSE: {
      const stakeHolderResponse = {
        stakeHolderResponse: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, stakeHolderResponse};
    }

    case ActionTypes.RECEIVE_ADD_STAKE_HOLDER_RESPONSE: {
      let stakeHolderResponseCopy = state.stakeHolderResponse.stakeHolderResponse.slice();
      stakeHolderResponseCopy.push(action.response.response)
      const stakeHolderResponse = {
        stakeHolderResponse: stakeHolderResponseCopy,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      const addStakeHolderResponse = {
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      }
      return {...state, stakeHolderResponse, addStakeHolderResponse};
    }

    case ActionTypes.RECEIVE_DELETE_STAKE_HOLDER_RESPONSE: {
      let stakeHolderResponseCopy = state.stakeHolderResponse.stakeHolderResponse.slice();
      if(action.response.deletedStakeholderID) {
        stakeHolderResponseCopy = stakeHolderResponseCopy.filter((stakeholder) => {
          if(stakeholder.id === action.response.deletedStakeholderID) {
            return false;
          }
          return true;
        })
      }
      const stakeHolderResponse = {
        stakeHolderResponse: stakeHolderResponseCopy,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      const deleteStakeHolderResponse = {
        deleteStakeHolderResponse: action.response.data,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, stakeHolderResponse, deleteStakeHolderResponse};
    }

    case ActionTypes.RECEIVE_UPDATE_STAKE_HOLDER_RESPONSE: {
      const updateStakeHolderResponse = {
        updateStakeHolderResponse: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, updateStakeHolderResponse};
    }

    case ActionTypes.RECEIVE_GRADES_RESPONSE: {
      const gradesResponse = {
        gradesResponse: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, gradesResponse};
    }

    case ActionTypes.RECEIVE_UPDATE_GRADES_RESPONSE: {
      const updateGradesResponse = {
        updateGradesResponse: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, updateGradesResponse};
    }

    case ActionTypes.CLEAR_STAKEHOLDER_VIEW_STATES: {
      const updateStakeHolderResponse = {
        updateStakeHolderResponse: undefined
      }
      return {...state, updateStakeHolderResponse};
    }

    //
    case ActionTypes.RECEIVE_BUSINESS_CRITERIA_RESPONSE: {
      const businessCriteriaResponse = {
        businessCriteriaResponse: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, businessCriteriaResponse};
    }

    case ActionTypes.RECEIVE_ADD_BUSINESS_CRITERIA_RESPONSE: {
      let businessCriteriaResponseCopy = state.businessCriteriaResponse.businessCriteriaResponse.slice();
      businessCriteriaResponseCopy.push(action.response.response)
      const businessCriteriaResponse = {
        businessCriteriaResponse: businessCriteriaResponseCopy,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      const addBusinessCriteriaResponse = {
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, businessCriteriaResponse, addBusinessCriteriaResponse};
    }

    case ActionTypes.RECEIVE_DELETE_BUSINESS_CRITERIA_RESPONSE: {
      let businessCriteriaResponseCopy = state.businessCriteriaResponse.businessCriteriaResponse.slice();
      if(action.response.deletedBusinessCriteriaID) {
        businessCriteriaResponseCopy = businessCriteriaResponseCopy.filter((criteria) => {
          if(criteria.id === action.response.deletedBusinessCriteriaID) {
            return false;
          }
          return true;
        })
      }
      const businessCriteriaResponse = {
        businessCriteriaResponse: businessCriteriaResponseCopy,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      const deleteBusinessCriterionResponse = {
        deleteBusinessCriterionResponse: action.response.data,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, businessCriteriaResponse, deleteBusinessCriterionResponse};
    }

    case ActionTypes.RECEIVE_UPDATE_BUSINESS_CRITERIA_RESPONSE: {
      const updateBusinessCriterionResponse = {
        updateBusinessCriterionResponse: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, updateBusinessCriterionResponse};
    }

    case ActionTypes.RECEIVE_BUSINESS_CRITERIA_STRATEGY_PERIOD_RESPONSE: {
      const businessCriterionStrategyPeriodResponse = {
        businessCriterionStrategyPeriodResponse: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, businessCriterionStrategyPeriodResponse};
    }

    case ActionTypes.RECEIVE_UPDATE_BUSINESS_CRITERIA_STRATEGY_PERIOD_RESPONSE: {
      const updateBusinessCriterionStrategyPeriodResponse = {
        updateBusinessCriterionStrategyPeriodResponse: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, updateBusinessCriterionStrategyPeriodResponse};
    }

    case ActionTypes.CLEAR_BUSINESS_CRITERIA_VIEW_STATES: {
      const updateBusinessCriterionResponse = {
        updateBusinessCriterionResponse: undefined
      }
      return {...state, updateBusinessCriterionResponse};
    }

    case ActionTypes.RECEIVE_ORGANISATION_DETAILS_RESPONSE: {
      const organisationDetails = {
        organisationDetails: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, organisationDetails}
    }

    case ActionTypes.RECEIVE_ORGANISATION_VERSIONS_RESPONSE: {
      const organisationVersions = {
        organisationVersions: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, organisationVersions};
    }

    case ActionTypes.RECEIVE_UPDATE_STRATEGY_PERIOD_RESPONSE: {
      const selectedStrategyPeriodForBuisnessCriteria = {
        selectedStrategyPeriod: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, selectedStrategyPeriodForBuisnessCriteria}
    }

    case ActionTypes.RECEIVE_ANALYSE_DRIVERS_RESPONSE: {
      const listAnalyseDrivers = {
        listAnalyseDrivers: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, listAnalyseDrivers}
    }

    case ActionTypes.RECEIVE_STAKEHOLDER_INTERVIEWS_RESPONSE: {
      const listStakeholderInterviews = {
        listStakeholderInterviews: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, listStakeholderInterviews}
    }

    case ActionTypes.RECEIVE_VALUE_CHAIN_BOUNDARIES_RESPONSE: {
      const listValueChainAndBoundaries = {
        listValueChainAndBoundaries: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, listValueChainAndBoundaries}
    }

    case ActionTypes.RECEIVE_STRATEGY_AND_REPORTS_FILES_RESPONSE: {
      const listStrategyReportsFiles = {
        listStrategyReportsFiles: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, listStrategyReportsFiles}
    }

    case ActionTypes.RECEIVE_SELECTED_STAKEHOLDER_RESPONSE: {
      const selectedSTakeHolderResponse = {
        selectedSTakeHolderResponse: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, selectedSTakeHolderResponse}
    }

    case ActionTypes.RECEIVE_LIST_OF_TOPICS_RESPONSE: {
      const listOfTopics = {
        listOfTopics: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, listOfTopics}
    }

    case ActionTypes.RECEIVE_VALIDATE_CONFIGS_FOR_QUESTIONNAIRE: {
      const analysisTypesConfigured = {
        analysisTypesConfigured: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, analysisTypesConfigured}
    }

    case ActionTypes.RECEIVE_QUESTIONNAIRE_QUESTION: {
      const questionnaireQuestions = {
        questionnaireQuestions: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, questionnaireQuestions}
    }

    case ActionTypes.RECEIVE_LIST_OF_TOPIC_CATEGORIES_RESPONSE: {
      const questionnaireTopicCategories = {
        questionnaireTopicCategories: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, questionnaireTopicCategories}
    }

    case ActionTypes.RECEIVE_QUESTIONNAIRE_GRADE_POINTS_RESPONSE: {
      const questionnaireGradePoints = {
        questionnaireGradePoints: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, questionnaireGradePoints}
    }

    case ActionTypes.RECEIVE_EDIT_WEIGHTAGE_VALUES_RESPONSE: {
      const editWeightageValues = {
        editWeightageValues: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, editWeightageValues}
    }

    case ActionTypes.RECEIVE_PREVIEW_QUESTIONNAIRE_RESPONSE: {
      const previewQuestionnaireQuestion = {
        previewQuestionnaireQuestion: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, previewQuestionnaireQuestion}
    }

    case ActionTypes.RECEIVE_STRATEGY_REPORT_VALUES_RESPONSE: {
      const strategyReportValues = {
        strategyReportValues: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, strategyReportValues}
    }

    case ActionTypes.REQUEST_CLEAR_RESPONDENT: {
      const selectedSTakeHolderResponse = {
        selectedSTakeHolderResponse: undefined,
      }
      const stakeHolderResponse = undefined;
      const businessCriteriaResponse = undefined;
      const questionnaireQuestions = undefined;
      return {...state, selectedSTakeHolderResponse, stakeHolderResponse, businessCriteriaResponse, questionnaireQuestions}
    }

    case ActionTypes.RECEIVE_LIST_OF_RESPONDENTS_RESPONSE: {
      const listOfRespondents = {
        listOfRespondents: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, listOfRespondents}
    }

    case ActionTypes.RECEIVE_EDIT_RESPONDENT: {
      const selectedSTakeHolderResponse = {
        selectedSTakeHolderResponse: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      };
      return {...state, selectedSTakeHolderResponse}
    }

    case ActionTypes.REQUEST_CLEAR_STAKEHOLDER_CRITERIA_CONFIG: {
      const analysisTypesConfigured = undefined;
      return {...state, analysisTypesConfigured}
    }

    case ActionTypes.REQUEST_CLEAR_QUESTIONNAIRE_PREVIEW: {
      const previewQuestionnaireQuestion = undefined;
      return {...state, previewQuestionnaireQuestion}
    }

    case ActionTypes.REQUEST_CLEAR_QUESTIONNAIRE_QUESTIONNAIRE_TOPIC_CATEGORIES: {
      const questionnaireTopicCategories = undefined;
      return {...state, questionnaireTopicCategories}
    }

    case ActionTypes.RECEIVE_INDIVIDUAL_RESPONDENT: {
      const respondentQuestionnairePreview  = {
        respondentQuestionnairePreview: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      }
      return {...state, respondentQuestionnairePreview}
    }
    case ActionTypes.RECEIVE_QUESTIONNAIRE_QUESTION_RESPONSE: {
      const respondentAnswerQuestionnaireQuestionResponse = {
        respondentAnswerQuestionnaireQuestionResponse: action.response.response,
        isSuccess: action.response.isSuccess,
        isError: action.response.isError
      }
      return {...state, respondentAnswerQuestionnaireQuestionResponse}
    }

    case ActionTypes.RESET_ANSWER_QUESTIONNAIRE_QUESTION_RESPONSE: {
      const respondentAnswerQuestionnaireQuestionResponse = {
        respondentAnswerQuestionnaireQuestionResponse: undefined,
        isSuccess: undefined,
        isError: undefined
      }
      return {...state, respondentAnswerQuestionnaireQuestionResponse}
    }
    default:{
      return state;
    }
  }

}

export default app;