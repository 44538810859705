import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Form, Grid } from 'semantic-ui-react';
import { translateOptions } from '../../../../../../i18n/config';

class EditStakeHolderDegreeView extends Component {
  constructor(props){
    super(props);
    this.state = {
      fields:{
        id: this.props.stakeHolder.id,
        name: this.props.stakeHolder.name || "",
        weight: this.props.stakeHolder.weight || "",
      }
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(field, e){
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({
      fields,
    });
    this.props.onChangeCallback(fields);
  }

  deleteStakeHolder(id){
    const params = {
      stakeholder_id: id
    }
    this.props.deleteStakeHolderCallback(params);
  }

  render(){
    const { t } = this.props;
    const { id, name, weight } = this.state.fields;

    return(
      <Form.Field className='form-field' style={styles.marginBottom5}>
        <Grid columns='equal' verticalAlign='middle'>
          <Grid.Row className='no-padding'>
            <Grid.Column width={7} className="stakeholder-name">
              <input
                type='text'
                placeholder={t('stakeholdersInsightsPage.stakeHolder')}
                onChange={this.handleChange.bind(this, 'name')}
                value={name}
                className='no-margin clear-opacity'
                disabled
              />
            </Grid.Column>
            <Grid.Column width={7} className="stakeholder-note">
              <input
                type='number'
                placeholder={t('stakeholdersInsightsPage.degree')}
                onChange={this.handleChange.bind(this, 'weight')}
                value={weight}
                min="0"
              />
            </Grid.Column>
            <Grid.Column width={2} className="icon-wrapper" style={{textAlign: 'right'}}>
              <div className="icon icon-trash" onClick={()=> this.deleteStakeHolder(id)} ></div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Field>
    );
  }

}

export default translate(['translations'], translateOptions)(connect(null)(EditStakeHolderDegreeView));

const styles = {
  marginBottom5: {
    marginBottom: '5px'
  }
}