import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { translateOptions } from '../../../../i18n/config';
import _, { isEqual } from 'lodash';

class ReviewBusinessCriteriasConfigurationsView extends Component {
  constructor(props){
    super(props);
    this.state = {
      modalOpened: this.props.open,
      selectedStrategyPeriod: null
    }
  }
  handleModalClose() {
    if(this.state.modalOpened) {
      this.setState({modalOpened: false});
      this.props.onClose();
    }
  }

  callConfigurebusinessCriteria() {
    this.props.updateBusinessCriteriaConfigured({'is_business_criteria_configured': true});
    this.props.showOrHideModel();
    this.handleModalClose();
  }

  setStrategyPeriodResponse(selectedStrategyPeriod) {    
    this.setState({selectedStrategyPeriod: selectedStrategyPeriod});
  }

  handlePrint() {
    window.print();
  }

  getbusinessCriteriaView(businessCriteriaResponse) {
    const { t } = this.props;
    businessCriteriaResponse = _.orderBy(businessCriteriaResponse, 'weight', 'desc');
    return(
      <table className="ui basic table">
        <thead>
          <tr>
            <th>{t('reviewBusinessCriteriaConfigsModal.businessCriteriaTableHeading1')}</th>
            <th>{t('reviewBusinessCriteriaConfigsModal.businessCriteriaTableHeading2')}</th>
          </tr>
        </thead>
        <tbody>
          {
            businessCriteriaResponse.map((businessCriteria, index) => {
              return (
                <tr key={index}>
                  <td>{businessCriteria.name}</td>
                  <td>{businessCriteria.weight}</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    )
  }

  getGradeView(selectedStrategyPeriod) {
    const { t } = this.props;
    return(
      <div className="ui input">
        <input type="text" placeholder="Strategy period" value={selectedStrategyPeriod.year} readOnly />
      </div>
    )
  }

  componentDidMount() {
    this.props.getSelectedStrategyPeriod();
    if(this.props.selectedStrategyPeriodForBuisnessCriteria) {
      this.setStrategyPeriodResponse(this.props.selectedStrategyPeriodForBuisnessCriteria);
    }
  }

  componentWillReceiveProps(newProps) {
    if(!isEqual(newProps.selectedStrategyPeriodForBuisnessCriteria, this.props.selectedStrategyPeriodForBuisnessCriteria)) {
      this.setStrategyPeriodResponse(newProps.selectedStrategyPeriodForBuisnessCriteria);
    }
  }

  render(){
    const {businessCriteriaResponse, updateBusinessCriterionStrategyPeriodResponse, t} = this.props;
    const { selectedStrategyPeriod } = this.state;
    return(
      <div>
        <Modal className="review-businessCriteria-configs-view" size={this.props.size} closeOnEscape={this.props.closeOnEscape} closeOnDimmerClick={this.props.closeOnDimmerClick} open={this.state.modalOpened} onClose={()=>this.handleModalClose()}>
          <Modal.Header>
            <div className="icon-alert-icon"></div>
            <div className="heading-content">
              <p>
                {t('reviewBusinessCriteriaConfigsModal.heading1')}
              </p>
              <p>
                {t('reviewBusinessCriteriaConfigsModal.heading2')}
              </p>
            </div>
          </Modal.Header>
          <Modal.Content>
            <div className="business-criteria-configurations">
              <div className="heading-wrapper-modal">
                <div className="heading">
                {t('reviewBusinessCriteriaConfigsModal.businessCriteriaWeightsHeading')}
                </div>
                <div className="print-link" onClick={()=>this.handlePrint()}>Print</div>
              </div>
              {
                businessCriteriaResponse && businessCriteriaResponse.businessCriteriaResponse.length>0 &&
                this.getbusinessCriteriaView(businessCriteriaResponse.businessCriteriaResponse)
              }
            </div>
            <div className="grade-values-wrapper">
              <div className="heading">
              {t('reviewBusinessCriteriaConfigsModal.strategyPeriodHeading')}
              </div>
              {
                selectedStrategyPeriod && selectedStrategyPeriod.selectedStrategyPeriod &&
                this.getGradeView(selectedStrategyPeriod.selectedStrategyPeriod)
              }
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button color="orange" icon='checkmark' labelPosition='left' content={'cancel'} onClick={()=>this.handleModalClose()}/>
            <Button color="orange" icon='checkmark' labelPosition='left' content={'proceed'} onClick={()=> this.callConfigurebusinessCriteria()}/>
          </Modal.Actions>
        </Modal>
      </div>
    )
  };

};

export default translate(['translations'], translateOptions)(connect(null)(ReviewBusinessCriteriasConfigurationsView));